import React from 'react';
import { STBodyText, STActiveLink } from 'design/atoms/Text';
import { STFlex } from '../Container';
import { withTheme } from 'styled-components';
import { StyledSystemProps } from 'design/atoms/Box';
import { Box } from '../Box';
import { Theme } from 'styled-system';
import { STIcon } from 'design/atoms/Icon';

interface NavItemProps {
  label: string;
  active?: boolean | undefined;
  activeBorder?: boolean;
  variantBlack?: string;
  hoverEffect?: boolean;
  theme?: Theme;
  icon?: string;
}

export const STNavItem: React.FC<NavItemProps & StyledSystemProps> = withTheme(
  ({ active, fontSize, activeBorder, label, pl, pr, hoverEffect, width, minWidth, theme, icon, ...props }) => {
    const [Hovered, toggleHover] = React.useState(false);
    const Wrapper = active ? STActiveLink : STBodyText;

    let ActiveHoverComponent;
    if (hoverEffect && (active || Hovered)) {
      ActiveHoverComponent = (
        <Box
          position="absolute"
          top="100%"
          mt="auto"
          height="2px"
          minWidth="50px"
          alignSelf="center"
          bg="primary"
          display={['none', 'unset']}
        />
      );
    }
    return (
      <STFlex
        active={active}
        position="relative"
        height={['100%', '64px']}
        flexDirection="row"
        borderBottom={['none', activeBorder ? (active ? `${theme.borders[1]}` : ' none') : 'none']}
        borderLeft={[active ? `2px solid ${theme.colors.primary}` : 'none', 'none']}
        data-cy="nav-item"
        justifyContent={['flex-start', 'center']}
        alignItems={['flex-start', 'center']}
        onMouseEnter={() => toggleHover(true)}
        onMouseLeave={() => toggleHover(false)}
        onClick={() => toggleHover(false)}
        {...props}
      >
        <Wrapper
          display="flex"
          flex={1}
          variant={active ? 'blueBoldClean' : 'greyRegularClickable'}
          pt={[2, 0]}
          pr={pr}
          pb={[2, 0]}
          pl={pl}
          textAlign={['left', 'center']}
          minWidth={minWidth}
          width={'fit-content'}
          fontSize={fontSize ? fontSize : 1}
        >
          {label}
        </Wrapper>
        {icon && (
          <STFlex display={['none', 'block']} pt={2} ml={-3} mr={3}>
            <STIcon icon={icon} size="xs" color="grey70" />
          </STFlex>
        )}
        {ActiveHoverComponent}
      </STFlex>
    );
  }
);
