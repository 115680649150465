/* eslint-disable import/prefer-default-export */
export const flightAppInitialState = {
  FLT: {
    mobileConfig: {
      filterActive: false,
      sortersActive: false,
      filtersActive: false,
      modifySearchActive: false,
      navActive: false,
      locationSelectorActive: false,
      dateSelectorActive: false,
      moreOptionsActive: false,
      flightDetailsActive: false,
      tripReviewActive: false,
      mobilePaymentActive: false,
    },
    webConfig: {
      modalScrollbar: false,
      modifySearchActive: false,
      appFooterVisible: true,
    },
    v3: {
      isActive: true,
    },
  },
};
