import React, { AnchorHTMLAttributes, FC } from 'react';
import styled from 'styled-components';
import { color, typography, space, layout, variant, system, compose } from 'styled-system';
import { Link } from 'react-router-dom';
import { StyledSystemProps } from 'design/atoms/Box';

const whiteSpace = system({ whiteSpace: true });
const whiteSpaceProps = compose(whiteSpace);

const TextSystem = styled.div`
  ${color}
  ${typography}
  ${space}
  ${layout}
  ${whiteSpaceProps}
  cursor: 'default';
`;

export interface TextProps extends AnchorHTMLAttributes<HTMLAnchorElement> {}
const TextStyled: FC<StyledSystemProps> = styled(TextSystem)(
  variant({
    variants: {
      blackBold: {
        fontWeight: 700,
        color: 'black',
      },
      blackMedium: {
        fontWeight: 500,
        color: 'black',
      },
      blackRegular: {
        fontWeight: 400,
        color: 'black',
      },
      blackRegularClickable: {
        fontWeight: 400,
        color: 'black',
        cursor: 'pointer',
      },
      lightGreyRegular: {
        color: 'grey40',
        fontWeight: 400,
      },
      lightGreyRegularClean: {
        color: 'grey40',
        fontWeight: 400,
        textDecoration: 'none',
        cursor: 'pointer',
        '&:hover': {
          color: 'primaryHover',
          textShadow: `0 0 1px #00598B`,
        },
      },
      lightGreyMedium: {
        color: 'grey40',
        fontWeight: 500,
      },
      lightGreyBold: {
        color: 'grey40',
        fontWeight: 700,
        cursor: 'pointer',
      },
      greyRegular: {
        color: 'grey70',
        fontWeight: 400,
      },
      greyRegularClickable: {
        color: 'grey70',
        fontWeight: 400,
        cursor: 'pointer',
        '&:hover': {
          color: 'primaryHover',
        },
      },

      greyMedium: {
        color: 'grey70',
        fontWeight: 500,
      },
      greyBold: {
        color: 'grey70',
        fontWeight: 700,
      },
      blueRegular: {
        color: 'primary',
        fontWeight: 400,
        textDecoration: 'underline',
        cursor: 'pointer',
        '&:hover': {
          color: 'primaryHover',
        },
      },
      blueBold: {
        color: 'primary',
        fontWeight: 700,
        cursor: 'pointer',
        textDecoration: 'underline',
        '&:hover': {
          color: 'primaryHover',
        },
      },
      blueBoldClean: {
        color: 'primary',
        fontWeight: 700,
        textDecoration: 'none',
        cursor: 'pointer',
        '&:hover': {
          color: 'primaryHover',
        },
      },
      blueRegularClean: {
        color: 'primary',
        fontWeight: 400,
        textDecoration: 'none',
        cursor: 'pointer',
        '&:hover': {
          color: 'primaryHover',
        },
      },
      whiteBold: {
        color: 'white',
        fontWeight: 700,
      },
      whiteRegular: {
        fontWeight: 400,
        color: 'white',
      },
      redRegular: {
        color: 'error',
        fontWeight: 400,
      },
    },
  })
);

export const STNavLink = styled(Link)`
  text-decoration: none;
`;

export const BaseText: FC<StyledSystemProps> = ({ children, ...props }) => (
  <TextStyled fontFamily="primary" {...props}>
    {children}
  </TextStyled>
);

export const STH1: FC<StyledSystemProps> = ({ children, ...props }) => (
  <BaseText as="h1" variant="blackRegular" fontSize={4} {...props}>
    {children}
  </BaseText>
);

export const STH2: FC<StyledSystemProps> = ({ children, ...props }) => (
  <BaseText as="h2" variant="blackRegular" fontSize={3} {...props}>
    {children}
  </BaseText>
);

export const STH3: FC<StyledSystemProps> = ({ children, ...props }) => (
  <BaseText as="h3" variant="blackRegular" fontSize={2} {...props}>
    {children}
  </BaseText>
);

export const STBodyText: FC<StyledSystemProps> = ({ children, ...props }) => (
  <BaseText as="div" variant="blackRegular" fontSize={1} {...props}>
    {children}
  </BaseText>
);

export const STClickableText: FC<StyledSystemProps> = ({ children, ...props }) => (
  <BaseText as="div" variant="blackRegularClickable" fontSize={1} {...props}>
    {children}
  </BaseText>
);

export const STDisabledBodyText: FC<StyledSystemProps> = ({ children, ...props }) => (
  <STBodyText as="div" variant="lightGreyRegular" {...props}>
    {children}
  </STBodyText>
);

export const STLabel: FC<StyledSystemProps> = ({ children, ...props }) => (
  <STBodyText as="label" variant="greyRegular" {...props}>
    {children}
  </STBodyText>
);

export const STLink: FC<StyledSystemProps & TextProps> = ({ children, ...props }) => (
  <STBodyText as="span" variant="blueBold" {...props}>
    {children}
  </STBodyText>
);

export const STActiveLink: FC<StyledSystemProps> = ({ children, ...props }) => (
  <STBodyText as="span" variant="blueRegularClean" {...props}>
    {children}
  </STBodyText>
);

export const STSecondaryLink: FC<StyledSystemProps> = ({ children, ...props }) => (
  <BaseText as="span" variant="lightGreyBold" fontSize={3} {...props}>
    {children}
  </BaseText>
);

export const STErrorText: FC<StyledSystemProps> = ({ children, ...props }) => (
  <STBodyText as="span" variant="redRegular" {...props}>
    {children}
  </STBodyText>
);
