export const ALL_PROVIDERS_DETAIL = 'ALL_PROVIDERS_DETAIL';
export const CYBER_SRC_FORM_FIELDS_SET = 'CYBER_SRC_FORM_FIELDS_SET';

export const ALL_PROVIDERS_DETAIL_ = 'ALL_PROVIDERS_DETAIL_';
export const CYBER_SRC_FORM_FIELDS_SET_ = 'CYBER_SRC_FORM_FIELDS_SET_';

export const ADD_ORDER = 'ADD_ORDER';
export const UPDATE_ORDER_TRANSACTION = 'UPDATE_ORDER_TRANSACTION';
export const ADD_ORDER_TRANSACTION = 'ADD_ORDER_TRANSACTION';
export const CLEAR_ORDER = 'CLEAR_ORDER';

export const ADD_DISCOUNT_DETAILS = 'ADD_DISCOUNT_DETAILS';

export const UPDATE_SELECTED_EMI_PLAN = 'UPDATE_SELECTED_EMI_PLAN';
