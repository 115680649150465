import * as orderActionTypes from './orderActionTypes';
import { createReducer } from '../../util/Helpers';
import { orderInitialState } from './orderInitialState';

const allProvidersDetail = (state = {}, action) => {
  return {
    ...state,
    paymentProviders: action.allProvidersDetail,
  };
};

const addDiscountDetails = (state = {}, action) => ({
  ...state,
  orderDetails: {
    ...state.orderDetails,
    discount: action.discount,
    promoCode: action.promoCode,
  },
});

const updateCyberSrcFormFields = (state = {}, action) => ({
  ...state,
  payment: {
    cyberSrcFormFields: action.cyberSrcFormFields,
  },
});

const addOrder = (state = {}, action) => ({
  ...state,
  orderDetails: action.order,
});

const updateOrderTransaction = (state = {}, action) => ({
  ...state,
  orderDetails: {
    ...state.orderDetails,
    transactions: action.order.transactions,
  },
});

const addOrderTransaction = (state = {}, action) => ({
  ...state,
  orderDetails: {
    ...state.orderDetails,
    transactions: [action.transaction],
  },
});

const clearOrder = (state = {}) => ({
  ...state,
  orderDetails: null,
});

const updateSelectedEmiPlan = (state = {}, action) => ({
  ...state,
  emi: {
    selectedPlan: action.selectedPlan,
  },
});

const _orderReducer = createReducer(
  {},
  {
    [orderActionTypes.UPDATE_ORDER_TRANSACTION]: updateOrderTransaction,
    [orderActionTypes.ADD_ORDER]: addOrder,
    [orderActionTypes.ADD_ORDER_TRANSACTION]: addOrderTransaction,
    [orderActionTypes.ALL_PROVIDERS_DETAIL]: allProvidersDetail,
    [orderActionTypes.CYBER_SRC_FORM_FIELDS_SET]: updateCyberSrcFormFields,
    [orderActionTypes.CLEAR_ORDER]: clearOrder,
    [orderActionTypes.ADD_DISCOUNT_DETAILS]: addDiscountDetails,
    [orderActionTypes.UPDATE_SELECTED_EMI_PLAN]: updateSelectedEmiPlan,
  }
);

const OrderReducer = (state = orderInitialState, action) => _orderReducer(state, action);

export default OrderReducer;
