import { UmrahBookingAction } from './umrahBookingActionTypes';
import * as bookingActionTypes from '../../../Booking/bookingActionTypes';
import { BookingSteps } from 'modules/Booking/BookingStore/types';
import { UmrahBookingState } from './UmrahBookingTypes';

const initialState: UmrahBookingState = {
  bookings: [],
  currentStep: BookingSteps.BOOKING,
};

export default function umrahBookingReducer(state = initialState, action: UmrahBookingAction): UmrahBookingState {
  switch (action.type) {
    case bookingActionTypes.BOOKING_SAVE_:
      return {
        ...state,
        bookings: action.bookings,
      };
    case bookingActionTypes.BOOKING_UPDATE:
      return {
        ...state,
        bookings: action.bookings,
      };
    case bookingActionTypes.BOOKING_CLEAR_:
      return {
        ...state,
        bookings: [],
      };
    default:
      return state;
  }
}
