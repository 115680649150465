const links = {
  //Top Domestic Airlines
  TopDomesticAirlines: [
    { title: 'Airblue', link: 'https://www.sastaticket.pk/airline/air-blue' },
    { title: 'AirSial', link: 'https://www.sastaticket.pk/airline/air-sial' },
    { title: 'Fly Jinnah', link: 'https://www.sastaticket.pk/airline/fly-jinnah' },
    {
      title: 'Pakistan International Airways',
      link: 'https://www.sastaticket.pk/airline/pakistan-international-airlines',
    },
    { title: 'K2 Airways', link: 'https://www.sastaticket.pk/airline/k2-airways' },
    { title: 'Serene Airways', link: 'https://www.sastaticket.pk/airline/serene-air' },
    { title: 'Shaheen Airways', link: 'https://www.sastaticket.pk/airline/shaheen-air' },
    { title: 'Q Airlines', link: 'https://www.sastaticket.pk/airline/q-airlines' },
  ],

  //Top International Airlines
  TopInternationalAirlines: [
    { title: 'Air Arabia', link: 'https://www.sastaticket.pk/airline/air-arabia' },
    { title: 'Air China', link: 'https://www.sastaticket.pk/airline/air-china' },
    { title: 'Malindo Air', link: 'https://www.sastaticket.pk/airline/malindo-air/' },
    { title: 'American Airways', link: 'https://www.sastaticket.pk/airline/american-airlines' },
    { title: 'British Airways', link: 'https://www.sastaticket.pk/airline/british-airways' },
    { title: 'Cathay Pacific', link: 'https://www.sastaticket.pk/airline/cathay-pacific' },
    { title: 'Emirates', link: 'https://www.sastaticket.pk/airline/emirates' },
    { title: 'Etihad Airways', link: 'https://www.sastaticket.pk/airline/etihad-airways' },
    { title: 'Fly Dubai', link: 'https://www.sastaticket.pk/airline/fly-dubai' },
    { title: 'Gulf Air', link: 'https://www.sastaticket.pk/airline/gulf-air' },
    { title: 'Kenya Airways', link: 'https://www.sastaticket.pk/airline/kenya-airways' },
    { title: 'Oman Air', link: 'https://www.sastaticket.pk/airline/oman-air' },
    { title: 'Qatar Airways', link: 'https://www.sastaticket.pk/airline/qatar-airways' },
    { title: 'Saudi Arabian Airlines', link: 'https://www.sastaticket.pk/airline/saudi-arabian-airline' },
    { title: 'SriLankan Airlines', link: 'https://www.sastaticket.pk/airline/srilankan-airlines' },
    { title: 'Swiss International Airlines', link: 'https://www.sastaticket.pk/airline/swiss-international-airlines' },
    { title: 'Thai Airways', link: 'https://www.sastaticket.pk/airline/thai-airways' },
    { title: 'Turkish Airlines', link: 'https://www.sastaticket.pk/airline/turkish-airlines' },
  ],

  //Popular Holiday Destinations
  /*
  PopularHolidayDestination: [
    { title: 'Hotels in Karachi', link: 'https://www.sastaticket.pk/book-hotels/karachi-hotels' },
    { title: 'Hotels in Lahore', link: 'https://www.sastaticket.pk/book-hotels/lahore-hotels' },
    { title: 'Hotels in Islamabad', link: 'https://www.sastaticket.pk/book-hotels/islamabad-hotels' },
    { title: 'Hotels in Murree', link: 'https://www.sastaticket.pk/book-hotels/murree-hotels' },
    { title: 'Hotels in Naran', link: 'https://www.sastaticket.pk/book-hotels/naran-hotels' },
    { title: 'Hotels in Rawalpindi', link: 'https://www.sastaticket.pk/book-hotels/rawalpindi-hotels' },
    { title: 'Hotels in Hyderabad', link: 'https://www.sastaticket.pk/book-hotels/hyderabad-hotels' },
    { title: 'Hotels in Swat', link: 'https://www.sastaticket.pk/book-hotels/swat-hotels' },
    { title: 'Hotels in Gilgit', link: 'https://www.sastaticket.pk/book-hotels/gilgit-hotels' },
    { title: 'Hotels in Multan', link: 'https://www.sastaticket.pk/book-hotels/multan-hotels' },
    // global
    { title: 'Hotels in Dubai', link: 'https://www.sastaticket.pk/book-hotels/dubai-hotels' },
    { title: 'Hotels in Kuala Lampur', link: 'https://www.sastaticket.pk/book-hotels/kuala-lumpur-hotels' },
    { title: 'Hotels in Langkawi', link: 'https://www.sastaticket.pk/book-hotels/langkawi-hotels' },
    { title: 'Phi Phi', link: 'https://www.sastaticket.pk/book-hotels/phi-phi-hotels' },
    { title: 'Hotels in Phuket', link: 'https://www.sastaticket.pk/book-hotels/phuket-hotels' },
    { title: 'Hotels in Istanbul', link: 'https://www.sastaticket.pk/book-hotels/istanbul-hotels' },
    { title: 'Hotels in Makkah', link: 'https://www.sastaticket.pk/book-hotels/makkah-hotels' },
    { title: 'Hotels in Madinah', link: 'https://www.sastaticket.pk/book-hotels/madinah-hotels' },
  ],
  */

  //Popular Domestic Flight Destinations
  PopularDomesticFlightDestinations: [
    {
      title: 'Flights from Karachi to Islamabad',
      link: 'https://www.sastaticket.pk/flights/cheap-flights-from-karachi-to-islamabad',
    },
    {
      title: 'Flights from Karachi to Lahore',
      link: 'https://www.sastaticket.pk/flights/cheap-flights-from-karachi-to-lahore',
    },
    {
      title: 'Flights from Islamabad to Karachi',
      link: 'https://www.sastaticket.pk/flights/cheap-flights-from-islamabad-to-karachi',
    },
    {
      title: 'Flights from Lahore to Islamabad',
      link: 'https://www.sastaticket.pk/flights/cheap-flights-from-lahore-to-islamabad',
    },
    {
      title: 'Flights from Lahore to Karachi',
      link: 'https://www.sastaticket.pk/flights/cheap-flights-from-lahore-to-karachi',
    },
    {
      title: 'Flights from Karachi to Peshawar',
      link: 'https://www.sastaticket.pk/flights/cheap-flights-from-karachi-to-peshawar',
    },
    {
      title: 'Flights from Karachi to Faisalabad',
      link: 'https://www.sastaticket.pk/flights/cheap-flights-from-karachi-to-faisalabad',
    },
  ],

  //Popular International Flight Destinations
  PopularInternationalFlightDestinations: [
    {
      title: 'Flights from Karachi to Dubai',
      link: 'https://www.sastaticket.pk/flights/cheap-flights-from-karachi-to-dubai',
    },
    {
      title: 'Flights from Lahore to Dubai',
      link: 'https://www.sastaticket.pk/flights/cheap-flights-from-lahore-to-dubai',
    },
    {
      title: 'Flights from Islamabad to Dubai',
      link: 'https://www.sastaticket.pk/flights/cheap-flights-from-islamabad-to-dubai',
    },
    {
      title: 'Flights from Karachi to Jeddah',
      link: 'https://www.sastaticket.pk/flights/cheap-flights-from-karachi-to-jeddah',
    },
    {
      title: 'Flights from Karachi to Madinah',
      link: 'https://www.sastaticket.pk/flights/cheap-flights-from-karachi-to-madinah',
    },
    {
      title: 'Flights from Karachi to Bangkok',
      link: 'https://www.sastaticket.pk/flights/cheap-flights-from-karachi-to-bangkok',
    },
    {
      title: 'Flights from Lahore to Beijing',
      link: 'https://www.sastaticket.pk/flights/cheap-flights-from-lahore-to-beijing',
    },
    {
      title: 'Flights from Karachi to Kuala Lampur',
      link: 'https://www.sastaticket.pk/flights/cheap-flights-from-karachi-to-kuala-lumpur',
    },
    {
      title: 'Flights from Karachi to Abu Dhabi',
      link: 'https://www.sastaticket.pk/flights/cheap-flights-from-karachi-to-abu-dhabi',
    },
    {
      title: 'Flights from Lahore to New York',
      link: 'https://www.sastaticket.pk/flights/cheap-flights-from-lahore-to-new-york',
    },
  ],

  // Top Bus Operators
  TopBusOperators: [
    { title: 'Online Bus Tickets', link: 'https://www.sastaticket.pk/bus-tickets' },
    { title: 'Daewoo Express', link: 'https://www.sastaticket.pk/bus-tickets/daewoo-express' },
    { title: 'Kainat Travels', link: 'https://www.sastaticket.pk/bus-tickets/kainat-travels' },
    { title: 'Niazi Express', link: 'https://www.sastaticket.pk/bus-tickets/niazi-express' },
    { title: 'Faisal Movers', link: 'https://www.sastaticket.pk/bus-tickets/faisal-movers' },
    { title: 'Bilal Travels', link: 'https://www.sastaticket.pk/bus-tickets/bilal-travels' },
    { title: 'Skyways', link: 'https://www.sastaticket.pk/bus-tickets/skyways' },
  ],
  //Company Links
  CompanyLinks: [
    { title: 'About Us', link: 'https://www.sastaticket.pk/pages/about-us' },
    { title: 'Contact Us', link: 'https://www.sastaticket.pk/pages/contact-us' },
    { title: 'My Booking', link: 'https://www.sastaticket.pk/air/account/bookings' },
    { title: 'FAQ', link: 'https://www.sastaticket.pk/pages/faqs' },
    { title: 'Privacy Policy', link: 'https://www.sastaticket.pk/pages/privacy-policy' },
    { title: 'Careers', link: 'https://app.skeeled.com/board/6223d306427ae9116481dcb8' },
    { title: 'Terms and Conditions', link: 'https://www.sastaticket.pk/pages/terms-and-conditions' },
    { title: 'Free Cancellation', link: 'https://www.sastaticket.pk/pages/free-cancellation-faq' },
    // { title: 'List Your Property', link: 'https://www.sastaticket.pk/pages/list-your-property' },
  ],

  //social Links
  Facebook: 'https://www.facebook.com/sastaticket.pk/',
  Twitter: 'https://twitter.com/sastaticketpk',
  Instagram: 'https://www.instagram.com/sastaticket.pk/?hl=en',
};
export default links;
