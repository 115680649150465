export const ADMIN_REPORT_DETAILS = 'ADMIN_REPORT_DETAILS';
export const ADMIN_HOTEL_REPORT_DETAILS = 'ADMIN_HOTEL_REPORT_DETAILS';
export const ADMIN_TOUR_REPORT_DETAILS = 'ADMIN_TOUR_REPORT_DETAILS';
export const ADMIN_BUS_REPORT_DETAILS = 'ADMIN_BUS_REPORT_DETAILS';
export const CLEAR_ADMIN_REPORT = 'CLEAR_ADMIN_REPORT';
export const ADMIN_REPORTS = 'ADMIN_REPORTS';
export const ADMIN_REPORTS_ORIGINAL = 'ADMIN_REPORTS_ORIGINAL';
export const FETCH_ALL_AGENTS = 'FETCH_ALL_AGENTS';
export const NOTE_ADD = 'NOTE_ADD';
export const NOTES = 'NOTES';
export const EMAIL_CONFIRMATION = 'EMAIL_CONFIRMATION';
export const GET_PROVIDERS = 'GET_PROVIDERS';
export const TRIGGER_REQUEST = 'TRIGGER_REQUEST';
export const END_REQUEST = 'END_REQUEST';
export const ORDER_PENALTIES = 'ORDER_PENALTIES';
export const GET_REISSUANCE_DETAILS = 'GET_REISSUANCE_DETAILS';
