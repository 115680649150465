/* eslint-disable */
import humps from 'humps';
import { hotelResultsInitialState } from './hotelResultsInitialState';
import { HotelResultsAction, HotelResultActiontypes, HotelResultsState } from './hotelResultsTypes';
import { OptionModel } from 'modules/Hotels/Search/HotelSearchStore/hotelSearchTypes';

const {
  ADD_HOTEL_RESULTS,
  CLEAR_HOTEL_RESULTS,
  UPDATE_FILTERS_IN_RESPONSE,
  MERGE_HOTEL_RESULTS,
} = HotelResultActiontypes;

const hasThisFilterChanged = (filterName: string, requestedFilters: object) =>
  Object.keys(requestedFilters).includes(humps.camelize(filterName));

const getRangeValueFromAppliedFilter = (appliedFilter: any, optionName: string) => appliedFilter['price'][optionName];
const getDefaultRangeValues = (optionName: string) => (optionName === 'min' ? 0 : 1000);
const unCheckThisOption = (option: OptionModel) => {
  return { ...option, value: false };
};
const checkThisOption = (option: OptionModel) => {
  return { ...option, value: true };
};
const isThisOptionApplied = (currentFilterName: string, optionName: string, requestedFilters: any) =>
  requestedFilters[humps.camelize(currentFilterName)].includes(optionName);

const isPriceFilterApplied = (filterName: string) => filterName === 'price';

export const hotelResultsReducer = (
  state = hotelResultsInitialState,
  action: HotelResultsAction
): HotelResultsState => {
  switch (action.type) {
    case ADD_HOTEL_RESULTS:
    case CLEAR_HOTEL_RESULTS:
      return { hotelSearchResults: action.hotelSearchResults };
    case UPDATE_FILTERS_IN_RESPONSE:
      if (!state.hotelSearchResults)
        return {
          hotelSearchResults: state.hotelSearchResults,
        };
      return {
        hotelSearchResults: {
          ...state.hotelSearchResults,
          filters: {
            ...state.hotelSearchResults.filters,
            filters: state.hotelSearchResults.filters.filters.map((filter) => {
              return {
                ...filter,
                options: filter.options.map((option) => {
                  /** we have a different value property in price, so have to check it separately */
                  if (isPriceFilterApplied(filter.name)) {
                    return {
                      ...option,
                      value: hasThisFilterChanged(filter.name, action.requestedFilters)
                        ? getRangeValueFromAppliedFilter(action.requestedFilters, option.name)
                        : getDefaultRangeValues(option.name),
                    };
                  }
                  if (filter.name === 'property_id') {
                    return {
                      ...option,
                    };
                  }
                  /** for all other filters */
                  if (
                    hasThisFilterChanged(filter.name, action.requestedFilters) &&
                    isThisOptionApplied(filter.name, option.name, action.requestedFilters)
                  )
                    return checkThisOption(option);
                  else return unCheckThisOption(option);
                }),
              };
            }),
          },
        },
      };

    case MERGE_HOTEL_RESULTS:
      return {
        hotelSearchResults: {
          hotels: [...state.hotelSearchResults!.hotels, ...action.hotelSearchResults!.hotels],
          searchQuery: action.hotelSearchResults!.searchQuery,
          filters: action.hotelSearchResults!.filters,
        },
      };
    default:
      return state;
  }
};
