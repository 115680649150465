import { HotelSearchQuery } from 'modules/Hotels/Search/HotelSearchStore/hotelSearchQueryTypes';
import { BookingSteps } from 'modules/Booking/BookingStore/types';
import {
  HotelBriefResponse,
  cancellationPolicies,
} from 'modules/Hotels/HotelResults/HotelResultsStore/hotelResultsTypes';

export enum RouteType {
  ONEWAY = 'ONEWAY',
  RETURN = 'RETURN',
  MULTI = 'MULTI',
}

export enum Gender {
  MALE = 'M',
  FEMALE = 'F',
}

export enum Title {
  MR = 'Mr',
  MASTER = 'Master',
  MRS = 'Mrs',
  MS = 'Ms',
}
export enum TravelerType {
  ADULT = 'ADT',
  CHILDREN = 'CNN',
  INFANT = 'INF',
}
export enum OrderType {
  HOTEL = 'HOTEL',
  FLIGHT = 'FLIGHT',
  TOUR = 'TOUR',
  BUS = 'BUS',
}

export interface GuestsCount {
  adults: number;
  children: number;
  infants: number;
}

export interface CabinClass {
  code: string;
  label: string;
}

export interface TravelerCount {
  numAdult: number;
  numChild?: number;
  numInfant?: number;
}

export interface Baggage {
  price: number;
  weight: string;
  quantity: number;
  flightType: string;
  key: string;
}

export interface OriginDestinationType {
  iataCode: string;
  airport?: string;
  city: string;
  timeZone?: string;
  id?: number;
  name?: string;
  displayName?: string;
  country?: string;
  latitude?: number;
  longitude?: number;
  aTimezone?: number;
  dst?: string;
  tzDbTimezone?: string;
  hitCount?: number;
  searchPosition?: number;
  isCity?: boolean;
  cityAirports?: null;
}

export interface Passenger {
  firstName: string;
  middleName: string | null;
  passengerType: TravelerType;
  title: Title;
  lastName: string;
  phoneNumber: string;
  ticketNumber: string;
  dob: Date;
  bookingId: number;
  extraFields: object | null;
  gender: Gender;
  passportExpiry: Date;
  nationality: string;
  passportNumber: string;
  id: number;
  createdAt?: Date;
  lastUpdatedAt?: Date;
  CNIC?: number;
  nic?: number;
}

export interface ContactDetail {
  id: number | null;
  firstName?: string;
  lastName?: string;
  email?: string;
  mobileNumber?: string;
  title?: string;
}

export interface BaggageInfo {
  pieces: number;
  weight: number;
  unit?: string;
}

export interface SegmentMeta {
  bookingFlightNumber?: string;
  cabinClass?: string;
  marriageGroup?: string;
  mealCode?: string;
  seatsRemaining?: number;
  flightType?: string;
}

export interface RouteSegment {
  origin: OriginDestinationType; //dp_ap
  destination: OriginDestinationType; //ar_ap
  arrivalDatetime: Date; //ar_dt
  departureDatetime: Date; //dp_dt
  flightNumber: Array<string>; //fl_id
  durationMinutes: number; //du_min
  cabinClass: string; //cb_cl
  rbd?: string;
  marketingAirline: AirlineInfoMeta;
  operatingAirline: AirlineInfoMeta;
  meta?: SegmentMeta; //bk_cd
}

export interface Departure {
  airport: string;
  city: string;
  iataCode: string;
  timeStamp: string;
  timeZone: string;
}

export interface Arrival {
  airport: string;
  city: string;
  iataCode: string;
  timeStamp: Date;
  timeZone: string;
}

export interface AirlineInfoMeta {
  code?: string;
  logo?: string;
  slug?: string;
  name?: string;
}
export interface AirlineInfo {
  operatingAirline: AirlineInfoMeta;
  marketingAirline: AirlineInfoMeta;
}
export interface RouteLeg {
  itenaryId: string;
  cabinClass: string;
  origin: string;
  destination: string;
  hash: string;
  isRefundable: boolean;
  baggageInfo: BaggageInfo;
  operatingAirline: AirlineInfoMeta;
  totalDurationInMinutes: number;
  segments: Array<RouteSegment>;
  meta: {
    fareBasisInfo?: FareBasisInfo;
    lastTicketDetail?: string;
    fareBasisCodes?: FareBasisCodes;
  };
}

interface FareBasisCodes {
  ADT?: string;
  INF?: string;
  CNN?: string;
}

export interface FareBasisInfo {
  content: string;
  govCarrier: string;
  ArrivalAirportCode?: string;
  BookingCode?: string;
  DepartureAirportCode?: string;
  FareComponentBeginAirport?: string;
  FareComponentDirectionality?: string;
  FareComponentEndAirport?: string;
  FareComponentVendorCode?: string;
}

export interface RouteFareInfo {
  totalFare: number;
  baseFare: number;
  tax: number;
}

export interface RoutePriceDetails {
  sellingFare: number; //display
  publishedFare: number; //supplier without tax
  grossFare: number; //publishedfare+tax
  tax: number;
  currency: string;
  breakdown: {
    adult: RouteFareInfo;
    child?: RouteFareInfo;
    infant?: RouteFareInfo;
  };
  isComboPrice: boolean;
  comboPrice: number;
}

export interface FlightMeta {
  flightRating: number;
  freeCancelPremium?: number;
  isCustomItinerary?: boolean;
  isFoc?: boolean;
  isFreeCancellation?: boolean;
  itineraryDuration: number;
  name: string;
  price: number;
}

export interface FlightSearchResult {
  id: string; //pr_key,seqNo
  provider: string; //pr
  isRefundable: boolean; //is_rfd
  legs: Array<RouteLeg>;
  price: RoutePriceDetails;
  baggageInfo: BaggageInfo;
  meta: FlightMeta;
  isCancellable?: boolean;
}

export interface SearchResult {
  searchResults: Array<FlightSearchResult>;
}

export interface GATrackerKeys {
  ga_client_id: string;
  ga_document_referrer: string;
}

export interface CustomError {
  message: String;
}

export interface GTMProduct {
  id: string;
  name: string;
  brand: string;
  category: string;
  price: string;
  quantity: number;
  variant: string;
}

export interface Guest {
  id: number;
  firstName: string;
  lastName: string;
  dob: Date;
  title: Title;
}

export interface Rate {
  id: string;
  availableRooms: number;
  rateClass: RateClass;
  depositRequired: number; //due
  cancellationPolicy: Partial<CancellationPolicy>;
  cancellation_policy: Array<cancellationPolicies>;
  grossRate: number;
  sellingRate: number;
  tax: number;
  fee: number;
  meta: RateMeta;
  // paymentType: PaymentType;
}

type RateMeta = {
  rateId?: number;
  showMarkedUpRate?: boolean;
};

enum RateClass {
  REFUNDABLE = 'Refundable',
  NON_REFUNDABLE = 'Non-refundable',
  SPECIAL = 'Special',
}

type CancellationPolicy = ['Non-refundable', 'Free cancellation', 'Reserve now, pay at Hotel', 'Advance Payment'];

export interface Image {
  url: string;
  title: string;
}

export interface NearbyPlace {
  location: string;
  distance: string;
}

export interface Rate {
  adjustment: number;
  amenities: Array<string>;
  baseRate: number;
  displayPrice: number;
  paymentType: PaymentType;
  guestsCount: GuestsCount;
  total: number;
  tax: number;
}

export type PaymentType = 'AT_HOTEL' | 'AT_WEB';

export interface Room extends GuestsCount {
  rates: Array<Rate>;
  roomDescription: string;
  roomId: string;
  roomName: string;
  isRefundable: boolean;
  boardBasis: string;
  images: Array<Image>;
}

interface BookedRoom {
  id: number;
  roomType: string;
  adult: number;
  children: number;
}

export interface HotelBooking {
  createdAt: Date;
  bookingId: number | null;
  checkin: string;
  checkout: string;
  cancellationPolicies: Array<cancellationPolicies>;
  contactDetails: ContactDetail;
  grossFare: number;
  hotelType: string;
  id: number;
  leadGuest: Guest;
  numberOfGuest: number;
  numberOfRooms: number;
  prop: HotelBriefResponse;
  provider: string;
  rooms: BookedRoom[];
  tax: number;
  totalFare: number;
  webReference: string;
  searchQuery: HotelSearchQuery;
}

export interface HotelBookingState {
  bookings: Array<HotelBooking>;
  currentStep: BookingSteps;
}

export interface hotelInitiateBookingPayload {
  numberOfRooms: number;
  propDetailKey: string;
  selectedRoom: {
    roomId: string;
    rateId: string;
  };
  searchQuery: {
    destination: string;
    checkin: string;
    checkout: string;
    roomsOccupancy: guestsRoomOccupancy[];
    filters: object;
  };
}

interface GuestCount {
  adults: number;
  children: number;
  infants: number;
}

export interface hotelMakeBookingPayload {
  contact_detail: ContactDetail;
  lead_guest: Guest;
  web_reference: string;
}

export interface Guest {
  id: number;
  title: Title;
  firstName: string;
  lastName: string;
}
interface HotelOriginDestinationType {
  city: string;
  iataCode: string;
}

interface guestsRoomOccupancy {
  adult: number;
  children: Array<{ age: number }>;
}

export interface Amenity {
  name: string;
  id: number;
  icon: string;
}

interface Location {
  location: string;
  distance: string;
}

export interface CalendarFare {
  airlineCode: string;
  departureDate: string;
  legIndex: null | number;
  provider: string;
  sellingFare: number;
  travelDuration: number;
}

export interface LocationState {
  id: number;
  lat: string;
  lng: string;
  name: string;
  shortCode: string;
  address: string;
}

export interface BusSearchQuery {
  departureDate: Date;
  destinationId: number;
  originId: number;
}

export enum LegPassengerStatus {
  Active = 'active',
  CancelProcessing = 'cancel_processing',
  CancelFailed = 'cancel_failed',
  AutoFailed = 'auto_failed',
  CancelledAuto = 'cancelled_auto',
  CancelledManual = 'cancelled_manual',
}
