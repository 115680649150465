import { BookingState, BookingSteps } from './types';
import { BookingAction } from './bookingActionTypes';
import * as bookingActionTypes from '../bookingActionTypes';

const initialState: BookingState = {
  bookings: [],
  transferBookings: [],
  travelInsuranceBooking: [],
  selectedSearchResults: [],
  currentBooking: null,
  currentStep: BookingSteps.BOOKING,
  webReference: null,
};

export default function bookingReducer(state = initialState, action: BookingAction): BookingState {
  switch (action.type) {
    case bookingActionTypes.TRANSFER_BOOKING_SAVE:
      return {
        ...state,
        transferBookings: action.bookings,
      };
    case bookingActionTypes.INSURANCE_BOOKING_SAVE:
      return {
        ...state,
        travelInsuranceBooking: action.bookings,
      };
    case bookingActionTypes.BOOKING_SAVE_:
      return {
        ...state,
        bookings: action.bookings,
      };
    case bookingActionTypes.BOOKING_CLEAR_:
      return {
        ...state,
        bookings: [],
        travelInsuranceBooking: [],
        transferBookings: [],
      };
    case bookingActionTypes.BOOKING_UPDATE:
      return {
        ...state,
        bookings: action.bookings,
      };

    case bookingActionTypes.BOOKING_CURRENT:
      return {
        ...state,
        currentBooking: action.booking,
      };

    case bookingActionTypes.BOOKING_UPDATE_STEP:
      return {
        ...state,
        currentStep: action.currentStep,
      };

    case bookingActionTypes.BOOKING_CLEAR_SELECTED_BOOKING:
      return {
        ...state,
        currentBooking: null,
      };

    case bookingActionTypes.BOOKING_WEB_REFERENCE:
      return {
        ...state,
        webReference: action.webReference,
      };
    default:
      return state;
  }
}
