import React from 'react';
import { BOOKING_DISCLAIMER_MSG } from 'util/Copies';
import MediaQuery from 'react-media';
import { ROUTE_PREFIX, VIEW_PORTS } from 'util/Constants';
import classNames from 'classnames';

import './Disclaimer.css';

const isFlightSearchPage = `${ROUTE_PREFIX}/search`;
const isBookingPage = `${ROUTE_PREFIX}/booking`;
const isPaymentPage = `${ROUTE_PREFIX}/booking/payment`;
const isConfirmationPage = `${ROUTE_PREFIX}/booking/confirmation`;

const Disclaimer = ({ togglehideDisclaimer }) => {
  return (
    <MediaQuery query={VIEW_PORTS.MOBILE}>
      {(matches) =>
        matches &&
        (location.pathname === isPaymentPage ||
          location.pathname === isBookingPage ||
          location.pathname === isFlightSearchPage ||
          location.pathname === isConfirmationPage) ? null : (
          <div className={classNames({ disclaimer: true })}>
            <span className="cross-button" onClick={togglehideDisclaimer} />
            <span>{BOOKING_DISCLAIMER_MSG}</span>
          </div>
        )
      }
    </MediaQuery>
  );
};

export default Disclaimer;
