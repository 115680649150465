import { TourBookingAction } from './TourBookingActionTypes';
import * as bookingActionTypes from '../../../Booking/bookingActionTypes';
import { BookingSteps } from 'modules/Booking/BookingStore/types';
import { TourBookingState } from './TourBookingTypes';

const initialState: TourBookingState = {
  bookings: [],
  currentStep: BookingSteps.BOOKING,
};

export default function TourBookingReducer(state = initialState, action: TourBookingAction): TourBookingState {
  switch (action.type) {
    case bookingActionTypes.BOOKING_SAVE_:
      return {
        ...state,
        bookings: action.bookings,
      };
    case bookingActionTypes.BOOKING_UPDATE:
      return {
        ...state,
        bookings: action.bookings,
      };
    case bookingActionTypes.BOOKING_CLEAR_:
      return {
        ...state,
        bookings: [],
      };
    default:
      return state;
  }
}
