import { hotjar } from 'react-hotjar';
import { V3UserRoles } from '../modules/V3App/types';

const { REACT_APP_HOTJAR_ID: ID, REACT_APP_HOTJAR_SV: SV } = process.env;

class HotjarService {
  isInitialized = false;

  init() {
    this.isInitialized = true;
    hotjar.initialize(ID, SV);
  }

  addHJUserInfo(authInfo) {
    if (!this.isInitialized || !authInfo.user) {
      return;
    }

    const { email, middleName, nickName, gender, createdAt, isEmailVerified, googleId, fbId, appleId } = authInfo.user;

    const { mobileNumber, city, country, zipCode, state } = authInfo.user.contactDetail;

    hotjar.identify(authInfo.user.email, {
      isPartner: authInfo.role.includes(V3UserRoles.Partner),
      isMember: authInfo.role.includes(V3UserRoles.Member),
      isAgent: authInfo.role.includes(V3UserRoles.Agent),
      email,
      middleName,
      nickName,
      gender,
      createdAt,
      isEmailVerified,
      googleId,
      fbId,
      appleId,
      mobileNumber,
      city,
      country,
      zipCode,
      state,
    });
  }

  // Hotjar only use event name (no key/value pair)
  // e.g: sendHJEvent('viewed_homepage_B'); or sendHJEvent('user_logged_in');
  sendHJEvent(eventName) {
    if (this.isInitialized) {
      hotjar.event(eventName);
    }
  }
}

const hj = new HotjarService();
export default hj;
