import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ERRORS from 'util/ErrorMsgs';
import { checkIfCookiesAreEnabled } from 'util/Helpers';
import { withScope, captureException } from '@sentry/browser';

import './ErrorBoundary.css';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, areCookiesEnabled: true };
  }

  componentDidCatch(error, errorInfo) {
    // Display fallback UI
    this.setState({ hasError: true });
    const areCookiesEnabled = checkIfCookiesAreEnabled();
    this.setState({ areCookiesEnabled });
    console.error('block-error occurred', error);

    withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      captureException(error);
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className={`block-error ${this.props.options.styleClasses}`}>
          <h3 className="title">{ERRORS.SOMETHING_WRONG}</h3>
          <p className="hint-message">
            {this.state.areCookiesEnabled ? this.props.options.text : ERRORS.DISABLED_COOKIES}
          </p>
        </div>
      );
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.object,
  options: PropTypes.object.isRequired,
};

export default ErrorBoundary;
