/* eslint-disable */
import React, { useEffect, useState } from 'react';
import nortonImg from 'static/images/norton.png';

let nortonDiv = null;

const Norton = () => {
  let v_mact = null;

  useEffect(() => {
    nortonLoader();
    return () => {
      v_mact && document.removeEventListener('click', v_mact, true);
    };
  });

  function nortonLoader() {
    var dn = 'www.sastaticket.pk';
    var lang = 'en';
    var tpt = 'transparent';
    var vrsn_style = 'WW';
    var splash_url = 'https://trustsealinfo.websecurity.norton.com';
    var sslcenter_url = 'https://www.symantec.com/page.jsp?id=ssl-information-center';
    var seal_url = 'https://seal.websecurity.norton.com';

    var u1 = splash_url + '/splash?form_file=fdf/splash.fdf&dn=' + dn + '&lang=' + lang;
    var u2 = seal_url + '/getseal?at=0&sealid=1&dn=' + dn + '&lang=' + lang + '&tpt=' + tpt;
    var u5 = sslcenter_url;
    var sopener;

    function symcBuySSL() {
      var win = window.open(u5, '_blank');
      win.focus();
    }

    function vrsn_splash() {
      if (sopener && !sopener.closed) {
        sopener.focus();
      } else {
        const tbar = 'location=yes,status=yes,resizable=yes,scrollbars=yes,width=560,height=500';
        var sw = window.open(u1, 'VRSN_Splash', tbar);
        if (sw) {
          sw.focus();
          sopener = sw;
        }
      }
    }

    var ver = -1;
    var v_ua = navigator.userAgent.toLowerCase();
    var re = new RegExp('msie ([0-9]{1,}[.0-9]{0,})');
    if (re.exec(v_ua) !== null) {
      ver = parseFloat(RegExp.$1);
    }
    var v_old_ie = v_ua.indexOf('msie') != -1;
    if (v_old_ie) {
      v_old_ie = ver < 5;
    }

    function v_mact(e) {
      var s;
      if (document.addEventListener) {
        s = e.target.name == 'seal';
        if (s) {
          vrsn_splash();
          return false;
        }
      } else if (document.captureEvents) {
        var tgt = e.target.toString();
        s = tgt.indexOf('splash') != -1;
        if (s) {
          vrsn_splash();
          return false;
        }
      }
      return true;
    }

    function v_mDown(event) {
      if (document.addEventListener) {
        return true;
      }
      event = event || window.event;
      if (event) {
        if (event.button == 1) {
          if (v_old_ie) {
            return true;
          } else {
            vrsn_splash();
            return false;
          }
        } else if (event.button == 2) {
          vrsn_splash();
          return false;
        }
      } else {
        return true;
      }
    }

    if (nortonDiv) {
      nortonDiv.innerHTML =
        '<img  style="cursor: pointer;" name="seal" src="' +
        nortonImg +
        '" oncontextmenu="return false;" border="0" usemap="#sealmap_medium" alt="Powered by Norton Secured" />';
      nortonDiv.innerHTML += '<map name="sealmap_medium" id="sealmap_medium" >';
      nortonDiv.innerHTML +=
        '<area  alt="Click to Verify - This site has chosen an SSL Certificate to improve Web site security" title="" href="javascript:vrsn_splash()" shape="rect" coords="0,0,115,58" tabindex="-1" style="outline:none;" />';
      nortonDiv.innerHTML +=
        '<area  alt="Click to Verify - This site has chosen an SSL Certificate to improve Web site security" title="" href="javascript:vrsn_splash()" shape="rect" coords="0,58,63,81" tabindex="-1" style="outline:none;" />';
      nortonDiv.innerHTML +=
        '<area  alt="" title="" href="javascript:symcBuySSL()" shape="rect" coords="63,58,115,81" style="outline:none;" />';
      nortonDiv.innerHTML += '</map>';
    }
    if (v_ua.indexOf('msie') != -1 && ver >= 7) {
      var plat = -1;
      var re = new RegExp('windows nt ([0-9]{1,}[.0-9]{0,})');
      if (re.exec(v_ua) !== null) {
        plat = parseFloat(RegExp.$1);
      }
      if (plat >= 5.1 && plat != 5.2) {
        nortonDiv.innerHTML = "<div style='display:none'>";
        nortonDiv.innerHTML += "<img src='https://extended-validation-ssl.websecurity.symantec.com/dot_clear.gif'/>";
        nortonDiv.innerHTML += '</div>';
      }
    }
    if (document.addEventListener) {
      v_mact = v_mact;
      document.addEventListener('click', v_mact, true);
    } else {
      if (document.layers) {
        document.captureEvents(Event.MOUSEDOWN);
        document.onclick = v_mact;
      }
    }

    function v_resized() {
      if (pageWidth != innerWidth || pageHeight != innerHeight) {
        self.history.go(0);
      }
    }

    if (document.layers) {
      pageWidth = innerWidth;
      pageHeight = innerHeight;
      window.onresize = v_resized;
    }
  }

  return (
    <div className="norton" title="Norton Secured Transactions">
      <div
        id="norton-div"
        ref={(norDiv) => {
          nortonDiv = norDiv;
        }}
      />
      <a href="http://www.symantec.com/ssl-certificates" target="_blank">
        ABOUT SSL CERTIFICATES
      </a>
    </div>
  );
};

export default Norton;
