import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { FeatureToggles, Feature } from '@paralleldrive/react-feature-toggles';
import { isActiveFeatureName } from '@paralleldrive/feature-toggles';
import DSMHeader from '../../v2/App/Components/Header/Header';
import Footer from '../../v2/App/Components/Footer/Footer';
import { showToast } from 'shared/Toast/Toast';
import withErrorBoundary from 'util/HOC/errorBoundary';
import Disclaimer from './components/Header/Disclaimer/Disclaimer';
import VerificationBanner from 'shared/VerificationBanner/VerificationBanner';

import {
  getFromLS,
  removeInLS,
  stringDecrypt,
  getAppWrapperClasses,
  setInLS,
  stringEncrypt,
  checkIfCookiesAreEnabled,
  redirectToPage,
} from 'util/Helpers';
import { localStorageChangeListener } from 'auth';
import routes from 'routes/routes';
import umrahRoutes from 'routes/umrahRoutes';
import tourRoutes from 'routes/tourRoutes';
import busRoutes from 'routes/busRoutes';

import { removeUser, saveUser, socialLogin, sendVerificationEmail } from '../Account/accountActions';

import { SUPPORTED_ID_TOKEN_PROVIDER, SUPPORTED_AUTH_METHOD, ROUTE_PREFIX, FEATURES } from 'util/Constants';

import SastaWalletContextProvider from 'modules/Account/SastaWallet/Context/SastaWalletContext';
import { isLandingPageRoute } from '../V3App/helpers';

import './App.css';

class FlightApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisclaimerVisible: false,
    };
    this.setupUserInfo = this.setupUserInfo.bind(this);
  }

  componentWillMount() {
    this.setupUserInfo();
  }

  // google One Tap SignIn

  handleSocialLogin(provider, payload) {
    this.props
      .socialLogin(provider, payload)
      .then((response) => {
        setInLS('user', stringEncrypt(JSON.stringify(response)));
        this.props.history.push(`${ROUTE_PREFIX}/account/edit`);
      })
      .catch((e) => {
        showToast({ msg: `Error! Login with google : ${e}`, type: 'error' });
      });
  }

  googleOneTapSignUp(googleyolo) {
    googleyolo
      .hint({
        supportedAuthMethods: SUPPORTED_AUTH_METHOD,
        supportedIdTokenProviders: SUPPORTED_ID_TOKEN_PROVIDER,
      })
      .then(
        (credential) => {
          if (credential.idToken) {
            this.handleSocialLogin('g', { accessToken: credential.idToken });
          }
        },
        (error) => {
          if (error.type === 'noCredentialsAvailable') this.googleOneTapSignUp(googleyolo);
        }
      );
  }

  googleOneTapLogin() {
    window.onGoogleYoloLoad = (googleyolo) => {
      googleyolo
        .retrieve({
          supportedAuthMethods: SUPPORTED_AUTH_METHOD,
          supportedIdTokenProviders: SUPPORTED_ID_TOKEN_PROVIDER,
        })
        .then(
          (credential) => {
            this.handleSocialLogin('g', { accessToken: credential.idToken });
          },
          (error) => {
            switch (error.type) {
              case 'noCredentialsAvailable':
                this.googleOneTapSignUp(googleyolo);
                break;
              case 'requestFailed':
                this.googleOneTapLogin();
                break;
            }
          }
        );
    };
  }

  setupUserInfo() {
    /* sanity check to prevent build errors in production-build
   because browser objects aren't available during SSR
	 further reading: https://stackoverflow.com/a/33725841/1791667  */
    if (checkIfCookiesAreEnabled() && typeof localStorage !== 'undefined') {
      let user = stringDecrypt(getFromLS('user') || '');
      if (user) {
        user = JSON.parse(user);
        this.props.saveUser(user);
      } else {
        // remove previously stored user-details from LS if they're invalid
        removeInLS('user');
      }
    }

    // To detect local stroge changes on all the open tabs.
    if (typeof window !== 'undefined') {
      window.addEventListener('storage', localStorageChangeListener, false);
    }
  }

  togglehideDisclaimer = () => {
    this.setState({
      isDisclaimerVisible: false,
    });
  };

  sendVerificationEmail = () => {
    this.props
      .sendVerificationEmail()
      .then(({ json }) => {
        if (json.success) {
          showToast({ msg: json.message, type: 'info' });
        } else {
          showToast({ msg: json.message, type: 'error' });
        }
      })
      .catch((error) => showToast({ msg: error.message, type: 'error' }));
  };

  render() {
    const {
      mobileConfig,
      webConfig,
      location: { pathname: path },
      user,
    } = this.props;
    // Note: We will need this logic in future when we override features config depend on each user.
    // const { REACT_APP_DEPLOYMENT_ENVIRONMENT: TARGET_ENV } = process.env;
    // const features_env = TARGET_ENV === 'development' || TARGET_ENV === 'staging' ? 'local' : 'production';
    // const features = userFeatureFlags ? userFeatureFlags[features_env]['features'] : process.env.features;

    const { isDisclaimerVisible } = this.state;
    const rootClass = getAppWrapperClasses(mobileConfig, webConfig, path);
    const isLandingPage = isLandingPageRoute(this.props.location);

    if (document && document.body) {
      // set up classes on body at the top for responsive design
      document.body.className = rootClass;
    }

    return (
      <>
        <FeatureToggles features={process.env.features}>
          <Feature>
            {({ features }) =>
              isActiveFeatureName(FEATURES.VERIFICATION_BANNER, features) &&
              user &&
              !user.isEmailVerified && (
                <VerificationBanner
                  displayText={
                    <span>
                      Complete your account setup by clicking{' '}
                      <a href="javascript:void(0);" onClick={this.sendVerificationEmail}>
                        here
                      </a>
                      . A verification email will be sent to your registered email address.
                    </span>
                  }
                />
              )
            }
          </Feature>
          <div
            id="wrapper"
            className={classNames({
              wrapper: true,
              disclaimered: isDisclaimerVisible,
              'hide-v3-banner': !isLandingPage,
              // only show v3 banner on landing page
            })}
          >
            {isDisclaimerVisible && <Disclaimer togglehideDisclaimer={this.togglehideDisclaimer} />}
            <SastaWalletContextProvider>
              <DSMHeader {...this.props} />
              <Fragment>
                {routes}
                {/* {umrahRoutes} */}
                {/* {tourRoutes}
                {busRoutes} */}
              </Fragment>
            </SastaWalletContextProvider>
            {webConfig.appFooterVisible && <Footer path={path} />}
          </div>
        </FeatureToggles>
      </>
    );
  }
}

FlightApp.propTypes = {
  location: PropTypes.object.isRequired,
  mobileConfig: PropTypes.object.isRequired,
  webConfig: PropTypes.object.isRequired,
  saveUser: PropTypes.func.isRequired,
  sendVerificationEmail: PropTypes.func.isRequired,
  user: PropTypes.object,
};

// Retrieve data from store as props
function mapStateToProps(store) {
  return {
    mobileConfig: store.app.mobileConfig,
    webConfig: store.app.webConfig,
    user: store.account.user.user,
  };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      removeUser,
      saveUser,
      socialLogin,
      sendVerificationEmail,
    },
    dispatch
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withErrorBoundary(FlightApp)));
