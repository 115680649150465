// Libraries
// @ts-ignore
import React, { useState } from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { Feature } from '@paralleldrive/react-feature-toggles';
import isEmpty from 'lodash.isempty';

// DSM Components
import { STHeader } from 'design/organisms/Header';
import { STFlex } from 'design/atoms/Container';
import { Box } from 'design/atoms/Box';
import { STNavItem } from 'design/atoms/NavItem/NavItem';
import { STNavCollapse, STNavItemsGroup, STNavbar, STNavbarToggler } from 'design/molecules/Navigation/';
import { STLink, STLabel, STNavLink } from 'design/atoms/Text';

// Components
import { LoggedInPopover } from './LoggedInPopover';

// Static
import stlogo from 'static/images/st-logo-blue.svg';

// Utils and Constants
import { isFeatureEnabled } from 'util/Helpers';
import { FEATURES, USER_ROLE } from 'util/Constants';
import { logoutUser } from 'auth';

// Types
import { UserDetail } from 'modules/Account/AccountTypes';

//Hook
import { useSastaWallet } from 'modules/Account/SastaWallet/Context/useSastaWallet';

export interface HeaderProps extends RouteComponentProps {
  user: UserDetail;
  userRole: any[];
  isLoggedIn: any[];
}

interface RouterLinkProps {
  path: string;
  search?: string;
  children: React.ReactNode;
}

export const RouterLink = ({ path, search, children }: RouterLinkProps) => {
  return <STNavLink to={{ pathname: path, search }}>{children}</STNavLink>;
};

const Header: React.FC<HeaderProps> = ({ user, userRole, isLoggedIn, location, ...props }) => {
  // TODO: We need to update the links for "active" logic when we create those pages
  const pathname = location.pathname;
  const [isDrawerOpen, changeDrawerVisibility] = useState(false);
  const isFlightsProduction = isFeatureEnabled(FEATURES.AIR_V1_HEADER_STYLE);
  // TODO see if we can remove the 1140px from there.
  const isPartner = USER_ROLE.filter((role) => userRole && userRole.includes(role));
  const { isSastaWalletEnable } = useSastaWallet();

  return (
    <STHeader>
      <STFlex
        justifyContent="space-between"
        px={[3, 0]}
        alignItems="center"
        width={isFlightsProduction ? '1170px' : 1}
        maxWidth={isFlightsProduction ? 'auto' : '1270px'}
      >
        <STNavbar mx={[0, 5]}>
          <STNavbarToggler onClick={() => changeDrawerVisibility(true)} />
          <Box mx={['auto', 0]} my="auto" left={0} right={0}>
            {/* @ts-ignore */}
            <Box width={['130px', '160px']} pt={1} height={['20px', '38px']} as="img" src={stlogo} />
          </Box>
          <STNavCollapse isOpen={isDrawerOpen} onClose={() => changeDrawerVisibility(false)}>
            <STNavItemsGroup convertDrawer={true}>
              <Box ml={[0, 3]}>
                <STLink variant="blueRegularClean" as="a" target="_blank" href={`${process.env.REACT_APP_BASE_URL}/`}>
                  <STNavItem active={false} label="Flights" pr={[0, 3]} pl={3} width="85%" hoverEffect />
                </STLink>
              </Box>
              <Feature
                name={FEATURES.BUS}
                activeComponent={() => (
                  <STLink
                    variant="blueRegularClean"
                    as="a"
                    target="_blank"
                    href={`${process.env.REACT_APP_BASE_URL}/bus/`}
                  >
                    <STNavItem active={false} label="Bus" pr={[0, 3]} pl={[3, 3]} width="85%" hoverEffect />
                  </STLink>
                )}
                inactiveComponent={() => <></>}
              />
              <STLink
                variant="blueRegularClean"
                as="a"
                target="_blank"
                href={`${process.env.REACT_APP_BASE_URL}/pages/visa`}
              >
                <STNavItem active={false} label="Visas" hoverEffect={true} pr={[0, 3]} pl={[3, 3]} />
              </STLink>
            </STNavItemsGroup>

            <STNavItemsGroup convertDrawer={true} align="right">
              <STFlex justifyContent="flex-end" flexDirection={['column', 'row']} width="100%">
                <STLabel display={[user ? 'block' : 'none', 'none']} onClick={() => logoutUser()}>
                  <STNavItem active={false} label="Sign Out" hoverEffect={false} pr={[0, 3]} pl={3} />
                </STLabel>
                <Box display={['none', user ? 'block' : 'none']}>
                  {!isEmpty(user) && <LoggedInPopover user={user} userRole={userRole} />}
                </Box>
                <Box display={['none', 'flex']} alignItems="center" justifyContent="center">
                  <Box width="2px" height="15px" bg="grey20">
                    {''}
                  </Box>
                </Box>

                {isLoggedIn && !isEmpty(isPartner) ? (
                  <STLink variant="blueRegularClean" as="a" href="https://partner.sastaticket.pk/" target="_blank">
                    <STNavItem active={false} label="Partner" pr={[0, 3]} pl={3} />
                  </STLink>
                ) : (
                  <STLink variant="blueRegularClean" as="a" href="https://partner.sastaticket.pk/" target="_blank">
                    <STNavItem active={false} label="Partner With Us" pr={[0, 3]} pl={3} />
                  </STLink>
                )}
                <Box display={['none', 'flex']} alignItems="center" justifyContent="center">
                  <Box width="2px" height="15px" bg="grey20">
                    {''}
                  </Box>
                </Box>
                <Box>
                  <STLink display={['none', 'flex']} variant="blueRegularClean" as="a" href="tel:00921111172782">
                    <STNavItem
                      active={false}
                      label="021 111 172 782"
                      pr={[0, isFlightsProduction ? 0 : 3]}
                      pl={3}
                      minWidth="85px"
                    />
                  </STLink>
                </Box>
              </STFlex>
            </STNavItemsGroup>
          </STNavCollapse>
        </STNavbar>
      </STFlex>
    </STHeader>
  );
};

var mapStateToProps = (state: any) => ({
  userRole: state.account.user.role,
  user: state.account.user.user,
  isLoggedIn: state.account.user && state.account.user.token,
});

export default connect(mapStateToProps)(withRouter(Header));
