import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { STToastContainer } from 'design/atoms/Toast';
import { ThemeProvider } from 'styled-components';

import defaultTheme from 'design/theme';
import { ModalProvider } from 'styled-react-modal';
import { IntlProvider, i18n } from './locale';

import initializeLibraries from 'util/libInitializer';
import AppProvider from './app-provider/AppProvider';

require('@babel/polyfill');

export default class App extends Component {
  componentWillMount() {
    initializeLibraries();
  }

  render() {
    return (
      <Provider store={this.props.store}>
        <ThemeProvider theme={defaultTheme}>
          <ModalProvider>
            <IntlProvider value={i18n}>
              <STToastContainer />
              <Router>
                <AppProvider />
              </Router>
            </IntlProvider>
          </ModalProvider>
        </ThemeProvider>
      </Provider>
    );
  }
}

App.propTypes = {
  store: PropTypes.object,
};
