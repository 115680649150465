require('@babel/polyfill');

import * as React from 'react';
import { render } from 'react-dom';
import * as Sentry from '@sentry/browser';
import App from './App';
import { configureStore } from './store';
import { unregister } from './registerServiceWorker';

declare global {
  interface Window {
    __INITIAL_STATE__: any;
  }
}

Sentry.addBreadcrumb({
  message: 'redux logs in breadcrumb',
});

const store = configureStore(window.__INITIAL_STATE__);
const rootEle = document.getElementById('root');

render(<App store={store} />, rootEle);
unregister();
