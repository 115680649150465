import React from 'react';
import { STFlex } from '../../atoms/Container';

interface STHeaderProps {
  children: React.ReactNode;
}

export const STHeader:React.FC<STHeaderProps> = ({ children }) => {
  return (
    <STFlex height={['56px', '65px']} as="header" width='100%' justifyContent="center" bg="white">
      {children}
    </STFlex>
  );
};