import React from 'react';
import { STFlex } from 'design/atoms/Container';
import TopFooter from './TopFooter';
import MiddleFooter from './MiddleFooter';
import BottomFooter from './BottomFooter';

const Footer = ({ path }) => {
  return (
    <STFlex flexDirection="column" bg="disabled">
      <TopFooter path={path} />
      <MiddleFooter path={path} />
      <BottomFooter />
    </STFlex>
  );
};

export default Footer;
