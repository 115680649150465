import {
  Passenger,
  ContactDetail,
  RouteSegment,
  RoutePriceDetails,
  FlightSearchResult,
  RouteLeg,
  FlightMeta,
} from '../../../standardTypes';
import { Query } from '../../Search/SearchStore/searchTypes';
//todo: we are not using optional types

export interface initiateBookingPayload {
  query: Query;
  itinerary_id: string;
  is_international: false;
  gaf_key: string;
  selectedSearchresult: FlightSearchResult;
}

export interface TravelerCount {
  numAdult: number;
  numChild: number;
  numInfant: number;
}

export interface OldPrice {
  id: number;
  currency: string;
  sellingFare: number;
  publishedFare: number;
  grossFare: number;
  tax: number;
}

export interface Booking {
  id: number;
  uuid: number | null;
  webReference: string | null;
  webReferenceExpiry: Date | null;
  pnrExpiryTimeout: Date | null;
  createdAt: Date | null;
  provider: string;
  numChild: number;
  numAdult: number;
  numInfant: number;
  price: RoutePriceDetails;
  oldLegs: Array<RouteLeg> | []; //format
  oldPricing?: OldPrice[];
  legs: Array<RouteLeg> | []; //format
  orderId: number | null;
  passengers: Array<Passenger> | [];
  pnr: string | null;
  contactDetails: ContactDetail;
  isInternational: boolean;
  searchQuery: SearchQuery;
  bookingType: string;
  searchMeta: FlightMeta;
}
export interface InsurancePayload {
  travellingTo: string;
  startDate: string;
  endDate?: string;
}
export interface InsuranceSearchQuery {
  referenceId: string;
  query: InsurancePayload;
}

export interface InsuranceBooking {
  webReference: string;
  status: string;
  createdAt: Date;
  searchQuery: InsuranceSearchQuery;
  id: number;
  order: number;
  provider: string;
  startDate: Date;
  endDate: Date;
  coverage: string;
  packageId: string;
  packageName: string;
  packageType: string;
  packageDescription: string;
  totalFare: number;
  travelType: string;
  isInternational: boolean;
  proposalId: string;
  insuranceHolder: Passenger;
  contactDetails: ContactDetail;
  buyer: Buyer;
  meta: Meta;
  bookingType: string;
}

export interface TransferLeg {
  id: number;
  createdAt: string;
  lastUpdatedAt: string;
  origin: string;
  destination: string;
  isToAirport: boolean;
  fare: number;
  city: string;
  options: any;
  pickupDatetime: string;
  driverName: string | null;
  driverNumber: string | null;
  carNumber: string | null;
  carModel: string | null;
  vendor: string | null;
  referenceNo: string | null;
}

export interface TransferBooking {
  id: number;
  createdAt: string;
  provider: string;
  legs: TransferLeg[];
  totalFare: number;
  bookingType: string;
}

interface Buyer {
  cityName: null | string;
  address: null | string;
}

export interface Meta {
  city: string;
  country: string;
  discount: number;
  premium_Filer: number;
}
export interface BookingState {
  bookings: Array<Booking>;
  selectedSearchResults: Array<FlightSearchResult>;
  currentBooking: Booking | null;
  currentStep: BookingSteps;
  webReference: string | null;
  travelInsuranceBooking: Array<InsuranceBooking>;
  transferBookings: Array<TransferBooking>;
}

export enum BookingSteps {
  BOOKING = 1,
  PAYMENT = 2,
  PROCESS = 3,
  ETICKET = 4,
}

export interface SearchQuery {
  travelerCount: TravelerCount;
}

export interface TransferLegPayload {
  booking: string;
  address: string;
  pickup_time: string;
}

export interface OldLegs {
  id: number;
  currency: string;
  sellingFare: number;
  publishedFare: number;
  grossFare: number;
  tax: number;
}
