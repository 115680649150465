/*
  Form doesn't reinitialize when replaced.
  https://github.com/erikras/redux-form/issues/3435.
  proposed solution: https://github.com/erikras/redux-form/issues/3435#issuecomment-359371803
*/

import { actionTypes } from 'redux-form';

const state = {};
export default () => (next) => (action) => {
  if (action) {
    if (action.meta && action.meta.form) {
      switch (action.type) {
        case actionTypes.DESTROY:
          state[action.meta.form] = (state[action.meta.form] || 0) - 1;
          if (state[action.meta.form] <= 0) {
            return next(action);
          }
          // Drop the action
          return false;
        case actionTypes.INITIALIZE:
          state[action.meta.form] = (state[action.meta.form] || 0) + 1;
          return next(action);
        default:
          return next(action);
      }
    }

    return next(action);
  }
};
