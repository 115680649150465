import { ResultsState, ResultAction, ResultActiontypes } from './resultTypes';
import resultsInitialState from './resultInitialState';

const {
  ADD_RESULTS,
  CLEAR_RESULTS,
  ADD_COMBO_TYPE,
  CHANGE_STEP,
  SELECT_SEARCH_RESULT,
  CLEAR_SELECTED_RESULT,
  ADD_CALENDAR_FARES,
  CLEAR_CALENDAR_FARES,
} = ResultActiontypes;

const resultsReducer = (state = resultsInitialState, action: ResultAction): ResultsState => {
  switch (action.type) {
    case ADD_RESULTS:
    case CLEAR_RESULTS:
      return {
        ...state,
        searchResults: action.searchResults,
      };
    case ADD_COMBO_TYPE:
      return {
        ...state,
        isInternationalCombo: action.isInternationalCombo,
      };
    case CHANGE_STEP:
      // TODO need to see if there is a better place to put this
      let selectedResults = state.selectedResults;
      const index = action.multiCityStepNumber;
      if (state.multiCityStepNumber > index) {
        // this means we are going back, need to update the selectedResults too
        // we need to remove all forward steps including the current one
        // so if we go from 0 to 1, we remove all
        // if we go from 3 to 2, we remove 2 only
        // edge case: when the user selects the last leg, they come back
        if (index === 0) {
          selectedResults = [];
        } else if (index === 1) {
          selectedResults = [
            {
              ...state.selectedResults[0],
              legs: [state.selectedResults[0].legs[0]],
            },
          ];
        } else {
          // only multicity, currently only has one object, so just slice the
          // legs as needed
          selectedResults = [
            {
              ...state.selectedResults[0],
              legs: state.selectedResults[0].legs.slice(0, index),
            },
          ];
        }
      }
      return {
        ...state,
        selectedResults,
        multiCityStepNumber: index,
      };
    case SELECT_SEARCH_RESULT:
      return {
        ...state,
        selectedResults: action.selectedResults,
      };
    case CLEAR_SELECTED_RESULT:
      return {
        ...state,
        selectedResults: [],
      };
    case CLEAR_CALENDAR_FARES:
      return {
        ...state,
        calendarFares: [],
      };
    case ADD_CALENDAR_FARES:
      return {
        ...state,
        calendarFares: action.calendarFares,
      };
    default:
      return state;
  }
};

export default resultsReducer;
