import fbp from 'util/FacebookPixelService';
import hj from 'util/HotjarService';
import gtm from 'util/GoogleTagManagerService';

import * as Sentry from '@sentry/browser';
import { amplitudeService } from './amplitude/AmplitudeService';

const { REACT_APP_DEPLOYMENT_ENVIRONMENT: TARGET_ENV } = process.env;

export function sentryInit() {
  const url = `https://${process.env.REACT_APP_SENTRY_API_KEY}@sentry.io/${process.env.REACT_APP_SENTRY_API_PROJECT}`;

  Sentry.init({
    dsn: url,
    blacklistUrls: [],
    integrations(defaultIntegrations) {
      // remove deprecation ReportingObserver integration
      return defaultIntegrations.filter((integration) => integration.name !== 'ReportingObserver');
    },
  });
}

export function initFacabookPixelAnalytics() {
  fbp.init();
}

export function initHotjar() {
  hj.init();
}

export function initGTM() {
  gtm.init();
}

export function initAmplitude() {
  amplitudeService.init();
}

const initializeLibraries = () => {
  // load orders should be maintained
  const loaders = {
    production: [initHotjar.bind(this)],
    development: [],
    staging: [],
    sstaging: [],
    test: [],
    ecs: [sentryInit.bind(this), initHotjar.bind(this), initGTM.bind(this)],
  };
  if (!loaders[TARGET_ENV]) return;
  loaders[TARGET_ENV].map((initiateLibrary) => initiateLibrary());
};

export default initializeLibraries;
