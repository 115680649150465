export interface V3UserContactDetails {
  id: number;
  country: string;
  mobileNumber: string;
  city: string;
  state: string;
  zipCode: string;
  street1: string;
  street2: string;
  emFirstName: string;
  emLastName: string;
  emTelephone: string;
  user: number;
}

export enum V3UserRoles {
  Member = 'Member',
  Agent = 'Agent',
  Partner = 'Partner',
}

export interface V3User {
  email: string;
  dateOfBirth: string;
  firstName: string;
  middleName: string;
  lastName: string;
  nickName: string;
  gender: string;
  title: string;
  contactDetail: V3UserContactDetails;
  alertsSubscription: boolean;
  createdAt: string;
  gaClientId: string;
  gaDocumentReferrer: string;
  isSuperuser: boolean;
  isEmailVerified: boolean;
  googleId: string;
  fbId: string;
  appleId: string;
  id: number;
}

export interface V3AuthenticatedUserInfo {
  role: V3UserRoles[];
  user: V3User;
  token: string;
  refreshToken: string;
  isUserCreated: boolean;
  xAuthStrategy: string;
}
