import React, { useState } from 'react';
import Popover, { ArrowContainer } from 'react-tiny-popover';
import { withTheme } from 'styled-components';
import { Position } from 'react-tiny-popover/dist/index';
import { Theme } from 'styled-system';
import { Box, StyledSystemProps } from 'design/atoms/Box';
import { STFlex } from 'design/atoms/Container';

interface PopoverProps extends StyledSystemProps {
  contentPosition: Position | Position[];
  trigger: JSX.Element;
  theme?: Theme;
  render: (togglePopover: Function) => JSX.Element;
  outsideClickEvent?: Function;
}
export const STPopover: React.FC<PopoverProps> = ({
  contentPosition,
  trigger,
  children,
  bg,
  render,
  outsideClickEvent,
}) => {
  let [isOpen, togglePopover] = useState(false);
  return (
    <Popover
      isOpen={isOpen}
      position={contentPosition}
      padding={1}
      align={'start'}
      disableReposition={true}
      onClickOutside={() => {
        togglePopover(!isOpen);
        outsideClickEvent && outsideClickEvent(togglePopover);
      }}
      content={
        <Box bg={bg} minWidth="100px" maxWidth="400px">
          {render(togglePopover)}
        </Box>
      }
    >
      <Box width="100%" data-item="trigger" onClick={() => togglePopover(!isOpen)}>
        {trigger}
      </Box>
    </Popover>
  );
};

export const STPopoverWithArrow: React.FC<PopoverProps> = withTheme(
  ({ contentPosition, trigger, children, theme, bg }) => {
    let [isOpen, togglePopover] = useState(false);
    return (
      <Popover
        isOpen={isOpen}
        position={contentPosition}
        padding={1}
        align={'start'}
        onClickOutside={() => togglePopover(false)}
        content={({ position, targetRect, popoverRect }) => (
          <ArrowContainer
            position={position}
            targetRect={targetRect}
            popoverRect={popoverRect}
            arrowColor={theme.colors.disabled}
            arrowSize={10}
          >
            <Box bg={bg} minWidth="100px" maxWidth="310px">
              {children}
            </Box>
          </ArrowContainer>
        )}
      >
        <STFlex onClick={() => togglePopover(!isOpen)}>{trigger}</STFlex>
      </Popover>
    );
  }
);
