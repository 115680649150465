/* eslint-disable */

// In production, we register a service worker to serve assets from local cache.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on the "N+1" visit to a page, since previously
// cached resources are updated in the background.

// To learn more about the benefits of this model, read https://goo.gl/KwvDNy.
// This link also includes instructions on opting out of this behavior.

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

function registerValidSW(swUrl) {
  return new Promise((resolve, reject) => {
    navigator.serviceWorker
      .register(swUrl, { scope: '/' })
      .then((registration) => {
        registration.onupdatefound = () => {
          const installingWorker = registration.installing;
          installingWorker.onstatechange = () => {
            if (installingWorker.state === 'installed') {
              if (navigator.serviceWorker.controller) {
                console.log('Service worker installed!');
                // Service worker updates cache
                registration.update();
                //refreshing the page will show latest content
                window.location.reload();
              }
              // At this point, everything has been precached.
              // It's the perfect time to display a
              // "Content is cached for offline use." message.
              return resolve('Content is cached for offline use.');
            }
            return reject(new Error('error'));
          };
        };
      })
      .catch((error) => {
        return reject(new Error(error));
      });
  });
}

function checkValidServiceWorker(swUrl) {
  // Check if the service worker can be found. If it can't reload the page.
  return new Promise((resolve, reject) => {
    fetch(swUrl)
      .then((response) => {
        // Ensure service worker exists, and that we really are getting a JS file.
        if (response.status === 404 || response.headers.get('content-type').indexOf('javascript') === -1) {
          // No service worker found. Probably a different app. Reload the page.
          navigator.serviceWorker.ready.then((registration) => {
            registration.unregister().then(() => {
              window.location.reload();
            });
          });
        } else {
          // Service worker found. Proceed as normal.
          registerValidSW(swUrl)
            .then((data) => {
              return resolve(data);
            })
            .catch((err) => {
              return reject(`Error during service worker registration: ${err}`);
            });
        }
      })
      .catch((err) => {
        return reject(`No internet connection found. App is running in offline mode: ${err}`);
      });
  });
}

export default function register() {
  const { REACT_APP_DEPLOYMENT_ENVIRONMENT: TARGET_ENV } = process.env;

  if ((TARGET_ENV === 'ecs' || TARGET_ENV === 'staging') && 'serviceWorker' in navigator) {
    if (isLocalhost) {
      return;
    }

    window.addEventListener('load', () => {
      const swUrl = `${window.location.origin}/static/service-worker.js`;

      checkValidServiceWorker(swUrl)
        .then((data) => {
          console.log(data);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }
}

export async function unregister() {
  if ('serviceWorker' in navigator) {
    try {
      // deleting registrations
      const registrations = await navigator.serviceWorker.getRegistrations();
      const unregisterPromises = registrations?.map((registration) => registration.unregister());

      // deleting caching
      const allCaches = await caches.keys();
      const cacheDeletionPromises = allCaches.map((cache) => caches.delete(cache));

      await Promise.all([...unregisterPromises, ...cacheDeletionPromises]);
    } catch (error) {
      console.log('Error during service worker unregister process: ', error);
    }
  }
}
