import React, { FC } from 'react';
import styled from 'styled-components';

import {
  color,
  layout,
  flexbox,
  space,
  border,
  typography,
  position,
  shadow,
  SpaceProps,
  ColorProps,
  TypographyProps,
  FlexboxProps,
  LayoutProps,
  BordersProps,
  BackgroundProps,
  PositionProps,
  ShadowProps,
  background,
} from 'styled-system';

type TagProps = {
  as:
    | 'div'
    | 'img'
    | 'span'
    | 'p'
    | 'a'
    | 'table'
    | 'td'
    | 'th'
    | 'tr'
    | 'aside'
    | 'section'
    | 'header'
    | 'button'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'label'
    | 'textarea';
  src: any;
  onClick?(event?: React.MouseEvent<HTMLElement> ) : void;
  onBackgroundClick?: () => void;
  onEscapeKeydown?: () => void;
  variant?: string;
  align?: string;
};

export interface StyledSystemProps
  extends SpaceProps,
    ColorProps,
    TypographyProps,
    FlexboxProps,
    LayoutProps,
    BordersProps,
    BackgroundProps,
    PositionProps,
    Partial<TagProps>,
    ShadowProps {
  className?: string;
  color?: string;
  whiteSpace?: string;
}

export const Box = styled.div<StyledSystemProps>`
  ${layout}
  ${flexbox}
  ${color}
  ${space}
  ${border}
  ${typography}
  ${position}
  ${shadow}
  ${background}
  box-sizing: border-box;
`;
