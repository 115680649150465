import React from 'react';

import PropTypes from 'prop-types';

import PageLoader from './components/PageLoader/PageLoader';
import SimpleLoader from './components/SimpleLoader/SimpleLoader';

import './SastaLoader.css';

const SastaLoader = ({ type, options = {}, title, isLoading }) => {
  switch (type) {
    case 'simpleloader':
      return <SimpleLoader isLoading={isLoading} options={options} />;
    case 'pageloader':
    default:
      return <PageLoader options={options} title={title} />;
  }
};

SastaLoader.propTypes = {
  isLoading: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.string,
  options: PropTypes.object,
};

export default SastaLoader;
