const enableXDCommunication = Boolean(process.env.REACT_APP_ENABLE_XD_COMMUNICATION);
const xdIframeLink = process.env.REACT_APP_XD_IFRAME_LINK;

if (enableXDCommunication && !xdIframeLink) {
  throw new Error('REACT_APP_XD_IFRAME_LINK is missing!');
}

export enum XDCEventsToReceive {
  ParentInit = 'Pong',
  AuthInfo = 'AuthInfo',
  ParentInitRoute = 'ParentInitRoute',
  Logout = 'Logout',
}

export enum XDCEventsToSend {
  XDCInit = 'Ping',
  Logout = 'Logout',
  Login = 'Login',
  V3RouteChanged = 'V3RouteChanged',
  V2RouteChangeRequest = 'V2RouteChangeRequest',
  GTMEvent = 'GTMEvent',
}

type XDCEventHandler = (data: any) => void;

export interface XDCBaseReceiveEvent<T> {
  name: XDCEventsToReceive;
  data: T;
}

export interface XDCBaseSendEvent<T> {
  name: XDCEventsToSend;
  data: T;
}

export interface XDRouterLocation {
  pathname: string;
  search: string;
}

class XdPWCommunicator {
  private subscriptions: Record<XDCEventsToReceive, XDCEventHandler[]>;
  private source: string;

  constructor(src: string) {
    this.source = src;
    this.subscriptions = {
      [XDCEventsToReceive.ParentInit]: [],
      [XDCEventsToReceive.ParentInitRoute]: [],
      [XDCEventsToReceive.AuthInfo]: [],
      [XDCEventsToReceive.Logout]: [],
    };
  }

  start() {
    this.startListening();
  }

  startListening() {
    window.addEventListener('message', this.handleEvent.bind(this), false);
  }

  stop() {
    // window.removeEventListener('message', this.handleEvent.bind(this), false);
    // ^ removal is not working - attaching multiple handlers
    Object.keys(this.subscriptions).forEach((key) => {
      this.subscriptions[key] = [];
    });
  }

  handleEvent(event: MessageEvent) {
    if (!event.origin.includes(this.source)) {
      return;
    }
    if (event.data.name && this.subscriptions[event.data.name]) {
      this.subscriptions[event.data.name].forEach((handler) => handler(event.data.data));
    }
  }

  on(eventName: XDCEventsToReceive, handler: XDCEventHandler) {
    if (!this.subscriptions[eventName]) {
      this.subscriptions[eventName] = [];
    }

    this.subscriptions[eventName].push(handler);
  }

  send(event: XDCBaseSendEvent<any>) {
    try {
      if (window.parent && window.parent.postMessage) {
        window.parent.postMessage(event, this.source);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      // don't let it block UI
    }
  }
}

export const xdPWCommunicator = new XdPWCommunicator(xdIframeLink);
