import React, { FC } from 'react';
import { STFlex } from 'design/atoms/Container';
import Norton from './Norton';
import { STIcon, STImageIcon } from 'design/atoms/Icon';
import paymenticons from 'static/images/payment-logos.webp';
import jazzcashicon from 'static/images/jazz-cash.png';
import NIFTePayIcon from 'static/images/nift-epay.png';
import { STLabel, STLink } from 'design/atoms/Text';
import styled from 'styled-components';
import links from './links';

interface SocailLinkProps {
  name: string;
}
const NortonWidgetStyled = styled(STFlex)`
  & a {
    display: none;
  }
`;

const MiddleFooter = ({ path }) => {
  return (
    <STFlex width="100%" bg="white">
      <STFlex
        display={[path === '/' ? 'flex' : 'none', 'flex']}
        maxWidth="1270px"
        width={1}
        margin="auto"
        py={3}
        pr={5}
      >
        <STFlex flexDirection="column" width={['100%', '40%']} justifyContent="center">
          <STFlex alignItems="center" width={1}>
            <NortonWidgetStyled flexDirection="column" width="66px" mr={4}>
              <Norton />
            </NortonWidgetStyled>
            <STFlex flexDirection="column" pl={4}>
              <STLabel variant="greyRegular" fontSize={0}>
                Follow Us
              </STLabel>
            </STFlex>
            <SocialLinks name="Facebook" />
            <SocialLinks name="Twitter" />
            <SocialLinks name="Instagram" />
          </STFlex>
        </STFlex>
        <STFlex flexDirection="column" width={['100%', '60%']} ml={3} justifyContent="center">
          <STFlex justifyContent="flex-end" width={1}>
            <STFlex flexDirection="column" maxWidth="400px">
              <STImageIcon width="100%" height="32px" icon={paymenticons} alt="payment methods" />
            </STFlex>
            <STFlex flexDirection="column" pl={3}>
              <STImageIcon width="65px" height="32px" icon={jazzcashicon} alt="Jazzcash" />
            </STFlex>
            <STFlex flexDirection="column" pl={3}>
              <STImageIcon width="100px" icon={NIFTePayIcon} alt="NIFT ePay" />
            </STFlex>
          </STFlex>
        </STFlex>
      </STFlex>
    </STFlex>
  );
};

export const SocialLinks: React.FC<SocailLinkProps> = ({ name }) => {
  return (
    <STFlex flexDirection="column" pl={3}>
      <STLink as="a" href={links[`${name}`]}>
        <STIcon icon={name} size="sm" color="black" />
      </STLink>
    </STFlex>
  );
};

export default MiddleFooter;
