import fetch from 'isomorphic-fetch';
import { logoutUser } from 'auth';
import { store } from 'store';
import { showToast } from 'shared/Toast/Toast';
import { SW_WHITE_LIST } from 'util/Constants';
import jwtDecode from 'jwt-decode';
import moment from 'moment-timezone';
import SastaEndpoints from 'util/Endpoints';
import { saveUser } from 'modules/Account/accountActions';
import { xdPWCommunicator, XDCEventsToSend } from 'modules/V3App/xd-communicator';

import { getBackendURL, setInLS, stringEncrypt } from 'util/Helpers';

export const API_URL = getBackendURL();
const MOCKS_API_URL = process.env.REACT_APP_BACKEND_URL_MOCKS;

function fetchCall(url, requestParams, disableToast) {
  return fetch(url, requestParams)
    .then((response) => response.json().then((json) => ({ json, response })))
    .then(({ json, response }) => {
      // eslint-disable-next-line no-unused-expressions, eqeqeq
      if (response.status >= 400 || (json.hasOwnProperty('success') && !json.success)) {
        const error = json.message || 'Something went wrong!';
        return Promise.reject({
          message: error,
          status_code: response.status,
          success: json.success,
          ...(json.data && { data: json.data }),
        });
      }
      return { json, response };
    })
    .catch((e) => {
      e.status == 401 && logoutUser();
      if (!disableToast) {
        showToast({
          msg: e.message,
          type: 'error',
        });
      }
      return Promise.reject(e);
    });
}

function checkWhiteList(endpoint) {
  return SW_WHITE_LIST.includes(endpoint);
}

export const setHeaders = () => ({
  'Access-Control-Allow-Origin': '*',
  'content-type': 'application/json; charset=utf-8',
});

function refreshToken(headers, refreshToken) {
  const requestParams = {
    headers,
    method: 'post',
  };

  const payload = {
    refresh: refreshToken,
  };

  requestParams.body = JSON.stringify(payload);

  const url = `${API_URL}/${SastaEndpoints.refreshToken}/`;

  return fetchCall(url, requestParams, true);
}

function checkTokenValidity(headers, userState) {
  return new Promise((resolve) => {
    const decodedToken = jwtDecode(userState.token);
    const currentTimeStamp = Math.round(moment() / 1000);
    if (decodedToken.exp < currentTimeStamp) {
      return (
        refreshToken(headers, userState.refreshToken)
          .then(({ json }) => {
            const newUserState = {
              ...userState,
              token: json.data.token,
              refreshToken: json.data.refresh_token,
            };

            store.dispatch(saveUser(newUserState));
            setInLS('user', stringEncrypt(JSON.stringify(newUserState)));
            resolve(json.data.token);
          })
          //Checking API bad response in fetchCall()
          .catch(() => logoutUser())
      );
    }
    resolve(userState.token);
  });
}

export const offlineResponse = () => {
  const message = 'System is offline. Please check your connection and try again.';
  showToast({ msg: message, type: 'error', toastId: 'offline' });
  return Promise.resolve({ json: { message, success: false } });
};

export const getUserState = () => {
  return store && store.getState().account.user;
};

const getAllHeaders = (headers, state, token) => {
  return {
    ...headers,
    ...(state.xAuthStrategy && { 'X-Auth-Strategy': 'v2' }),
    Authorization: `JWT ${token}`,
  };
};

const getAmp = (array, index, objKeys = [], ObjKeyIndex = null) =>
  index + 1 < array.length || ObjKeyIndex + 1 < objKeys.length ? '&' : '';

export default function callApi({
  endpoint,
  method = 'get',
  body = null,
  queryParams = null,
  id = null,
  disableToast = false,
  isMock = false,
}) {
  let url = `${isMock ? MOCKS_API_URL : API_URL}/${endpoint}/`;
  if (queryParams) {
    url = `${url}?`;
    const keys = Object.keys(queryParams);
    keys.forEach((key, keyIndex) => {
      /**Add this check if value is an array on same key e.g: status["PENDIND", "TICKETISSUED"]
       * then convert it into query params related to same key  */
      if (Array.isArray(queryParams[key])) {
        queryParams[key].map((value, index) => {
          url = `${url}${key}=${value}${getAmp(queryParams[key], index, keys, keyIndex)}`;
        });
      } else {
        url = `${url}${key}=${queryParams[key]}${getAmp(keys, keyIndex)}`;
      }
    });
  } else if (id) {
    url += id;
  }

  // eslint-disable-next-line eqeqeq
  if (method == 'get') {
    // eslint-disable-next-line prefer-template
    url += (url.indexOf('?') >= 0 ? '&' : '?') + '_=' + Date.now();
  }

  const userState = getUserState();
  let headers = setHeaders();

  /* {
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'DELETE, HEAD, GET, OPTIONS, POST, PUT',
    'Access-Control-Allow-Headers': 'Content-Type, Content-Range, Content-Disposition, Content-Description',
    'Access-Control-Max-Age': '1728000'
  } */

  const requestParams = {
    headers,
    method,
  };

  // Handle MS Edge issue (on 'Window': HEAD or GET Requests cannot have a body).
  if (body) {
    requestParams.body = JSON.stringify(body);
  }

  // Specific check added since onLine can be null in testing.
  if (window.navigator && window.navigator.onLine === false && !checkWhiteList(endpoint)) {
    return offlineResponse();
  }

  if (userState && userState.token) {
    return checkTokenValidity(headers, userState).then((token) => {
      headers = getAllHeaders(headers, userState, token);
      const requestParamsWithToken = {
        ...requestParams,
        headers,
      };
      return fetchCall(url, requestParamsWithToken, disableToast);
    });
  } else {
    return fetchCall(url, requestParams, disableToast);
  }
}
