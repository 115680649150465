import * as accountActionTypes from './accountActionTypes';
import { createReducer } from 'util/Helpers';
import { accountInitialState } from './accountInitialState';

const saveUser = (userState = {}, action) => {
  return Object.assign({}, userState, action.payload);
};

const removeUser = (
  // eslint-disable-next-line no-unused-vars
  userState = {},
  action // eslint-disable-line no-unused-vars
) => ({});

const userReducer = createReducer(
  {},
  {
    [accountActionTypes.SAVE_USER]: saveUser,
    [accountActionTypes.REMOVE_USER]: removeUser,
  }
);

const saveUserBookings = (state, action) => {
  return [].concat(state).concat(action.payload);
};

const saveUserPurchases = (state, action) => {
  return [].concat(state).concat(action.payload);
};

const saveUserBookingsByOrderId = (state, action) => {
  return [].concat(action.payload);
};

const removeUserBookings = () => [];

const removeUserPurchases = () => [];

const userBookingsReducer = createReducer(
  {},
  {
    [accountActionTypes.SAVE_USER_BOOKINGS]: saveUserBookings,
    [accountActionTypes.REMOVE_USER_BOOKINGS]: removeUserBookings,
  }
);

const saveBookingByOrderIdReducer = createReducer(
  {},
  {
    [accountActionTypes.SAVE_ORDER_BOOKING]: saveUserBookingsByOrderId,
    [accountActionTypes.REMOVE_ORDER_BOOKING]: removeUserBookings,
  }
);

const userPurchasesReducer = createReducer(
  {},
  {
    [accountActionTypes.SAVE_USER_PURCHASES]: saveUserPurchases,
    [accountActionTypes.REMOVE_USER_PURCHASES]: removeUserPurchases,
  }
);

const AccountReducer = (state = accountInitialState, action) => ({
  // eslint-disable-next-line new-cap
  user: userReducer(state.user, action),
  bookings: userBookingsReducer(state.bookings, action),
  purchases: userPurchasesReducer(state.purchases, action),
  bookingByUserID: saveBookingByOrderIdReducer(state.bookingByUserID, action),
});

export default AccountReducer;
