import moment from 'moment-timezone';

const ERROR_MESSAGE = {
  TOKEN_EXPIRE: 'Your session has expired, please login again.',
  SOMETHING_WRONG: 'Something went wrong.',
  INCORRECT_ORDER_ID: 'Incorrect Email Address or Order ID. Please try again',
  SELECT_PAYMENT_METHOD: 'Please select payment method.',
  CREDIT_CARD_DETAIL: 'Please fill credit card details.',
  VALID_TRIP_DURATION_DATE: 'Departure Date should be less than Arrival date.',
  MAX_VALID_DEPARTURE_DATE: `Departure date should after ${moment()
    .subtract(1, 'day')
    .format('YYYY-MM-DD')} and Arrival date should before ${moment().add(11, 'month').format('YYYY-MM-DD')}`,
  GOOGLE_ANALYTICS_TRACKER: 'Google analytics tracker is not define.',
  MISSING_FIELD: 'This field is required.',
  ALPHA_STRING_ERROR: 'Only letters are allowed.',
  NAME_MIN_LENGTH_ERROR: 'Name should not be less than 2 characters.',
  INVALID_EMAIL: 'This email is invalid.',
  INVALID_ORDER_ID: 'This order id is invalid.',
  INVALID_DAY: 'Day of Month is invalid.',
  INVALID_VOUCHER: 'Promo code is invalid.',
  WRONG_PROMOCODE: 'Promo code application error.',
  INVALID_DATE: 'Date is Invalid.',
  INVALID_PHONE_NUMBER: 'This phone number is invalid.',
  INVALID_CRD_CARD_NUMBER: 'This Card Number is not valid.',
  INVALID_CARD_NUMBER: 'Please input correct value',
  INVALID_INFANT_COUNT: 'Infants count must be equal or less than Adult count.',
  CORRECT_CARD_NUMBER_FIRST: 'Before Sec No, Please give valid Card No.',
  EMAIL_MISMATCH: 'Email does not match.',
  INVALID_NEW_EMAIL: 'Your new email address cannot be the same as your current one. Please try again.',
  SHORT_PASSWORD_ERROR: 'This password is too short. It must contain at least 8 characters.',
  ALPHANUMERIC_PASSWORD_ERROR: 'Password should atleast contain 1 capital letter and 1 digit ',
  FIRST_NAME_PASSWORD_ERROR: 'Password should not contian first name.',
  LAST_NAME_PASSWORD_ERROR: 'Password should not contian last name.',
  EMAIL_PASSWORD_ERROR: 'Password should not contian email address',
  PASSWORD_MISMATCH: 'Passwords do not match. Please try again.',
  ITEM_DISPLAY_ERROR: 'An error occurred while displaying this result.',
  PAGE_DISPLAY_ERROR: 'An error occurred while displaying this page. Please refresh or return to the home page.',
  NO_RESULTS_TITLE: 'No Results Found.',
  NO_WALLET_TRANSACTIONS: 'No Transactions Found In Your Wallet.',
  NO_DEPOSIT_TRANSACTIONS: 'No Deposit Transactions Found In Your Wallet.',
  NO_RESULTS_MSG: 'Please try another date or modify your search.',
  MULTICILTY_NO_RESULTS_TITLE: 'Multi city searches are not currently supported for domestic flights.',
  MULTICILTY_NO_RESULTS_MSG:
    ' Please call us at 021 111 172 782 for assistance with your multi city domestic flight bookings',
  NO_RESULTS_SELECTION: 'Please try changing your flight selection.',
  NO_RESULTS_FILTERED_TITLE: 'No filtered results.',
  NO_RESULTS_FILTERED_MSG: 'Please Select All filters to view results.',
  NO_RESULTS_BOOKING_TITLE: 'No bookings found.',
  NO_RESULTS_PURCHASES_TITLE: 'No purchases found.',
  NO_RESULTS_SALES_TITLE: 'No sales found.',
  NO_RESULTS_TRAVEL_TITLE: 'No orders found.',
  NO_BOOKING_DETAIL_TITLE: 'Booking Details Not Found.',
  NO_BOOKING_DETAIL_MSG: 'Please verify order id.',
  BASKET_ALREADY_PROCESSED: 'Your order has already been processed.',
  SASTA_BUTTON_DEFUALT_CLICK: 'Default SastaBTN onClick triggered, you can override it for custom behavior.',
  INVALID_PASSPORT_NUMBER: 'Passport number cannot exceed 12 characters.',
  CYBER_SRC_PROCESSING: `Credit card details are incorrect.`,
  CYBER_SRC_RESULT: 'Invalid credit card response.',
  FB_LOGIN_ERRORS: {
    APP_INTERNAL_ERR: 'Something went wrong with facebook app. Please try later.',
    AUTH_FAILED: 'Error - Failed to authenticate user with facebook.',
    FB_ERROR: 'Error - Login with facebook.',
    LOGIN_CANCELLED: 'User cancelled login or did not fully authorize or login popup is blocked.',
  },
  ONLINE_TXN_FAILED: 'Transaction failed. Please verify your credentials and try again.',
  EASY_PAISA_JAZZCASH_MOBILE_WALLET_ERROR:
    'Please ensure your mobile account is correctly configured, please contact us if you need additional support.',
  EASY_PAISA_JAZZCASH_OTC_ERROR:
    'Unable to process your payment at the moment. Please contact our call center for further assistance.',
  EASY_PAISA_JAZZCASH_MOBILE_NUMBER_ERROR: 'Please add mobile number.',
  DISABLED_COOKIES: 'Please enable cookies for this website in your browser settings',
  PAYMENT_FAILED:
    'Your transaction has been declined by your bank. Please use a different card or try using any other payment method.',
  INCORRECT_INPUT: 'Email address or Order ID is incorrect.',
  OTP_FAILURE: 'The OTP you entered is incorrect. Please try again.',
  ONEWAY_ITINERARY_UNAVAILABLE:
    'The existing flight combination is invalid, please select another flight combination. We regret the inconvenience!',
  SEND_EMAIL: 'Email could not be sent due to an error',
  INVALID_CNIC: 'CNIC is Invalid.',
  INVALID_ACCOUNT_NUMBER: 'Account number is Invalid.',
  INCORRECT_HBL_DETAILS: 'Incorrect CNIC or Account Number.',
  GREATER_HBL_AMOUNT_BACKEND: 'AMOUNT IS GREATER THAN MAX AMOUNT ',
  GREATER_HBL_AMOUNT: 'Order total is greater than the maximum amount allowed.',
  INSUFFICIENT_AMOUNT_BACKEND: 'INSUFFICIENT FUNDS',
  INSUFFICIENT_AMOUNT: 'Your account has insufficient funds.',
  ACCOUNT_DETAIL: 'Please fill account details.',
  SEARCH_QUERY_EXPIRE: 'Sorry, this search link has expired. Please try again.',
  BOOKING_EXPIRES: 'Sorry, your booking has expired. Please try again.',
  EXCEED_CANCELLATION_AMOUNT: 'Cancellation charges cannot exceed the order total.',
  INCORRECT_TIME: 'Incorrect time.',
  ADDRESS_LENGTH_20: 'Address should be minimum 20 characters.',
  TRAVEL_INSURANCE_SELECTION: 'Note: Please select Yes or No for Travel insurance to continue.',
  REMARKS: 'Please fill remarks field',
  SHORT_OTP_ERROR: 'OTP must contain at least 6 characters.',
  INVALID_IBAN: 'Must be 24 digits valid IBAN',
  AUTH_FAILURE: 'You are not authorized to login, please contact with your administrator to get authorized access!',
};

export default ERROR_MESSAGE;
