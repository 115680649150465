import React from 'react';
import { NavItemsGroupStyled } from './NavItemsGroup.style';
import { StyledSystemProps } from '../../../atoms/Box';

interface NavItemsGroupProps extends StyledSystemProps {
  children: React.ReactNode | React.ReactNode[];
  convertDrawer?: boolean;
}

export const STNavItemsGroup = ({ children, align, ...props }: NavItemsGroupProps) => {
  return (
    <NavItemsGroupStyled align={align ? align : 'left'} {...props}>
      {children}
    </NavItemsGroupStyled>
  );
};
