import {
  Identify,
  track,
  identify,
  setGroup,
  getDeviceId,
  setUserId,
  init as initiate,
} from '@amplitude/analytics-browser';
import { FEATURES, WebsiteVersion } from '../Constants';
import { isFeatureEnabled } from '../Helpers';

export const AmplitudeEvents = {
  // No flight events since all on frontend-core (V3)
  BUS_SEARCH: 'BUS_SEARCH',
  BUS_SEARCH_SELECTION: 'BUS_SEARCH_SELECTION',
  BUS_BOOKING_INITIATED: 'BUS_BOOKING_INITIATED',
  BUS_BOOKING_COMPLETED: 'BUS_BOOKING_COMPLETED',
  HOTEL_SEARCH: 'HOTEL_SEARCH',
  HOTEL_CHANGE_SEARCH: 'HOTEL_CHANGE_SEARCH',
  HOTEL_SELECTED: 'HOTEL_SELECTED',
  HOTEL_BOOKING_INITIATED: 'HOTEL_BOOKING_INITIATED',
  HOTEL_BOOKING_COMPLETED: 'HOTEL_BOOKING_COMPLETED',
  USER_LOGIN: 'USER_LOGIN',
};

// these properties are sent with every event for identification of the platform.
const AmplitudeBasePayload = {
  source: 'web',
  sourceVersion: WebsiteVersion.V2,
};

class AmplitudeService {
  removeEmpty(data) {
    const clonedData = { ...data };
    for (const key in clonedData) {
      if (!key) {
        delete clonedData[key];
      }
    }
    return clonedData;
  }

  triggerStandardEvent(eventObject) {
    // trigger data if feature flag is enabled
    if (isFeatureEnabled(FEATURES.AMPLITUDE_ENABLED)) {
      const eventName = eventObject.eventName;
      delete eventObject.eventName;
      const data = {
        ...AmplitudeBasePayload,
        ...eventObject,
      };
      track(eventName, {
        ...this.removeEmpty(data),
      });
    }
  }

  identify(userInfo) {
    if (!userInfo) {
      return;
    }

    setGroup('role', userInfo.role);

    if (userInfo.user) {
      const { user } = userInfo;
      setUserId(`0000${user.id}`);
      const identifyObj = new Identify();
      identifyObj.setOnce('isSuperUser', user.isSuperuser);
      identifyObj.set('isEmailVerified', user.isEmailVerified);
      identify(identifyObj);
    }
  }

  getDeviceId() {
    return getDeviceId();
  }

  init() {
    const apiKey = process.env.REACT_APP_AMPLITUDE_API_KEY;
    // throw error if feature flag is enabled and API KEY not found in env file
    if (!apiKey) {
      throw new Error('Amplitude API_KEY not found');
    }
    initiate(apiKey);
  }
}

export const amplitudeService = new AmplitudeService();
