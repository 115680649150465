import { ADD_HOTEL_INFO, ADD_ROOMS } from './offerDetailsActionTypes';
import { INITIAL_STATE } from './offerDetailsInitialState';

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case ADD_HOTEL_INFO:
      return {
        ...state,
        ...action.payload,
      };

    case ADD_ROOMS:
      return {
        ...state,
        rooms: action.payload.rooms,
        propKey: action.payload.propKey,
        provider: action.payload.provider,
        price: action.payload.price,
      };
    default:
      return {
        ...state,
      };
  }
};
