import React from 'react';
import { STIcon } from '../../../atoms/Icon';
import { Box } from '../../../atoms/Box';

interface NavbarTogglerProps {
  icon?: any;
  onClick: Function;
}

export const STNavbarToggler = ({ icon, onClick }: NavbarTogglerProps) => {
  return (
    <Box display={['flex', 'none']} flexDirection="column" justifyContent="center">
      <STIcon height="24px" icon={icon ? icon : 'Menu'} color="primary" onClick={onClick} />
    </Box>
  );
};
