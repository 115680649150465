import React from 'react';
import './PageLoader.css';
import PropTypes from 'prop-types';

const PageLoader = ({ options, title }) => {
  const defaultClass = 'pg_load_animation';
  const styleClasses = options && options.styleClasses ? `${defaultClass} ${options.styleClasses}` : defaultClass;

  return (
    <div className={styleClasses}>
      {title && <h4>{title}</h4>}
      {options.msg && <h3>{options.msg}</h3>}
      <div className="sasta-animation-loader">
        <div />
        <div />
        <div />
      </div>
      <p>Please wait it should only take a moment</p>
    </div>
  );
};

PageLoader.propTypes = {
  options: PropTypes.object,
  title: PropTypes.string,
};

export default PageLoader;
