import React from 'react';
import styled, { css } from 'styled-components';
import { ToastContainer, ToastContainerProps } from 'react-toastify';
import { STIcon } from 'design/atoms/Icon';
import { Box } from 'design/atoms/Box';
import 'react-toastify/dist/ReactToastify.css';

const CloseButton = () => (
  <Box className="Toastify__close-button">
    <STIcon icon="X" size="xs" />
  </Box>
);

const Toast = ({ icon, message }) => (
  <Box display="flex" alignItems="center">
    <div className="Toastify__icon">{icon}</div>
    <div className="Toastify__text">{message}</div>
  </Box>
);

const WrappedToastContainer = ({ className, ...rest }: ToastContainerProps & { className?: string }) => (
  <div className={className}>
    <ToastContainer autoClose={15000} draggable={false} closeOnClick closeButton={<CloseButton />} {...rest} />
  </div>
);

export const STSuccessToast = ({ message }) => (
  <Toast icon={<STIcon icon="CheckCircle" size="md" />} message={message} />
);

export const STErrorToast = ({ message }) => <Toast icon={<STIcon icon="XCircle" size="md" />} message={message} />;

export const STWarningToast = ({ message }) => (
  <Toast icon={<STIcon icon="AlertTriangle" size="md" />} message={message} />
);

export const STToastContainer = styled(WrappedToastContainer).attrs({
  // custom props
})(
  ({ theme }) => css`
    .Toastify__toast-container {
      margin-top: 35px;
    }

    .Toastify__toast {
      padding: ${theme.space[2]}px;
      color: ${theme.colors.white};
      font-size: ${theme.fontSizes[2]};
      font-family: ${theme.fonts.primary};
      border-radius: ${theme.radii[1]}px;
      font-weight: bold;
      max-width: 290px;
      margin: 20px 0;
      min-height: 50px;
    }
    ${theme.mediaQueries.mobile} {
      .Toastify__toast-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
      }
    }
    .Toastify__toast--error {
      background: ${theme.colors.error};
    }
    .Toastify__toast--warning {
      background: ${theme.colors.warning};
      color: ${theme.colors.black};
    }
    .Toastify__toast--warning > .Toastify__close-button {
      color: ${theme.colors.black};
    }
    .Toastify__toast--success {
      background: ${theme.colors.success};
    }
    .Toastify__toast-body {
    }
    .Toastify__icon {
      display: inline-block;
      margin-right: 12px;
      height: 32px;
    }
    .Toastify__text {
      display: inline-block;
    }
    .Toastify__close-button {
      float: right;
      margin-top: 1.5%;
    }
  `
);
