/* eslint-disable no-underscore-dangle */
/* eslint-disable no-undef */
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { addBreadcrumb } from '@sentry/browser';

import rootReducer from './reducers';
import reduxFormHotFix from 'util/formUtils/reduxFormHotFix';

// eslint-disable-next-line import/no-mutable-exports
export let store = null;

// Sentry middleware for redux action logging
const sentryReporter = () => (next) => (action) => {
  // action payload object does not have a unique key is all payloads thats why extracting payload key
  const actionKeys = Object.keys(action);
  const payloadKey = actionKeys.filter((key) => key !== 'type');

  addBreadcrumb({
    message: action.type,
    category: 'redux action',
    level: 'info',
    data: {
      payload: JSON.stringify(action[payloadKey], null, 4), // just for readable format in sentry
    },
  });
  return next(action);
};

export function configureStore(initialState = {}) {
  const { REACT_APP_DEPLOYMENT_ENVIRONMENT: TARGET_ENV } = process.env;

  // Middleware and store enhancers
  const middleware = [thunk, reduxFormHotFix, sentryReporter];

  let enhancer = null;
  if (TARGET_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    enhancer = composeEnhancers(
      applyMiddleware(...middleware)
      // other store enhancers if any
    );
  } else {
    const eas = [applyMiddleware(...middleware)];
    enhancer = compose(...eas);
  }
  store = createStore(rootReducer, initialState, enhancer);

  return store;
}
