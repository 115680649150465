/**
 * This file holds types of our Search state, actions, payloads as well as any other types related to this module.
 */
import { CabinClass, RouteType, TravelerCount, OriginDestinationType } from '../../../standardTypes';
import { AnyAction } from 'redux';

export interface SearchState {
  query: Query;
  datePickerVisibility?: boolean;
}
//TODO: flightSearchQuery rename

export const SearchActionTypes = {
  UPDATE_QUERY: 'UPDATE_QUERY',
  UPDATE_QUERY_PROPERTY: 'UPDATE_QUERY_PROPERTY',
  UPDATE_QUERY_LEG_PROPERTY: 'UPDATE_QUERY_LEG_PROPERTY',
  ADD_QUERY_LEG: 'ADD_QUERY_LEG',
  REMOVE_QUERY_LEG: 'REMOVE_QUERY_LEG',
  TOGGLE_DATE_PICKER: 'TOGGLE_DATE_PICKER',
};

export interface updateQueryActionType extends AnyAction {
  type: typeof SearchActionTypes.UPDATE_QUERY;
  query: Query;
}

export interface toggleDatePickerActionType extends AnyAction {
  type: typeof SearchActionTypes.TOGGLE_DATE_PICKER;
  flag: boolean;
}

/**
 * Sub types of Search Store Types below
 */
export interface Query {
  cabinClass: CabinClass;
  flexibility: null;
  routeType: RouteType;
  legs: Array<QueryLeg>;
  nonStopFlight: boolean;
  travelerCount: TravelerCount;
}
//TODO: flightSearchQueryLegs Rename
export interface QueryLeg {
  departureDate: string;
  destination: OriginDestinationType | null;
  origin: OriginDestinationType | null;
}

export type SearchActionCreatorsTypes = updateQueryActionType | toggleDatePickerActionType;
