/* eslint-disable import/prefer-default-export */
export const adminInitialState = {
  // TODO why on earth is user in admin state? This should be removed
  user: {},
  adminReports: null,
  // TODO remove this
  adminReportOriginal: null,
  // TODO rename to active report details
  adminReportDetails: {},
  adminHotelReportDetails: {},
  allAgents: [],
  fetchCount: 0,
  paymentProviders: {},
};
