/* eslint-disable import/prefer-default-export */
export const orderInitialState = {
  paymentProviders: null,
  payment: {},
  bookings: [],
  orderDetails: {},
  emi: {
    selectedPlan: null,
  },
};
