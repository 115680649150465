import { TOGGLE_V3, UPDATE_MOBILE_CONFIG, UPDATE_WEB_CONFIG } from './appActionTypes';
import { flightAppInitialState } from './appInitialState';
import { createReducer } from 'util/Helpers';

const updateConfig = (state = {}, action) => ({
  ...state,
  [action.config.key]: action.config.value,
});

const mobReducer = createReducer(
  {},
  {
    [UPDATE_MOBILE_CONFIG]: updateConfig,
  }
);

const webReducer = createReducer(
  {},
  {
    [UPDATE_WEB_CONFIG]: updateConfig,
  }
);

const v3Reducer = createReducer(
  {},
  {
    [TOGGLE_V3]: updateConfig,
  }
);

const FlightAppReducer = (state = flightAppInitialState.FLT, action) => ({
  mobileConfig: mobReducer(state.mobileConfig, action),
  webConfig: webReducer(state.webConfig, action),
  v3: v3Reducer(state.v3, action),
});

export default FlightAppReducer;
