import React from 'react';
import classNames from 'classnames';
import { Field } from 'redux-form';
import CryptoAES from 'crypto-js/aes';
import humps from 'humps';
import qs from 'qs';
import Utf8 from 'crypto-js/enc-utf8';
import TimeZonedMoment from 'moment-timezone';
import moment from 'moment/moment';
import findIndex from 'lodash.findindex';
import orderBy from 'lodash.orderby';
import cloneDeep from 'lodash.clonedeep';
import caseConverter from 'change-case-object';
import camelCase from 'lodash.camelcase';
import ERROR_MESSAGE from './ErrorMsgs';
import NumberFormat from 'react-number-format';
import { HOTELS_PREFIX, BOOKING_TYPE } from 'util/Constants';
import { parsePhoneNumberFromString } from 'react-phone-number-input/libphonenumber';
import BookingExpiry from 'modules/Account/MyBookings/BookingsBody/BookingListItem/BookingExpiry/BookingExpiry';
import { showToast } from '../shared/Toast/Toast';
import paypro from 'static/images/Bank Logos/paypro.png';
import paypak from 'static/images/Bank Logos/paypak-logo.png';
import unionpay from 'static/images/Bank Logos/unionpay.png';
import queryString from 'query-string';
import { format } from 'date-fns';
import { XDCEventsToSend, xdPWCommunicator } from 'modules/V3App/xd-communicator';

import { ReactComponent as airplaneIcon } from 'static/images/airplane.svg';
import { ReactComponent as flagIcon } from 'static/images/Flag.svg';

import {
  APP_TIMEZONE,
  APP_DATEFORMAT,
  APP_TIMEFORMAT,
  APP_DATETIMEFORMAT,
  DATE_FRMT_FOR_URL,
  ROUTE_PREFIX,
  ACCOUNT_ROUTE_PREFIX,
  CYBERSRC_MERCHANT_ID,
  TICKET_TYPES,
  TITLES,
  TRIP_TYPES,
  TRAVELER_TYPES,
  FLIGHT_TYPE,
  PRINTABLE_TICKET_KEYS,
  TRANSACTION_STATUS,
  ONLINE_PAYMENT_TYPE,
  PAYMENT_METHODS,
  ORDER_STATUS,
  READABLE_ORDER_STATUS,
  PAYMENT_STATUS,
  WEB_ENGAGE_DATA,
  CURRENCY_PREFIX,
  BIN_NUMBERS,
  FEE_TYPE,
  TIME_LIMITED_PAYMENT_METHODS,
} from './Constants';
import isEmpty from 'lodash.isempty';
import { OrderType, RouteType } from 'standardTypes';

export function scrollToTop() {
  setTimeout(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }, 100);
}

export const purchaseSuccessfulStatus = (status, type) => {
  if (status === ORDER_STATUS.TICKET_ISSUED) {
    return type === OrderType.HOTEL ? READABLE_ORDER_STATUS.VOUCHERISSUED : READABLE_ORDER_STATUS.TICKETISSUED;
  } else return READABLE_ORDER_STATUS.CANCELLED;
};

export const redirectToHotelDetail = (hotelId, query) => {
  window.open(`${HOTELS_PREFIX}property/${hotelId}?${qs.stringify(query)}`, '_blank');
};

export const redirectToPage = (push, alertMsg, redirectTo, params = null) => {
  if (alertMsg) {
    showToast({ msg: alertMsg, type: 'error' });
  }

  if (params) {
    push({ pathname: redirectTo, search: params });
  } else {
    push(redirectTo);
  }
  scrollToTop();
};

export const getRedirectPath = (user, { pathname, search }) => {
  let redirectTo = pathname;
  if (user && !user.user && redirectTo !== `${ROUTE_PREFIX}/login`) {
    if (pathname && search && pathname !== ROUTE_PREFIX) {
      redirectTo += search; // redirect back to the page where user was viewing
    } else {
      redirectTo = `${ACCOUNT_ROUTE_PREFIX}/edit`; // redirect to user account for login from landing page
    }
  }
  return redirectTo;
};

export function getPercentageOf(value, percentage) {
  return (percentage / 100) * value;
}

export function getTitleOptions(passengerType) {
  const titlesCopy = Object.assign({}, TITLES);
  const options = [{ value: null, label: '-' }];

  if (passengerType) {
    if (passengerType === TRAVELER_TYPES.ADULTS.label) {
      delete titlesCopy.MASTER;
    }
    if (passengerType === TRAVELER_TYPES.CHILDREN.label || passengerType === TRAVELER_TYPES.INFANTS.label) {
      delete titlesCopy.MRS;
      delete titlesCopy.MR;
    }
  }

  Object.keys(titlesCopy).forEach((title) => {
    options.push({ value: title, label: TITLES[title] });
  });

  return options;
}

export function getTitle(title = '-') {
  const OPTIONS = getTitleOptions();
  const i = findIndex(OPTIONS, { value: title });
  return i >= 0 ? OPTIONS[i] : OPTIONS[0];
}

export const scriptIntegration = (src) => {
  const script = document.createElement('script');
  document.body.appendChild(script);
  script.defer = true;
  script.src = src;
};

// localStorage is in window object, it will give error in server side rendering.
// Access localStorage methods in componentDidMount() instead of constructor();

// Put the object into storage
export const setInLS = (name, value) => checkIfCookiesAreEnabled() && localStorage && localStorage.setItem(name, value);

// Remove the object into storage
export const removeInLS = (name) => checkIfCookiesAreEnabled() && localStorage && localStorage.removeItem(name);

// Get the object from storage
export const getFromLS = (name) => checkIfCookiesAreEnabled() && localStorage && localStorage.getItem(name);

export const getBackendURL = () => process.env.REACT_APP_BACKEND_URL || process.env.STORYBOOK_REACT_APP_BACKEND_URL;

export const getCyberSourceScriptURL = (fingerPrintId) => {
  return `https://h.online-metrix.net/fp/tags.js?org_id=${process.env.REACT_APP_CYBERSRC_ORG_ID}&session_id=${CYBERSRC_MERCHANT_ID}${fingerPrintId}`;
};

export const getSocialMediaCredentials = (type = 'fb') => {
  if (type === 'fb') {
    return process.env.REACT_APP_FB_APP_ID;
  }

  return process.env.REACT_APP_GOOGLE_CLIENT_ID;
};

export const getRecaptchaSiteKey = () => process.env.REACT_APP_RECAPTCHA_SITEKEY;

export function getDateValues(paramInitial, end, firstValue) {
  let initial = paramInitial;
  const arr = [];
  if (firstValue) {
    arr.push({ label: firstValue, value: null });
  }
  // eslint-disable-next-line no-plusplus
  for (; initial < end; initial++) {
    if (initial < 10) {
      // prefix values that are < 10 with a preceding 0
      initial = `0${initial}`;
    }
    arr.push({ label: initial, value: initial });
  }
  return arr;
}

export function getFormattedDateTime(
  dateTime,
  dateFormat = APP_DATEFORMAT,
  timeFormat = APP_TIMEFORMAT,
  dateTimeFormat = APP_DATETIMEFORMAT,
  timezone = APP_TIMEZONE
) {
  if (dateTime) {
    return {
      date: moment.utc(dateTime).format(dateFormat),
      time: moment.utc(dateTime).format(timeFormat),
      dateTime: moment.utc(dateTime).format(dateTimeFormat),
      timeZone: TimeZonedMoment.tz(dateTime, timezone).format('Z'),
    };
  }
  return null;
}

export function getDateFromStr(dob = null, objectType) {
  // If objectType passed to this function is 'regular', it will return normal javascript object
  // otherwise it will return javasript date object
  if (dob) {
    const d = dob.split('-');

    if (objectType === 'regular') {
      return {
        day: d[2],
        month: d[1],
        year: d[0],
      };
    }
    return new Date(parseInt(d[0], 10), parseInt(d[1] - 1, 10), parseInt(d[2], 10));
  }
  return null;
}

export const getExpiryDateFromStr = (maskedExpDate) => {
  return {
    month: parseInt(maskedExpDate.substr(0, 2), 10),
    year: parseInt(maskedExpDate.substr(2, 4), 10),
  };
};

export function getDateFromDateObject(dob) {
  /*
   returns JS Date object from a regular JavaScript object
  */
  if (dob.year || dob.month || dob.day) {
    return new Date(dob.year, dob.month - 1, dob.day);
  }
  return null;
}

export function getDateObjectFromDate(dateObject) {
  const date = { day: null, month: null, year: null };

  if (!dateObject) return date;

  if (dateObject) {
    const [year, month, day] = moment(dateObject).format('YYYY-MM-DD').split('-');
    Object.assign(date, { day, month, year });
  }

  return date;
}

export function getTrimmedText(name = '', length = 15, suffix = '...') {
  if (name && name.length > length) {
    return name.substr(0, length) + suffix;
  }
  return name;
}

// String encryption
export const stringEncrypt = (string) => CryptoAES.encrypt(string, process.env.REACT_APP_APP_SALT).toString();

// String decryption
export const stringDecrypt = (ciphertext) => {
  let value = null;
  try {
    /* sanity check: if the decryption of ciphertext fails due to difference
        in APP_SALT used for decryption VS. APP_SALT used for encryption */
    value = CryptoAES.decrypt(ciphertext, process.env.REACT_APP_APP_SALT).toString(Utf8);
  } catch (error) {
    // eslint-disable-next-line no-console
  }
  return value;
};

export function createReducer(initialState, handlers) {
  return function reducer(state = initialState, action) {
    // eslint-disable-next-line no-prototype-builtins
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action);
    }
    return state;
  };
}

export function flightValidateSearchAPIResponseData(data = null) {
  let isValid = false;
  if (!data) {
    return isValid;
  }
  const dataSize = Object.keys(data).length;
  if (data && dataSize > 0) {
    if (dataSize === 1 && data.web_reference) {
      // if only web_refernce found in data, then just update web_refernce & not the data
      isValid = false;
    } else if (dataSize > 1) {
      // found some data as well, so trigger update
      isValid = true;
    }
  }
  return isValid;
}

export function getOppositeTicketType(ticketType, isList = false) {
  const oppTicketType = ticketType === TICKET_TYPES.OUTBOUND ? TICKET_TYPES.INBOUND : TICKET_TYPES.OUTBOUND;
  return `${oppTicketType}${isList === true ? 's' : ''}`;
}

export const getJourneyDuration = (departure, arrival, departTimeZone, arrivalTimeZone, getMinutes = true) => {
  const utcDeparture = TimeZonedMoment.tz(departure, departTimeZone).utc();
  const utcArrival = TimeZonedMoment.tz(arrival, arrivalTimeZone).utc();

  // multiply by minus to handle if difference is in negative value
  const difference = Math.abs(moment(utcArrival).diff(utcDeparture, 'minutes'));
  if (getMinutes) {
    return difference;
  }

  const hours = Math.floor(difference / 60);
  const minutes = difference - hours * 60;

  return `${hours} hr ${minutes} min`;
};

export const getDurationText = (duration, forAnalytics = false) => {
  const hours = Math.floor(duration / 60);
  const minutes = duration - hours * 60;
  if (forAnalytics) {
    return `${hours > 9 ? hours : `0${hours}`}:${minutes > 9 ? minutes : `0${minutes}`}`;
  }
  return `${hours} hr ${minutes} min`;
};

export function getStopDetails(stops, code) {
  return (
    (stops && stops[code]) || {
      city: '',
      iata_code: code,
    }
  );
}

export function getTimeZoneDetails(stops, code) {
  const stop = stops[code];
  return {
    timeZone: stop.a_timezone,
    tZRegion: stop.tz_db_timezone,
  };
}

export function getDateWithIncrement(days) {
  const result = new Date();
  result.setDate(result.getDate() + days);

  const month = result.getMonth() < 10 ? `0${result.getMonth()}` : result.getMonth();
  const date = result.getDate() < 10 ? `0${result.getDate()}` : result.getDate();
  return `${result.getFullYear()}-${month}-${date}`;
}

export function getTotalTraveler(travelerCount) {
  return (
    parseInt(travelerCount[TRAVELER_TYPES.ADULTS.queryPropertyName]) +
    parseInt(travelerCount[TRAVELER_TYPES.CHILDREN.queryPropertyName]) +
    parseInt(travelerCount[TRAVELER_TYPES.INFANTS.queryPropertyName])
  );
}

export function getTotalTax([outbound = null, inbound = null]) {
  if (!inbound) return outbound.price.totalTax;

  return outbound.price.totalTax + inbound.price.totalTax;
}

export function getPassengerName(passenger) {
  const { title, firstName, lastName } = passenger;
  return `${TITLES[title.toUpperCase()]}. ${firstName} ${lastName}`;
}

export function getCabinClassDetails(value, cabinClasses) {
  return cabinClasses ? cabinClasses[value].name : null;
}

export const getAirlineDetails = (code, airlines) => {
  return airlines[code];
};

export const getAppWrapperClasses = (mbConfg, wbConfg, pathname) => {
  /*
    Returns a string of all classes for which a flag is true
    in either mobileConfig or webConfig in the redux-store
  */
  const mobileConfig = caseConverter.snakeCase(mbConfg);
  const webConfig = caseConverter.snakeCase(wbConfg);

  // append current path for mobile-specific classes on body
  let className = `body path-${pathname.substr(pathname.lastIndexOf('/') + 1, pathname.length)}`;

  Object.keys(webConfig).forEach((key) => {
    if (webConfig[key]) {
      className = `${className} web_${key}`;
    }
  });

  Object.keys(mobileConfig).forEach((key) => {
    if (mobileConfig[key]) {
      className = `${className} mob_${key}`;
    }
  });

  return className;
};

export const printTicket = () => {
  /*
    This function prints the ticket selected in my bookings section.
    It gets the content of the booking of the parent div to be printed by id
    and then assigns it to iframe that was injected in PrintableTicket.
  */

  import('../modules/Account/BookingDetails/PrintableTicket/printableTicketStyle').then((response) => {
    /* import the styling for printable ticket PDF */
    const styleCss = response.default;
    const content = document.getElementById(PRINTABLE_TICKET_KEYS.CONTENT);
    const pri = document.getElementById(PRINTABLE_TICKET_KEYS.IFRAME).contentWindow;
    pri.setAttribute('title', 'Printable Ticket');
    pri.document.open();
    pri.document.write(styleCss);
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  });
};

/**
 * account helper functions: START
 */
// Format amount
export const numberWithCommas = (amount) => {
  return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getOrderSummary = (bookings) => {
  let bookingSummary;
  if (bookings.length) {
    bookings.map((booking) => {
      const legsTotalLength = booking.legs[0].segments.length - 1;

      bookingSummary = `${booking.route_type}
  ${booking.cabin_class}
  ${booking.legs[0].segments[0].flight_number[0]} ${booking.legs[0].segments[0].origin.iata_code}-${booking.legs[0].segments[legsTotalLength].destination.iata_code}`;
    });
  } else {
    bookingSummary = 'Summary not found';
  }
  return bookingSummary;
};

export const getOrderTotalTransactions = (transactions) => {
  let totalTransactions = 0;
  if (transactions.length) {
    transactions.map((transaction) => {
      if (
        transaction.status === TRANSACTION_STATUS.SUCCESS &&
        transaction.method.code === ONLINE_PAYMENT_TYPE.CYBERSOURCE
      ) {
        totalTransactions += transaction.amount;
      }
    });
  }
  return round(totalTransactions);
};

// Capitalize First Word of String
export const capitalizeFirstWord = (value) => {
  return value ? value.charAt(0).toUpperCase() + value.substr(1).toLowerCase() : '';
};
// Capitalize every word in a string, split the string through space and join it back.
export const capitalizeEveryWord = (value) => {
  const ValueArray = value.split(' ');
  const capitalizeWords = ValueArray.map((val) => capitalizeFirstWord(val));
  return capitalizeWords.join(' ');
};

export const getTravelerTotalCount = ({ numAdult = 0, numChild = 0, numInfant = 0 }) => {
  return parseInt(numAdult) + parseInt(numChild) + parseInt(numInfant);
};

export const getRefundableTicket = (SelectedResults) => {
  return SelectedResults.filter((result) => result.isRefundable === true);
};

export const isSpecificRoute = (path, routePrefix) => path.includes(routePrefix);

export const formatPrice = (price) => parseFloat(price.replace(/[^0-9.]+/g, ''));

// Purpose of writting this function is that to avoid array values not to convert in camel case.
export const camelizeKeys = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map((v) => camelizeKeys(v));
  } else if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: camelizeKeys(obj[key]),
      }),
      {}
    );
  }
  return obj;
};
function dateValidationToast(validTripDates, validTripDurationDates) {
  if (!validTripDates) {
    showToast({ msg: ERROR_MESSAGE.MAX_VALID_DEPARTURE_DATE, type: 'error' });
  } else if (!validTripDurationDates) {
    showToast({ msg: ERROR_MESSAGE.VALID_TRIP_DURATION_DATE, type: 'error' });
  }
}

export const validateDatesInQueryParams = (queryParams) => {
  const validTripDateOneway =
    moment(queryParams.departureDate) >= moment().startOf('day') &&
    moment(queryParams.departureDate) < moment().add(11, 'month');
  if (queryParams.leg_type === TRIP_TYPES.ONEWAY) {
    dateValidationToast(validTripDateOneway, true);
    return validTripDateOneway;
  }

  const validTripDatesReturn = validTripDateOneway && moment(queryParams.arrivalDate) < moment().add(11, 'month');
  const validTripDurationDates =
    moment(queryParams.departureDate) <= moment(queryParams.arrivalDate) &&
    moment(queryParams.arrivalDate) < moment().add(11, 'month');
  dateValidationToast(validTripDatesReturn, validTripDurationDates);

  return validTripDatesReturn && validTripDurationDates;
};

export const checkIfCookiesAreEnabled = () => {
  let areCookiesEnabled = navigator && navigator.cookieEnabled;
  if (navigator && navigator.cookieEnabled == 'undefined' && !areCookiesEnabled) {
    document.cookie = 'testCookie';
    areCookiesEnabled = document.cookie.indexOf('testCookie') == -1 ? false : true;
  }
  return areCookiesEnabled;
};

/**
 * New Functions Going Forward after multi-city
 */

export const formatDateTime = (
  dateTime,
  dateFormat = APP_DATEFORMAT,
  timeFormat = APP_TIMEFORMAT,
  dateTimeFormat = APP_DATETIMEFORMAT,
  timezone = APP_TIMEZONE
) => {
  return {
    date: TimeZonedMoment.tz(dateTime, timezone).format(dateFormat),
    time: TimeZonedMoment.tz(dateTime, timezone).format(timeFormat),
    dateTime: TimeZonedMoment.tz(dateTime, timezone).format(dateTimeFormat),
  };
};

export const formatDateForSelector = (dateTime, dateFormat = APP_DATEFORMAT) => {
  return {
    date: TimeZonedMoment(dateTime).format(dateFormat),
  };
};

export const formatDateForURL = (dateString) => {
  return dateString && moment(dateString).format(DATE_FRMT_FOR_URL);
};

export const getTotalDuration = (segments) => {
  const { departureDatetime, origin } = segments[0];
  const { arrivalDatetime, destination } = segments[segments.length - 1];

  const startTime = moment.tz(departureDatetime, origin.timeZone).utc();
  const endTime = moment.tz(arrivalDatetime, destination.timeZone).utc();

  return endTime.diff(startTime, 'minutes');
};

export const legInfo = (leg) => {
  const startingSegment = leg.segments[0];

  const flightDetails = {
    departure: {
      ...startingSegment.origin,
      timeStamp: startingSegment.departureDatetime,
    },
    arrival: {
      ...startingSegment.destination,
      timeStamp: startingSegment.arrivalDatetime,
    },
    airlinesInfo: {
      marketingAirline: {
        code: startingSegment.marketingAirline.code,
        name: startingSegment.marketingAirline.name,
      },
    },
  };

  if (leg.segments.length > 1) {
    const lastSegment = leg.segments[leg.segments.length - 1];
    flightDetails.arrival = {
      ...lastSegment.destination,
      timeStamp: lastSegment.arrivalDatetime,
    };

    flightDetails.airlinesInfo = {
      marketingAirline: {
        code: lastSegment.marketingAirline.code,
        name: lastSegment.marketingAirline.name,
      },
    };
  }

  return flightDetails;
};

export const eliminateCityObjects = (query) => {
  // Check on origin and destination added due to breaking case that's not reproduced easily.
  // Our initial state has origin and destination with null values so(has date value to show on fields)
  // adding length check on legs will not ensure safe code.
  if (!isEmpty(query) && !isEmpty(query.legs)) {
    return query.legs.map((leg) => {
      return {
        origin: leg.origin && leg.origin.iataCode,
        destination: leg.destination && leg.destination.iataCode,
        departureDate: formatDateForURL(leg.departureDate),
      };
    });
  } else throw new Error(`There is an issue with the query: ${query}`);
};

export const formatQueryInSnakeCaseWithoutLegObjects = (query) => {
  const formattedQueryParams = {
    ...query,
    legs: eliminateCityObjects(query),
  };
  return humps.decamelizeKeys(formattedQueryParams);
};

export const getMinPnrExpiry = (bookings) => {
  return orderBy(bookings, 'pnrExpiryTimeout')[0].pnrExpiryTimeout;
};

export const makeEEPaymentData = (bookingInfo, isInternationalRoute) => {
  const { orderId, bookings } = bookingInfo;
  return {
    id: orderId,
    affiliation: 'sastaticket.pk',
    revenue: bookings.map((booking) => booking.price.sellingFare).reduce((tot, num) => tot + num),
    category: `Flights - ${isInternationalRoute ? FLIGHT_TYPE.INTERNATIONAL : FLIGHT_TYPE.DOMESTIC}`,
    tax: bookings.map((booking) => booking.price.tax).reduce((tot, num) => tot + num),
    quantity: 1,
  };
};

export const getIncrementedDate = (providedDate, increment, period = 'days') => {
  return moment(providedDate).add(increment, period);
};

export const generateSimilarityHash = (leg, isUniqueByRBD = true) => {
  //isUniqueByRBD will be used to get fare options grouped under one flight
  return leg.segments
    .map(
      (segment) =>
        `${segment.departureDatetime}00${segment.arrivalDatetime}00${segment.flightNumber[0]}00${
          isUniqueByRBD ? segment.rbd : ''
        }`
    )
    .join('');
};

const changeSegmentTimeFormat = (segment) => {
  return {
    ...segment,
    arrivalDatetime: segment.arrivalDatetime.replace(/\+\d{2}:\d{2}/, ''),
    departureDatetime: segment.departureDatetime.replace(/\+\d{2}:\d{2}/, ''),
  };
};

/**
 * Purpose of this method is to add the origin and destination properties on the leg level
 * so they dont need to be computed again and again, this can be done for other props too
 */
export function formatLegs(data) {
  const formattedFlights = data.flights.map((flight) => {
    const formattedLegs = flight.legs.map((leg) => {
      leg.origin = leg.segments[0].origin.iataCode;
      leg.destination = leg.segments[leg.segments.length - 1].destination.iataCode;
      leg.hash = generateSimilarityHash(leg);
      leg.hashForRBD = generateSimilarityHash(leg, false);
      leg.segments = leg.segments.map(changeSegmentTimeFormat);
      leg.itenaryId = flight.id;
      leg.dayDifference = getWeekDaysDifference(
        leg.segments[0].departureDatetime,
        leg.segments[leg.segments.length - 1].arrivalDatetime
      );
      if (flight.legs.length >= 2) {
        flight.price.comboPrice = flight.price.sellingFare;
        flight.price.isComboPrice = true;
      } else {
        // In oneway, the third condition will fail so there will be no combo flights
        const comboFlights = data.flights.filter((flightItem) => {
          return (
            data.searchQuery.legs.length > 1 &&
            flightItem.id !== flight.id &&
            flightItem.legs.length === 1 &&
            flightItem.legs[0].segments[0].origin.iataCode === data.searchQuery.legs[1].origin.iataCode
          );
        });
        // If combo exists, sum it with the original price and create new property
        if (comboFlights.length) {
          flight.price.comboPrice =
            flight.price.sellingFare + Math.min(...comboFlights.map((flightItem) => flightItem.price.sellingFare));
          flight.price.isComboPrice = false;
          flight.hasIndividualReturnPair = true;
        }
        // If no combo, mostly in oneway, put original price into comboPrice prop
        else {
          flight.price.comboPrice = flight.price.sellingFare;
          flight.price.isComboPrice = false;
          // if there's no return pair, add this property to hide resutls of first leg
          // when no return flight for pairing isn't available
          flight.hasIndividualReturnPair = false;
        }
      }
      return leg;
    });
    return {
      ...flight,
      legs: formattedLegs,
    };
  });
  return {
    ...data,
    flights: formattedFlights,
  };
}

export const sortByPrice = (data) => {
  const sorted = {
    ...data,
    flights: orderBy(data.flights, (ticket) => ticket.price.sellingFare, 'asc'),
  };
  return sorted;
};

export const camelizeObject = (obj) => {
  return humps.camelizeKeys(obj, function (key, convert) {
    //callback for ignoring uppercase
    return /^[A-Z0-9_]+$/.test(key) ? key : convert(key);
  });
};

export const decamelizeObject = (obj) =>
  //callback for ignoring uppercase
  humps.decamelizeKeys(obj, (key, convert, options) => (/^[A-Z0-9_]+$/.test(key) ? key : convert(key, options)));

export const getLegNames = (segments) => {
  const legNames = {};
  for (let i = 0; i < segments.length - 1; i += 1) {
    legNames[`stop${i + 1}`] = segments[i.toString()].destination.iataCode;
  }
  return legNames;
};

// TODO improve the logic here, we need to find out if we are adding legs
// or results, may be use isInternationaL?
export const calculateStep = (resultIndex, legIndex) => {
  if (legIndex > 0) {
    return legIndex;
  }
  return resultIndex;
};

export const renderColumnInput = (label, isRequired, fieldName, componentToRender, otherProps) => {
  const isRequiredClass = classNames({
    asterick: true,
    required: isRequired,
  });
  return (
    <div className="col">
      <label>
        {`${label}`}&nbsp;
        <span className={isRequiredClass}>*</span>
        <span className="required-message">*Required</span>
      </label>
      <div className="input-holder">
        <Field name={fieldName} component={componentToRender} {...otherProps} />
      </div>
      {otherProps.hint && <span className="hint-message">{otherProps.hint}</span>}
    </div>
  );
};

export const getGTMProductName = (segments, routeType) => {
  const allSegmentsLastIndex = segments.length - 1;
  const innerSegmentLastIndex = segments[allSegmentsLastIndex].length - 1;
  let productName = '';
  if (routeType === TRIP_TYPES.MULTI) {
    productName = `${segments[0][0].origin.city}(${segments[0][0].origin.iataCode}) to ${segments[allSegmentsLastIndex][innerSegmentLastIndex].destination.city}(${segments[allSegmentsLastIndex][innerSegmentLastIndex].destination.iataCode}) + ${routeType}`;
  } else {
    productName = `${segments[0][0].origin.city}(${segments[0][0].origin.iataCode}) to ${
      segments[0][segments[0].length - 1].destination.city
    }(${segments[0][segments[0].length - 1].destination.iataCode}) + ${routeType}`;
  }
  return productName;
};

export const getGTMLocationList = (segments, variable) => {
  const allSegmentsLastIndex = segments.length - 1;

  return `${segments[0].origin.city} ${variable} ${segments[allSegmentsLastIndex].destination.city}`;
};

export const getGTMProductID = (segments, routeType) => {
  const allSegmentsLastIndex = segments.length - 1;
  const innerSegmentLastIndex = segments[allSegmentsLastIndex].length - 1;
  let id = '';
  if (routeType === TRIP_TYPES.RETURN) {
    id = segments[0][segments[0].length - 1].destination.iataCode;
  } else {
    id = segments[allSegmentsLastIndex][innerSegmentLastIndex].destination.iataCode;
  }
  return id;
};

export const getConditionalProviders = (paymentProviders = null, orderExpiry = null) => {
  if (getOrderExpiryLimit(orderExpiry) < 60) {
    return paymentProviders.filter(
      (provider) => provider.name !== PAYMENT_METHODS.LCL_BRC.name && provider.name !== PAYMENT_METHODS.TRANSFER.name
    );
  } else if (getOrderExpiryLimit(orderExpiry) > 60 && getOrderExpiryLimit(orderExpiry) < 180) {
    return paymentProviders.filter((provider) => provider.name !== PAYMENT_METHODS.LCL_BRC.name);
  } else {
    return paymentProviders;
  }
};

/**
 * This helper filters out methods based on time limitation.
 * It uses TIME_LIMITED_PAYMENT_METHODS config to filter out providers based on methods.
 * */
export const filterProvidersForAllowedMethods = (paymentProviders = null, orderExpiry = null) => {
  return paymentProviders.filter((provider) => {
    // Some providers don't include providerInfo, include them.
    if (!provider.providerInfo) return true;
    else {
      // Find relevant provider by searching the config.
      const relevantMethod = TIME_LIMITED_PAYMENT_METHODS.filter((paymentMethod) => {
        return provider.name === paymentMethod.provider;
      })[0];
      if (relevantMethod) {
        // If a method matches the config, apply following checks
        // 1: If order expiry is greater than the maxMinutes, include the provider
        // 2: If first condition is false, check if the providerInfo has that config method.
        return Boolean(
          provider.providerInfo.find((element) => {
            if (getOrderExpiryLimit(orderExpiry) > relevantMethod.maxMinutes) {
              return true;
            } else return element.code === relevantMethod.name;
          })
        );
      } else return true;
    }
  });
};

export const getMobileWallet = (paymentProviders, orderExpiry, type) => {
  if (
    getOrderExpiryLimit(orderExpiry) < 60 ||
    (getOrderExpiryLimit(orderExpiry) > 60 && getOrderExpiryLimit(orderExpiry) < 180)
  ) {
    return paymentProviders.filter((provider) => provider.code !== type);
  } else {
    return paymentProviders;
  }
};

export const getOrderExpiryLimit = (orderExpiry) => {
  return moment.duration(moment(orderExpiry).diff(moment())).asMinutes();
};

export const round = (amount) => {
  return Math.round(amount);
};

export const formattedTime = (milliseconds) => {
  //Note: if expiry hours  > 24 it will show expiry according to it. e.g: 45:23:13
  const duration = moment.duration(milliseconds);
  const hours = ('00' + Math.floor(duration.asHours())).slice(-2);
  const timeStamp = moment.utc(duration.asMilliseconds()).format(':mm:ss');

  return hours + timeStamp;
};

export const getHotelStayDuration = (checkinTime, checkoutTime, period) => {
  const diff = moment(checkoutTime).diff(moment(checkinTime), period);
  if (diff && diff > 1) {
    return `${diff} Night(s)`;
  } else {
    return `${diff} Night`;
  }
};

export const hasSuccessfulTransactions = (transactions) =>
  transactions.filter((transaction) => transaction.status === TRANSACTION_STATUS.SUCCESS).length > 0;

const getKey = (currentMethod, currentMethodKey) => {
  /**
   * we could have key in any of the params, this method is to find the accurate provider's sub category key a/c to provider
   * we could also have main provider key instead of sub category, when we haven't selected any sub provider,
   * we won't show discount or voucher component unless user select a sub category like HBL bank
   * incase of main provider key, we are sending null
   */
  if (currentMethodKey === PAYMENT_METHODS.ONLINE.key || currentMethodKey === PAYMENT_METHODS.ONLINE.name) {
    //as HBL is the only provider in online, we will send sub-category key, for others we are sending null
    return ONLINE_PAYMENT_TYPE.CYBERSOURCE;
  }
  if (currentMethodKey === PAYMENT_METHODS.HBLDT.key || currentMethodKey === PAYMENT_METHODS.HBLDT.name)
    return PAYMENT_METHODS.HBLDT.key;
  if (currentMethodKey === PAYMENT_METHODS.NIFT.key || currentMethodKey === PAYMENT_METHODS.NIFT.name)
    return PAYMENT_METHODS.NIFT.key;
  if (currentMethod === PAYMENT_METHODS.LCL_BRC.key) {
    return null;
  }
  if (currentMethod === PAYMENT_METHODS.TRANSFER.key) {
    return null;
  }
  return currentMethod;
};

export const getAllSubMethods = (providers) => {
  //to get all sub categories in a single for easy filtering
  let allMethods = [];
  providers.map((provider) => {
    provider.fees.map((subProvider) => allMethods.push(subProvider));
  });
  return allMethods;
};

/** This method takes the method code and iterate and filter over all sub methods and return the specific method in array */
export const getCurrentPaymentMethod = (paymentProviders, code) =>
  paymentProviders ? getAllSubMethods(paymentProviders).filter((method) => method.code === code) : [];

export const isDiscountEnabledAndNotPartialPayment = (
  transactions,
  currentMethod,
  offlinePaymentMethodKey,
  paymentProviders
) => {
  const key = getKey(currentMethod, offlinePaymentMethodKey);
  const currentPaymentMethod = getCurrentPaymentMethod(paymentProviders, key);

  if (transactions.length && hasSuccessfulTransactions(transactions)) return false;
  if (currentPaymentMethod.length && currentPaymentMethod[0].isDiscountEnabled) return true;
  return false;
};

export const shouldRenderCoupon = (props) => {
  const { hideDiscount, bookingForm, isMakingPayment, order, paymentProviders, currentPaymentMethod } = props;
  /**
   * author: Qaseem Lodhi
   * This method determines whether we need to hide or show the voucher component depend on following conditions
   *
   * return false - we need to hide the voucher component if we get some successful transaction in transactions array
   * or the payment method is not online(Credit Card / Jazzcash Mobile Wallet / Easypaisa Mobile Wallet)
   * or the payment is being processed i.e., the pay with button is in loading state
   * or voucher is supposed to be hidden from user and hideDiscount is set to true
   *
   * return true - we show the vocuher field when we have not any successful transaction, the current method get isDiscountEnable=true from backend and the button is not in loading state yet.
   */
  const offlineMethodKey = bookingForm.offlinePaymentForm && bookingForm.offlinePaymentForm.values.method;
  return (
    !isMakingPayment &&
    !hideDiscount &&
    isDiscountEnabledAndNotPartialPayment(order.transactions, offlineMethodKey, currentPaymentMethod, paymentProviders)
  );
};

export const getTotalPrice = (rates) => rates.reduce((acc, val) => acc + val.total, 0);

const calculateRate = (rates) => {
  return rates.reduce(
    (acc, curr) => {
      return {
        sellingRate: acc.sellingRate + curr.sellingRate,
        fee: acc.fee + curr.fee,
        tax: acc.tax + curr.tax,
        depositRequired: acc.depositRequired + curr.depositRequired,
        grossRate: acc.grossRate + curr.grossRate,
      };
    },
    { sellingRate: 0, fee: 0, tax: 0, depositRequired: 0, grossRate: 0 }
  );
};

export const calculateHotelRoomsRates = (rooms) => {
  const totalRatesOfEachRoom = rooms.map((room) => {
    return calculateRate(room.rates);
  });
  return calculateRate(totalRatesOfEachRoom);
};
export const getTotalAmount = (rates) => rates.reduce((acc, val) => acc + val, 0);

export const getWebEngageFlightsData = (query, prefix = 'search') => {
  const flightsData = {};
  if (query.routeType === TRIP_TYPES.MULTI) {
    query.legs.forEach((leg, index) => {
      flightsData[`${prefix}IATAOrigin${index === 0 ? '' : index + 1}`] = leg.origin.iataCode;
      flightsData[`${prefix}Origin${index === 0 ? '' : index + 1}`] = leg.origin.city;
      flightsData[`${prefix}IATADestination${index === 0 ? '' : index + 1}`] = leg.destination.iataCode;
      flightsData[`${prefix}Destination${index === 0 ? '' : index + 1}`] = leg.destination.city;
      flightsData[`${prefix}Departure${index === 0 ? '' : index + 1}`] = new Date(leg.departureDate);
    });
  } else {
    flightsData[`${prefix}IATAOrigin`] = query.legs[0].origin.iataCode;
    flightsData[`${prefix}Origin`] = query.legs[0].origin.city;
    flightsData[`${prefix}IATADestination`] = query.legs[0].destination.iataCode;
    flightsData[`${prefix}Destination`] = query.legs[0].destination.city;
    flightsData[`${prefix}Departure`] = new Date(query.legs[0].departureDate);
    if (query.routeType === TRIP_TYPES.RETURN)
      flightsData[`${prefix}Returning`] = new Date(query.legs[1].departureDate);
  }
  return flightsData;
};

export const createWebEngageData = (query, prefix = 'search') => {
  return {
    ...getWebEngageFlightsData(query),
    [`${prefix}NumberTravellers`]: getTotalTraveler(query.travelerCount),
    [`${prefix}Class`]: query.cabinClass.label,
    [`${prefix}TripType`]: WEB_ENGAGE_DATA.TRIP_TYPE[query.routeType],
    [`${prefix}URL`]: window.location.href,
  };
};

export const totalPaidAmount = (transactions) => {
  return transactions.length > 0
    ? transactions.reduce(
        (transaction, currentTransaction) =>
          currentTransaction.status === PAYMENT_STATUS.SUCCESS
            ? Math.ceil(transaction + currentTransaction.amount)
            : transaction,
        0
      )
    : 0;
};

export const WrapperRoundUpNumber = ({ value }) => (
  // round Up the value in NumberFormat
  <NumberFormat value={Math.ceil(value)} displayType="text" thousandSeparator prefix={CURRENCY_PREFIX} />
);
export const getAnalyticsContentIDs = (legs) => {
  if (legs && legs.length) {
    return legs
      .map((leg) => {
        if (leg) return [leg.origin.iataCode, leg.destination.iataCode];
      })
      .filter(Boolean)
      .reduce((total, item) => {
        return total.concat(...item);
      }, []);
  }
};

export const formatOtpExpiry = (milliseconds) => {
  const duration = moment.duration(milliseconds);
  const timeStamp = moment.utc(duration.asMilliseconds()).format('mm:ss');

  return timeStamp;
};

export const signupPayload = (formData, otp) => {
  const payload = {
    ...formData,
    otp,
    contactDetail: {
      country: parsePhoneNumberFromString(formData.mobileNumber).country,
      mobileNumber: formData.mobileNumber,
    },
  };
  return caseConverter.snakeCase(payload);
};

/**
 * author: Faria Ejaz
 * This helper method shows the 'Complete payment in' timer on My Bookings
 * page depending upon if the status doesnot match with any of the following
 * statuses: 'TICKETISSUED', 'CANCELLED', 'ERROR', 'PAYMENTAWAITINGAPPROVAL'
 * and 'PROCESSINGTICKET'.
 */

export const renderBookingExpiryTimerComponent = (orderExpiry, orderStatus, onBookingTimeExpired) => {
  const isPending = orderStatus == ORDER_STATUS.PAYMENT_PENDING;
  const isInitiated = orderStatus == ORDER_STATUS.ORDER_INITIATED;
  const isPartiallyPaid = orderStatus == ORDER_STATUS.PARTIAL_PAID;

  return (
    orderExpiry &&
    (isPending || isInitiated || isPartiallyPaid) && (
      <BookingExpiry expiry={orderExpiry} expiredCB={onBookingTimeExpired} />
    )
  );
};

export const parsePythonJSON = (json) => {
  return JSON.parse(
    json.replace(/'/g, '"').replace(/None/g, 'null').replace(/True/g, 'true').replace(/False/g, 'false')
  );
};

export const updateStateHelper = (key, state, newState) => {
  return { [key]: { ...state[key], ...newState } };
};

/* author: Shah Nawaz
   This helper method converts all possible values in object into number
   It takes an object/array as a parameter and parses possible values to Integer
   Currently it supports only Arrays or Objects that have primitive properties
   like string or float, and to second level only
   e.g. { arr: ['1','2'], obj: { price: '1000' } }
*/
/*
  Purpose:
  When query data is stringified, it converts all numeric values to strings.
  In order to parse those values to integer, we neet to go through all the properties of the
  query that can be parsed to integer and convert them.
  The above case happens when we push query to the URL and then parse it to hydrate the store
*/

export const parseIntPossibleValues = (data) => {
  // deep clone the data so we can mutate it directly
  if (data && typeof data === 'object' && Object.keys(data).length) {
    const clonedData = cloneDeep(data);
    // iterate over all properties
    Object.keys(clonedData).map((key) => {
      // check if the item is an array
      if (Array.isArray(clonedData[key]) && clonedData[key].length) {
        // check if the first item of the array can be parsed to Integer
        if (parseInt(clonedData[key][0]) >= 0) {
          // parse all items into Integer
          clonedData[key] = clonedData[key].map((item) => (item = parseInt(item)));
        }
      }
      // if the item is an object
      // iterate over all properties
      else if (typeof clonedData[key] === 'object') {
        Object.keys(clonedData[key]).map((innerKey) => {
          // check if the property can be parsed to an Integer
          if (parseInt(clonedData[key][innerKey]) >= 0) {
            // parseInt the object property
            clonedData[key][innerKey] = parseInt(clonedData[key][innerKey]);
          }
          // if value can't be parsed, just return the value
          else return clonedData[key];
        });
      }
      // if the item is a string
      else if (typeof clonedData[key] === 'string') {
        // parseInt if possible
        if (parseInt(clonedData[key]) >= 0) {
          clonedData[key] = parseInt(clonedData[key]);
        }
      }
    });
    return clonedData;
  } else return data;
};

// Author: Shah Nawaz
// This helper method gives the difference between the weekdays between two days
// We cannot detect day change with moment.diff since it works on 24hrs
export const getWeekDaysDifference = (startDate, endDate) => {
  // Check if both dates are valid
  if (moment(startDate).isValid() && moment(endDate).isValid()) {
    // if the end date is in the next week, the days restart from 0-6,
    // this is why we need to add the difference and then subtract from 7
    // like if start is Friday Week1 and end is Monday Week2
    // e.g. Friday === 5 and Monday === 1 (0-6 Sun-Sat)
    // Day differnce === 7 + Monday - Friday === 3
    if (moment(startDate).week() < moment(endDate).week()) {
      return 7 + moment(endDate).weekday() - moment(startDate).weekday();
    }
    // This will work when both dates are in the same week
    // So we subtract the start day from end day
    // Sunday === 0, Monday === 1, Diff = 1-0
    else {
      return moment(endDate).weekday() - moment(startDate).weekday();
    }
  }
};

export const getHotelGuestsCount = (guestDetails) => {
  let result = {};
  if (Array.isArray(guestDetails) && guestDetails.length) {
    result = guestDetails.reduce(
      (acc, curr) => {
        return {
          adults: acc.adults + curr.adults,
          children: acc.children + (curr.children || 0),
        };
      },
      { adults: 0, children: 0 }
    );
  }

  return result;
};

export const checkBinNumbers = (cardNumber) => {
  /**
   * Author: Taley'a Mirza
   * This method determine if the card number is a valid bin number to get discount
   * it returns true if valid else return false
   */

  return BIN_NUMBERS.filter((string) => cardNumber.startsWith(string)).length > 0;
};

export const populateCyberSourceMethodPayload = (formValues) => {
  const { expiry, name, number, cvv2 } = formValues;
  const { month, year } = getExpiryDateFromStr(expiry);
  const fingerPrintId = new Date().getTime();
  return {
    device_fingerprint_id: fingerPrintId,
    name: name,
    number: number,
    cvv2: cvv2,
    month: month < 10 ? `0${month}` : month.toString(),
    year: year < 10 ? `0${year}` : year.toString(),
  };
};

export const isFeatureEnabled = (feature) => process.env.features.includes(feature);

export const findRelatedFee = (key, paymentProviders, paymentType) => {
  let methodFee = {};
  if (paymentProviders.length) {
    paymentProviders.forEach((provider) => {
      if (provider && provider.name === paymentType) {
        methodFee = {
          ...provider.fees.filter((fee) => fee.code === key)[0],
          methodName: provider.name,
        };
      }
    });
  }
  return methodFee;
};

const calculateFee = (type, fee, totalPrice, discount = 0) => {
  // In case discount is undefined. Passing it as 0
  let displayFee = '';
  switch (fee[`${type}Type`]) {
    case FEE_TYPE.fixed:
      displayFee = fee[`${type}Value`];
      break;
    case FEE_TYPE.percentage:
      /*
       * if the payment method has discount enbaled on it, we deduct discount to recalculate
       * the merchant fee. The check is needed because if we switch between OTC/MA methods, payment method
       * change isn't triggered, sp discount amount is not reset and discount is also visible on the sibling
       * payment method where it's not enabled.
       */
      displayFee = (totalPrice - (fee.isDiscountEnabled ? discount : 0)) * (fee[`${type}Value`] / 100);
      break;
    default:
      break;
  }
  return Math.round(displayFee);
};

export const calculateAdditionalCharges = (fee, totalPrice, discount) => {
  const charges = [];

  if (fee.merchantFeeValue > 0) {
    charges.push({
      label: `Merchant Fees - ${fee.methodName} - ${fee.name}`,
      value: calculateFee('merchantFee', fee, totalPrice, discount),
    });
  }

  if (fee.serviceChargesValue > 0) {
    charges.push({
      label: `Service Fees - ${fee.methodName} - ${fee.name}`,
      value: calculateFee('serviceCharges', fee, totalPrice),
    });
  }
  return charges;
};

export const getTransactionAmountCharges = (transactions, transactionStatus) => {
  const charges = [];
  transactions
    .filter((transaction) => transaction.status === transactionStatus)
    .map((transaction) => {
      if (transaction.merchantFee > 0) {
        charges.push({
          label: `Merchant Fees - ${transaction.method}`,
          value: Math.ceil(transaction.merchantFee),
        });
      }

      if (transaction.serviceFee > 0) {
        charges.push({
          label: `Service Fees - ${transaction.method}`,
          value: Math.ceil(transaction.serviceFee),
        });
      }
    });
  return charges;
};

export const getAdjustedTransations = (transactions, transactionStatus, paymentMethod) => {
  return transactions
    .filter((transaction) => transaction.status === transactionStatus && transaction.code === paymentMethod)
    .map((transaction) => {
      return {
        label: transaction.method,
        value: Math.ceil(transaction.amount),
      };
    });
};

export const getMobileNumber = (mobileNumber) => {
  // This function is used to format phone number without +
  const { countryCallingCode, nationalNumber } = parsePhoneNumberFromString(mobileNumber);
  return countryCallingCode + nationalNumber;
};

export const getLowestPrice = (flights, routeType) => {
  /**
   * Author: Taley'a Mirza
   * This method will filter flights to get minimum price, it will first filter the combos if available, then return combo price, in case we don't have any
   * combo available it will return zero, if it's oneway will return simply selling price
   * and in end, it will further filter the filtered results to get minimum price
   */

  const filteredLegs = flights
    .map((flight) => {
      if (routeType === TRIP_TYPES.ONEWAY) return flight.price.sellingFare;
      else if (!flight.hasIndividualReturnPair && flight.price.isComboPrice) return flight.price.comboPrice;
      else if (flight.hasIndividualReturnPair) return 0;
    })
    .filter((price) => !isNaN(price) && price > 0);

  return filteredLegs.length && Math.min(...filteredLegs);
};

export const hasLesserSeatsRemaining = (seatsRemaining) =>
  typeof seatsRemaining == 'number' && seatsRemaining !== 0 && seatsRemaining <= 5;

export const getHotelStayDurationDetails = (
  checkin,
  checkout,
  dateFormat = APP_DATEFORMAT,
  timeFormat = APP_TIMEFORMAT
) => {
  const stayDetailsHasTime = /T\d{2}:\d{2}/.test(checkin);
  return {
    checkinDate: moment.utc(checkin).format(dateFormat),
    checkoutDate: moment.utc(checkout).format(dateFormat),
    checkinTime: stayDetailsHasTime ? moment.utc(checkin).format(timeFormat) : null,
    checkoutTime: stayDetailsHasTime ? moment.utc(checkout).format(timeFormat) : null,
  };
};

export const parsedLocations = (groupedLocations) =>
  groupedLocations.map((location) => {
    return {
      ...location,
      icon: flagIcon,
      options: camelizeKeys(location).options.map((option) => {
        const { displayName, iataCode, city, country } = option;
        return {
          ...option,
          subHeading: displayName,
          rightAlignedCode: iataCode,
          label: `${city}, ${country}`,
          value: `${iataCode}`,
          icon: airplaneIcon,
        };
      }),
    };
  });
export const getPaymentMethodsLogo = (paymentType) => {
  switch (paymentType) {
    case PAYMENT_METHODS.PAYPRO.name:
      return paypro;

    case PAYMENT_METHODS.PAYPAK.name:
      return paypak;

    case PAYMENT_METHODS.UNIONPAY.name:
      return unionpay;

    default:
      return null;
  }
};

export const getProviderDetail = (paymentProviders, currentPaymentMethod) =>
  paymentProviders.filter((provider) => provider.key === currentPaymentMethod)[0].providerInfo;

export const getAllFlightsSegments = (bookings) => {
  if (!Array.isArray(bookings)) return;
  return bookings
    .map((booking) => {
      return booking.legs.map((leg) => ({ ...leg, bookingId: booking.id }));
    })
    .reduce((acc, curr) => acc.concat(curr), [])
    .map((leg) => {
      return leg.segments.map((segment) => ({ ...segment, bookingId: leg.bookingId }));
    })
    .reduce((acc, curr) => acc.concat(curr), []);
};

export const getToAiport = (isToAirport, requiredValue, defaultValue) => (isToAirport ? requiredValue : defaultValue);

export const getFormattedBookings = (bookings) =>
  bookings.map((booking) => {
    return {
      rules: booking.rulesJson,
      baggageInfo: booking.legs[0].baggageInfo,
      meta: booking.searchMeta,
      price: booking.price,
      isRefundable: booking.legs[0].isRefundable,
      provider: booking.provider,
      id: booking.id,
      legs: booking.legs.map((leg) => {
        return {
          ...leg,
          origin: leg.segments[0].origin.iataCode,
          destination: leg.segments[leg.segments.length - 1].destination.iataCode,
        };
      }),
    };
  });
export const totalAirportTransferCharges = (selectedTransfers) =>
  selectedTransfers.reduce((total, transfer) => total + transfer.amount, 0);

export const getTimeIn24HrsFormat = (time, timePeriod) =>
  TimeZonedMoment(`${time} ${timePeriod}`, ['h:mm A']).format('HH:mm');

export const createTourGTMData = ({
  query,
  pageURL,
  tourPackage = {},
  isGuest = false,
  isConsent = false,
  includeTotal = false,
  paymentMethod = null,
}) => {
  const totalTravelers = getTravelerTotalCount(query.travelerCount);
  return {
    toursFrom: typeof query.origin === 'object' ? query.origin.iataCode : query.origin,
    toursTo: typeof query.destination === 'object' ? query.destination.iataCode : query.destination,
    toursMonth:
      typeof query.departureMonth === 'object' ? query.departureMonth.label : query.departureMonth.split(' ')[0],
    toursTravellerCount: totalTravelers,
    searchURL: pageURL,
    ...(!isEmpty(tourPackage)
      ? {
          toursPrice: tourPackage.data.pricing.pricePerPerson,
          toursPackageName: tourPackage.title,
          ...(includeTotal ? { toursTotalPrice: tourPackage.data.pricing.pricePerPerson * totalTravelers } : {}),
        }
      : {}),
    ...(isGuest ? { isConsent } : {}),
    ...(paymentMethod ? { toursPaymentType: paymentMethod } : {}),
  };
};

export const RegexParser = (input) => {
  var m = input.match(/(\/?)(.+)\1([a-z]*)/i);

  // Invalid flags
  if (m[3] && !/^(?!.*?(.).*?\1)[gmixXsuUAJ]+$/.test(m[3])) {
    return RegExp(input);
  }

  // Create the regular expression
  return new RegExp(m[2], m[3]);
};

export const getMonths = (currentYear) => {
  return [
    { value: 0, label: `January ${currentYear}` },
    { value: 1, label: `Febuary ${currentYear}` },
    { value: 2, label: `March ${currentYear}` },
    { value: 3, label: `April ${currentYear}` },
    { value: 4, label: `May ${currentYear}` },
    { value: 5, label: `June ${currentYear}` },
    { value: 6, label: `July ${currentYear}` },
    { value: 7, label: `August ${currentYear}` },
    { value: 8, label: `September ${currentYear}` },
    { value: 9, label: `October ${currentYear}` },
    { value: 10, label: `November ${currentYear}` },
    { value: 11, label: `December ${currentYear}` },
  ];
};

export const formattedDate = (date) => moment(date).format('YYYY-MM-DD');
export const csvToArray = (str, delimiter = ',') => {
  const headers = str.slice(0, str.indexOf('\n')).split(delimiter);
  const rows = str.slice(str.indexOf('\n') + 1).split('\n');
  const parsedCsv = rows.map(function (row) {
    const values = row.split(delimiter);
    const el = headers.reduce(function (object, header, index) {
      object[header] = values[index];
      return object;
    }, {});
    return el;
  });
  return parsedCsv;
};

export const getCancellationPriceObj = (bookings) => {
  const totalPrice = bookings.map((booking) => booking.price.sellingFare).reduce((total, num) => total + num);
  const totalCancellationPrice = bookings
    .map((booking) => (booking.searchMeta.freeCancelPremium ? booking.searchMeta.freeCancelPremium : 0))
    .reduce((total, num) => total + num);

  return {
    withCancellationPrice: totalPrice + totalCancellationPrice,
    freeCancellationPrice: totalCancellationPrice,
  };
};

// this function checks for segment and returns searchAirportsInfo as per needed by analytics
export function getOriginDestinationFromSearchData(query) {
  const airportsInfo = {
    searchOrigin: '',
    searchIATAOrigin: '',
    searchDestination: '',
    searchIATADestination: '',
  };

  if (query.routeType === RouteType.MULTI) {
    query.legs
      // for every flight selected, we need to add its airportInfoN fields, where N = flight number
      .forEach(({ origin, destination }, index) => {
        airportsInfo[`searchOrigin${index === 0 ? '' : index + 1}`] = origin.city;
        airportsInfo[`searchIATAOrigin${index === 0 ? '' : index + 1}`] = origin.iataCode;
        airportsInfo[`searchDestination${index === 0 ? '' : index + 1}`] = destination.city;
        airportsInfo[`searchIATADestination${index === 0 ? '' : index + 1}`] = destination.iataCode;
      });
  } else {
    airportsInfo['searchOrigin'] = query.legs[0].origin.city;
    airportsInfo['searchIATAOrigin'] = query.legs[0].origin.iataCode;
    airportsInfo['searchDestination'] = query.legs[0].destination.city;
    airportsInfo['searchIATADestination'] = query.legs[0].destination.iataCode;
  }
  return airportsInfo;
}

/**
 * @muhammadAnas190
 * sort booking list for cross-sell, we want flight booking on first position of bookings array
 */
export function sortOrderBookings(order) {
  // OM passes decamelize object while, Purchase history & My bookings passes camelize object
  const decamelOrder = decamelizeObject(order);
  if (!decamelOrder.bookings.some((booking) => booking.booking_type === BOOKING_TYPE.FLIGHT)) {
    return order;
  }
  const { bookings } = decamelOrder;
  const flightIndex = bookings.findIndex((booking) => booking.booking_type === BOOKING_TYPE.FLIGHT);
  const flightBooking = { ...bookings[flightIndex] };

  bookings.splice(flightIndex, 1);
  return { ...order, bookings: [flightBooking, ...bookings] };
}

export function transformQueryForV3(v2Query) {
  const { cabinClass, travelerCount, routeType, legs } = v2Query;

  const transformedQuery = {
    cabinClass: JSON.stringify(cabinClass),
    travelerCount: JSON.stringify(travelerCount),
    routeType,
    legs: legs.map((leg) => {
      const { departureDate, origin, destination } = leg;
      return JSON.stringify({
        departureDate: format(new Date(departureDate), 'yyyy-MM-dd'),
        origin: origin?.iataCode,
        destination: destination?.iataCode,
      });
    }),
  };

  return `?${queryString.stringify(transformedQuery, {
    skipEmptyString: true,
    skipNull: true,
    encode: false,
    arrayFormat: 'bracket',
  })}`;
}

/**
 * @muhammadAnas190
 * Transform payment payload
 * Add method for payment and method_payload for properties
 */
export const paymentTransformer = (payload) => {
  const { method, ...methodPayload } = payload;
  return {
    method,
    methodPayload,
  };
};

export const showOnV3 = (path, search) => {
  const eventData = {
    name: XDCEventsToSend.V3RouteChanged,
    data: { pathname: path, search },
  };
  xdPWCommunicator.send(eventData);
};
