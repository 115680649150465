import React from 'react';
import { STFlex } from 'design/atoms/Container';
import { STLabel, STLink } from 'design/atoms/Text';
import links from './links';
import { StyledSystemProps } from 'design/atoms/Box';
import styled, { withTheme } from 'styled-components';
import { Link } from 'react-router-dom';
import gtm from 'util/GoogleTagManagerService';

interface FooterLinkProp extends StyledSystemProps {
  title: string;
  link?: string;
}
interface FooterTitleProp {
  title: string | React.ReactNode;
}

const FooterLinkContainer = withTheme(
  styled(STFlex)<StyledSystemProps>`
    flex-direction: column;
    padding-top: ${({ theme }) => theme.space[3]}px;
    padding-right: ${({ theme }) => theme.space[5]}px;
    justify-content: left;
  `
);

const TopFooter = ({ path }) => {
  return (
    <STFlex maxWidth="1270px" width={1} px={[1, 0]} margin="auto">
      <STFlex display={[path === '/' ? 'flex' : 'none', 'flex']} flexDirection="column" mb={3}>
        <TopDomesticAirlines />
        <TopInternationalAirlines />
        {/* <PopularHolidayDestinations /> */}
        <PopularDomesticFlightDestinations />
        <PopularInternationalFlightDestinations />
        <TopBusOperators />
        <CompanyLinks />
      </STFlex>
    </STFlex>
  );
};

const TopDomesticAirlines = () => {
  return (
    <FooterLinkContainer>
      <FooterTitle title="Top Domestic Airlines:" />
      <STFlex flexWrap="wrap">
        {links.TopDomesticAirlines.map((airline, index) => (
          <FooterLink
            key={index}
            title={airline.title}
            link={airline.link}
            borderLeft={index == 0 ? -1 : 7}
            pl={index == 0 ? 0 : 2}
          />
        ))}
      </STFlex>
    </FooterLinkContainer>
  );
};

const TopInternationalAirlines = () => (
  <FooterLinkContainer>
    <FooterTitle title="Top International Airlines:" />
    <STFlex flexWrap="wrap">
      {links.TopInternationalAirlines.map((airline, index) => (
        <FooterLink
          key={index}
          title={airline.title}
          link={airline.link}
          borderLeft={index == 0 ? -1 : 7}
          pl={index == 0 ? 0 : 2}
        />
      ))}
    </STFlex>
  </FooterLinkContainer>
);

/*
const PopularHolidayDestinations = () => (
  <FooterLinkContainer>
    <FooterTitle title="Popular Holiday Destinations:" />
    <STFlex flexWrap="wrap">
      {links.PopularHolidayDestination.map((city, index) => (
        <FooterLink
          key={index}
          title={city.title}
          link={city.link}
          borderLeft={index == 0 ? -1 : 7}
          pl={index == 0 ? 0 : 2}
        />
      ))}
    </STFlex>
  </FooterLinkContainer>
);
*/

const PopularDomesticFlightDestinations = () => (
  <FooterLinkContainer>
    <FooterTitle title="Popular Domestic Flight Destinations:" />
    <STFlex flexWrap="wrap">
      {links.PopularDomesticFlightDestinations.map((flight, index) => (
        <FooterLink
          key={index}
          title={flight.title}
          link={flight.link}
          borderLeft={index == 0 ? -1 : 7}
          pl={index == 0 ? 0 : 2}
        />
      ))}
    </STFlex>
  </FooterLinkContainer>
);

const PopularInternationalFlightDestinations = () => (
  <FooterLinkContainer>
    <FooterTitle title="Popular International Flight Destinations:" />
    <STFlex flexWrap="wrap">
      {links.PopularInternationalFlightDestinations.map((flight, index) => (
        <FooterLink
          key={index}
          title={flight.title}
          link={flight.link}
          borderLeft={index == 0 ? -1 : 7}
          pl={index == 0 ? 0 : 2}
        />
      ))}
    </STFlex>
  </FooterLinkContainer>
);

const TopBusOperators = () => (
  <FooterLinkContainer>
    <FooterTitle title="Top Bus Operators:" />
    <STFlex flexWrap="wrap">
      {links.TopBusOperators.map((flight, index) => (
        <FooterLink
          key={index}
          title={flight.title}
          link={flight.link}
          borderLeft={index == 0 ? -1 : 7}
          pl={index == 0 ? 0 : 2}
        />
      ))}
    </STFlex>
  </FooterLinkContainer>
);

const CompanyLinks = () => (
  <FooterLinkContainer>
    <FooterTitle
      title={
        <>
          <a href="https://www.sastaticket.pk/">Sastaticket.pk</a> Links:
        </>
      }
    />
    <STFlex flexWrap="wrap">
      {links.CompanyLinks.map((clink, index) => (
        <FooterLink
          key={index}
          title={clink.title}
          link={clink.link}
          borderLeft={index == 0 ? -1 : 7}
          pl={index == 0 ? 0 : 2}
        />
      ))}
    </STFlex>
  </FooterLinkContainer>
);

const FooterLink: React.FC<FooterLinkProp> = ({ title, link, borderLeft, pl, pr = 2 }) => {
  const triggerEvent = () => {
    gtm.triggerStandardEvent({
      event: 'footerClick',
      clickText: title,
    });
  };

  return (
    <STFlex flexDirection="column" minWidth="fit-content" borderLeft={borderLeft} pl={pl} pr={pr} my={1}>
      <STLink onClick={triggerEvent} as="a" href={link} variant="lightGreyRegularClean" fontSize={0}>
        {title}
      </STLink>
    </STFlex>
  );
};

const FooterTitle: React.FC<FooterTitleProp> = ({ title }) => {
  return (
    <STFlex>
      <STLabel variant="greyBold" fontSize={0}>
        {title}
      </STLabel>
    </STFlex>
  );
};
export default TopFooter;
