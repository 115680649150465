import { amplitudeService } from './amplitude/AmplitudeService';
import { ANALYTICS_EVENTS, ANALYTICS_PROVIDERS } from './Constants';
import fbp from './FacebookPixelService';
import gtm from './GoogleTagManagerService';

export interface EventType {
  category?: string;
  event: string;
  label?: string;
  value?: string;
  context?: object;
  action?: string;
  providers?: string[];
}

interface EventInstanceType {
  category: string;
  event: string;
  label?: string;
  value?: string;
  action?: string;
}

export class AnalyticsEvent {
  /**
   * @param {string} category
   * @param {string} event
   * @param {string} label
   * @param {number} value
   * @param {object} context
   */
  category: string;
  context?: object;
  event: string;
  label?: string;
  value?: string | number;
  action?: string;
  constructor(
    category: string,
    event: string,
    label?: string,
    value?: string | number,
    context?: Object,
    action?: string
  ) {
    /**
     * As per Google Analytics Doc, if label or value is not defined, they should be passed
     * as [undefined] rather than [null] or [false], or any falsey value
     * Therefore, they have to be set as unefined
     */
    this.category = category;
    this.context = context;
    this.event = event;
    if (typeof label !== 'undefined' && typeof label !== 'object') {
      this.label = label.toString() === '[object Object]' ? undefined : label.toString();
    } else {
      this.label = undefined;
    }
    this.value = typeof value === 'string' || value === undefined ? undefined : Number(value);
    this.action = typeof action === 'string' ? action : undefined;
  }
}

/**
 * Google And Facebook Analytics Event Provider for specific tracked pages
 */
export default class AnalyticsEventProvider {
  static getNavigationEvent({ event, label, value }: EventType) {
    return new AnalyticsEvent(
      ANALYTICS_EVENTS.NAVIGATION.__CATEGORY__,
      event || ANALYTICS_EVENTS.NAVIGATION.PAGE_LOAD,
      label,
      value
    );
  }

  static triggerEvent(event: any) {
    return {
      [ANALYTICS_PROVIDERS.FACEBOOK]: () => {
        fbp.trackEvent(event);
      },
      [ANALYTICS_PROVIDERS.GOOGLE]: () => {
        // ga.trackEvent(event);
        gtm.triggerStandardEvent(event);
      },
      [ANALYTICS_PROVIDERS.AMPLITUDE]: () => {
        amplitudeService.triggerStandardEvent(event);
      },
    };
  }

  static mapProviders(event: any, providers = [ANALYTICS_PROVIDERS.GOOGLE]) {
    providers.map((provider) => {
      this.triggerEvent(event)[provider]();
    });
  }

  static getInstance({ category, event, label, value }: EventInstanceType) {
    return new AnalyticsEvent(category, event, label, value);
  }

  /*
   * The methods below receive following params and the convention and types should be followed
   * @param {string} category
   * @param {string} event
   * @param {string} label
   * @param {number} value
   * @param {object} context
   * @param {array} providers
   * The <providers> parameter selects the Analytics Providers. Google is selected by default.
   * If <provider> is passed a value, Google will no longer be the default provider and the array provided
   * must contain all providers' names
   */

  static searchEvent({ event, label, value, context, providers }: EventType) {
    const eventData = new AnalyticsEvent(ANALYTICS_EVENTS.SEARCH.__CATEGORY__, event, label, value, context);
    this.mapProviders(eventData, providers);
    return eventData;
  }

  static searchResultsEvent({ event, label, value, context, providers }: EventType) {
    const eventData = new AnalyticsEvent(ANALYTICS_EVENTS.SEARCH_RESULTS.__CATEGORY__, event, label, value, context);
    this.mapProviders(eventData, providers);
  }

  static paymentsEvent({ event, label, value, context, providers }: EventType) {
    const eventData = new AnalyticsEvent(ANALYTICS_EVENTS.PAYMENTS.__CATEGORY__, event, label, value, context);
    this.mapProviders(eventData, providers);
    return eventData;
  }

  static bookingReviewEvent({ event, label, value, context, providers }: EventType) {
    const eventData = new AnalyticsEvent(
      ANALYTICS_EVENTS.FLIGHT_BOOKING_REVIEW.__CATEGORY__,
      event,
      label,
      value,
      context
    );
    this.mapProviders(eventData, providers);
  }

  static bookingPassengerDetailsEvent({ event, label, value, context, providers }: EventType) {
    const eventData = new AnalyticsEvent(
      ANALYTICS_EVENTS.BOOKING_PASSENGER_DETAILS.__CATEGORY__,
      event,
      label,
      value,
      context
    );
    this.mapProviders(eventData, providers);
  }

  static finaliseBookingDetailsEvent({ event, label, value, context, providers }: EventType) {
    const EventInstanceType = new AnalyticsEvent(
      ANALYTICS_EVENTS.FINALISE_BOOKING_DETAILS.__CATEGORY__,
      event,
      label,
      value,
      context
    );
    this.mapProviders(EventInstanceType, providers);
  }

  static myBookingsEvent({ event, label, value, context, providers }: EventType) {
    const eventData = new AnalyticsEvent(ANALYTICS_EVENTS.MY_BOOKINGS.__CATEGORY__, event, label, value, context);
    this.mapProviders(eventData, providers);
  }

  static saveProfileEvent({ event, label, value, context, providers }: EventType) {
    const eventData = new AnalyticsEvent(ANALYTICS_EVENTS.PROFILE.__CATEGORY__, event, label, value);
    this.mapProviders({ ...eventData, ...context }, providers);
  }
  static flightDetails({ event, label, value, context, providers }: EventType) {
    const eventData = new AnalyticsEvent(ANALYTICS_EVENTS.BOOKING_SUMMARY.__CATEGORY__, event, label, value, context);
    this.mapProviders(eventData, providers);
  }
  static authEvent({ event, label, value, context = {}, providers }: EventType) {
    const eventData = new AnalyticsEvent(ANALYTICS_EVENTS.AUTH.__CATEGORY__, event, label, value, context);
    this.mapProviders({ ...eventData, ...eventData.context }, providers);
  }
  static registerOTP({ event, label, value, action, context = {}, providers }: EventType) {
    const eventData = new AnalyticsEvent(
      ANALYTICS_EVENTS.MEMBER_MODULE.REGISTER.VERIFICATION_REGISTER,
      event,
      label,
      value,
      context,
      action
    );
    this.mapProviders({ ...eventData, ...eventData.context }, providers);
  }
  static loginOTP({ event, label, value, action, context = {}, providers }: EventType) {
    const eventData = new AnalyticsEvent(
      ANALYTICS_EVENTS.MEMBER_MODULE.LOGIN.__CATEGORY__,
      event,
      label,
      value,
      context,
      action
    );
    this.mapProviders({ ...eventData, ...eventData.context }, providers);
  }
}
