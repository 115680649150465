import { HotelBookingAction } from './hotelBookingActionTypes';
import * as bookingActionTypes from '../../../Booking/bookingActionTypes';
import { BookingSteps, BookingState } from 'modules/Booking/BookingStore/types';
import { HotelBookingState } from 'standardTypes';

const initialState: HotelBookingState = {
  bookings: [],
  currentStep: BookingSteps.BOOKING,
};

export default function hotelBookingReducer(state = initialState, action: HotelBookingAction): HotelBookingState {
  switch (action.type) {
    case bookingActionTypes.BOOKING_SAVE_:
      return {
        ...state,
        bookings: action.bookings,
      };
    case bookingActionTypes.BOOKING_UPDATE_STEP:
      return {
        ...state,
        currentStep: action.currentStep,
      };
    case bookingActionTypes.BOOKING_CLEAR_:
      return {
        ...state,
        bookings: [],
      };

    default:
      return state;
  }
}
