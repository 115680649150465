import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { toast, ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.min.css';

const defaultOptions = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  pauseOnHover: true,
  closeOnClick: true,
  newestOnTop: true,
};

const Toast = (props) => {
  const options = { ...defaultOptions, ...props.options };
  const defaultClass = 'toast';
  return <ToastContainer className={classNames({ [defaultClass]: true, [options.classNames]: true })} {...options} />;
};

Toast.propTypes = {
  options: PropTypes.object,
  classNames: PropTypes.string,
};

export const showToast = (paramOptions) => {
  let options = paramOptions;
  options = { ...defaultOptions, ...options };
  toast(options.msg || '', options);
};

export default Toast;
