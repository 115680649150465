import React, { Component } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

import { formattedTime } from 'util/Helpers';

class SastaTimer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timeRemaining: null,
    };

    this.interval = null;
  }

  componentDidMount() {
    this.updateRemainingTime(this.props.expireAt);
    this.interval = setInterval(this.tick, 1000);
  }

  componentDidUpdate(prevProps) {
    const expireAt = prevProps.expireAt;
    if (this.props.expireAt !== expireAt) {
      this.updateRemainingTime(expireAt);
    }
  }

  static getDerivedStateFromProps(props) {
    const timeRemaining = props.expireAt.diff(moment(new Date()));
    if (timeRemaining > 0) {
      return { timeRemaining };
    } else return null;
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  updateRemainingTime = (expirtyTime) => {
    if (expirtyTime && expirtyTime.isValid()) {
      const timeRemaining = expirtyTime.diff(moment(new Date()));

      if (timeRemaining > 0) {
        // eager check for already expired time
        this.setState({ timeRemaining });
      } else {
        clearInterval(this.interval);
        this.props.expiredCB();
      }
    }
  };

  tick = () => {
    if (this.state.timeRemaining) {
      if (this.state.timeRemaining > 0) {
        this.setState({ timeRemaining: this.state.timeRemaining - 1000 });
      } else {
        clearInterval(this.interval);
        this.props.expiredCB();
      }
    }
  };

  render() {
    return (
      <div className="sasta-timer">
        {this.state.timeRemaining > 0 ? formattedTime(this.state.timeRemaining) : '00:00:00'}
      </div>
    );
  }
}

SastaTimer.propTypes = {
  expireAt: PropTypes.object.isRequired, // eslint-disable-line
  expiredCB: PropTypes.func.isRequired,
};

export default SastaTimer;
