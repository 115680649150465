export const TICKETS_SAVE = 'TICKETS_SAVE';
export const TICKETS_CLEAR = 'TICKETS_CLEAR';
export const TRAVELER_SAVE_COUNT = 'TRAVELER_SAVE_COUNT';
export const BOOKING_SAVE = 'BOOKING_SAVE';
export const BOOKING_CLEAR = 'BOOKING_CLEAR';
export const INSURANCE_BOOKING_SAVE = 'INSURANCE_BOOKING_SAVE';
export const TRANSFER_BOOKING_SAVE = 'TRANSFER_BOOKING_SAVE';
export const INSURANCE_BOOKING_CLEAR = 'INSURANCE_BOOKING_CLEAR';
export const BOOKING_FARE_RULES = 'BOOKING_FARE_RULES';
export const BOOKING_FARE_RULES_RESET = 'BOOKING_FARE_RULES_RESET';

export const SASTA_ACCOUNTS_ADD = 'SASTA_ACCOUNTS_ADD';
export const SASTA_BRANCHES_ADD = 'SASTA_BRANCHES_ADD';
export const ALL_PROVIDERS_DETAIL = 'ALL_PROVIDERS_DETAIL';
export const CYBER_SRC_FORM_FIELDS_SET = 'CYBER_SRC_FORM_FIELDS_SET';
//todo: rename select search result
export const ADD_SEARCH_RESULT = 'ADD_SEARCH_RESULT';
export const CLEAR_SEARCH_RESULTS = 'CLEAR_SEARCH_RESULTS';
export const BOOKING_SAVE_ = 'BOOKING_SAVE_';
export const BUS_BOOKING_SAVE_ = 'BUS_BOOKING_SAVE_';
export const BUS_BOOKING_UPDATE = 'BUS_BOOKING_UPDATE';
export const BOOKING_CLEAR_ = 'BOOKING_CLEAR_';
export const BOOKING_UPDATE = 'BOOKING_UPDATE';
export const BOOKING_CURRENT = 'BOOKING_CURRENT';
export const BOOKING_UPDATE_STEP = 'BOOKING_UPDATE_STEP';
export const BOOKING_CLEAR_SELECTED_BOOKING = 'BOOKING_CLEAR_SELECTED_BOOKING';
export const BOOKING_WEB_REFERENCE = 'BOOKING_WEB_REFERENCE';
