import { ResultsState } from './resultTypes';

const resultsInitialState: ResultsState = {
  searchResults: null,
  calendarFares: [],
  isInternationalCombo: false,
  multiCityStepNumber: 0,
  selectedResults: [],
};

export default resultsInitialState;
