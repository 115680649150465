import { SearchActionTypes, SearchState, SearchActionCreatorsTypes } from './searchTypes';
import { searchInitialState } from './searchInitialState';

export const queryReducer = (state = searchInitialState, action: SearchActionCreatorsTypes): SearchState => {
  switch (action.type) {
    case SearchActionTypes.UPDATE_QUERY:
      return {
        ...state,
        query: {
          ...state.query,
          ...action.query,
        },
      };
    case SearchActionTypes.TOGGLE_DATE_PICKER:
      return {
        ...state,
        datePickerVisibility: action.flag,
      };
    default:
      return state;
  }
};
