import React from 'react';

export interface SastaWalletContextProps {
  isFetchingSession: boolean;
  isSessionInitiated: boolean;
  isValidSession: boolean;
  availableBalance: number;
  initiateSession: (isOMS?: boolean) => void;
  getBalance: (value?: boolean) => void;
  onCancel: () => void;
  onValidateOtp: () => void;
  onReInitiateSession: () => void;
  isSastaWalletEnable: (role: string[]) => boolean;
  setSession: () => void;
}

export const SastaWalletContext = React.createContext<SastaWalletContextProps | undefined>(undefined);
