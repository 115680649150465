/* eslint-disable camelcase */
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';

import { removeInLS, getFromLS, stringDecrypt } from './util/Helpers';
import gtm from 'util/GoogleTagManagerService';
import { removeUser, saveUser } from 'modules/Account/accountActions';
import { XDCEventsToSend, xdPWCommunicator } from 'modules/V3App/xd-communicator';
import { store } from './store';

import { ACCOUNT_ROUTE_PREFIX, ROUTE_PREFIX, ROLE_PERMISSION } from 'util/Constants';

const locationHelper = locationHelperBuilder({});

const logoutUser = () => {
  store.dispatch(removeUser());
  gtm.addCustomDimensions({ userLoginStatus: '0' });
  removeInLS('user');
};

// imported in this way because we also have to use it in localStorageChangeListener(event)
export { logoutUser };

// To listen against any event which cause the change of localstorage
export function localStorageChangeListener(event) {
  if (event.key === 'user') {
    let user = stringDecrypt(getFromLS('user') || '');
    if (user) {
      user = JSON.parse(user);
      store.dispatch(saveUser(user));
    }
  }
}

export const userIsAuthenticated = connectedRouterRedirect({
  redirectPath: `${ROUTE_PREFIX}/login`,
  allowRedirectBack: true,
  authenticatedSelector: (state) => Boolean(state.account && state.account.user && state.account.user.token),
  wrapperDisplayName: 'UserIsAuthenticated',
});

export const userIsNotAuthenticated = connectedRouterRedirect({
  // If user is authenticated and lands on unauthenticated route then he will be redirected to /user page
  redirectPath: (state, ownProps) => {
    return (
      locationHelper.getRedirectQueryParam(ownProps) ||
      (state.account && state.account.user && state.account.user.user && `${ROUTE_PREFIX}/admin-panel`)
    );
  },

  allowRedirectBack: false,
  authenticatedSelector: (state) => {
    return Boolean(!state.account || !state.account.user || !state.account.user.user);
  },
  wrapperDisplayName: 'UserIsNotAuthenticated',
});

export const userIsAdminRedir = connectedRouterRedirect({
  redirectPath: `/`,
  allowRedirectBack: false,
  authenticatedSelector: (state) => {
    const token = stringDecrypt(getFromLS('user'));

    if (token) {
      const parsedToken = JSON.parse(token);
      const roles = parsedToken.role && parsedToken.role.length && parsedToken.role.join(',');
      if (roles) {
        const isAdmin = roles.includes(ROLE_PERMISSION);
        return Boolean(state.account && state.account.user && state.account.user.token && isAdmin);
      }
    }
    return false;
  },
  predicate: (user) => user.role.isAdmin,
  wrapperDisplayName: 'UserIsAdmin',
});

/*
 NOTES:
 https://mjrussell.github.io/redux-auth-wrapper/docs/Getting-Started/Overview.html#where-to-apply
 Not safe to apply the authWrapper

 const routes = (
 <Route path="/" component={App}>
 <Route path="auth" getComponent={(nextState, cb) => {
 cb(null, authWrapper(Foo))
 }} />
 ...
 </Route>
 )

 Options:

 redirectPath: '/login'
 #The url to redirect user to if they fail

 redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/foo'
 #This sends the user either to the query param route if we have one, or to the landing page if none is
 #specified and the user is already logged in

 allowRedirectBack: false
 #This prevents us from adding the query parameter when we send the user away from the login page

 authenticatedSelector: state => state.user.data !== null
 #Determine if the user is authenticated or not

 authenticatingSelector: state => state.user.isLoading
 #Returns true if the user auth state is loading

 AuthenticatingComponent: Loading
 #Render this component when the authenticatingSelector returns true

 redirectAction: routerActions.replace
 #If you want to dispatch a redux action to perform navigation instead of interacting directly with the history/router
 #object then you can pass the redux action creator to redirectAction

 */
