export const USER_ACCOUNT_FORM_PREFIX = 'accountDetails';
export const RESET_PASSWORD = 'resetPassword';

export const APP_FORMS = {
  OFFLINE_PAYMENT: 'offlinePaymentForm',
  ONLINE_PAYMENT: 'OnlinePaymentForm',
  SASTA_WALLET: 'SastaWalletForm',
  HBL_DIRECT_TRANSFER: 'HblDirectTransferForm',
  LOGIN: 'loginForm',
  SIGNUP: 'signupForm',
  CONTACT_FORM: 'contactForm',
  TRAVELER_FORM: 'travelerForm',
  PERSONAL_INFO: 'personalInfo',
  ADDRESS: 'address',
  EMERGENCY_CONTACT: 'emergencyContact',
  CHANGE_EMAIL: 'changeEmail',
  CHANGE_PASSWORD: 'changePassword',
  CHANGE_MOBILE_NUMBER: 'changeMobileNumber',
  FORGOT_PASSWORD: 'forgotPassword',
  REGISTER_PHONE_NUMBER: 'registerPhoneNumberForm',
  ADDON_FORM: 'addonForm',
  SEARCH_BOOKINGS_FORM: 'searchBookings',
  PRE_REGISTRATION_FORM: 'preRegistration',
  SUBSCRIPTION_FORM: 'subscriptionForm',
  AIRPORT_TRANSFER_LOCATION_FORM: 'airportTransferLocationForm',
  DRIVER_DETAIL_FORM: 'driverDetailForm',
  NIFT_FORM: 'niftForm',
  REFUND_BANK_DETAILS_FORM: 'refundBanDetailsForm',
};

export const SIGNUP_KEYS = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  EMAIL_ADDRESS: 'email',
  MOBILE_NUMBER: 'mobileNumber',
  PASSWORD: 'password',
  CONFIRM_PASSWORD: 'confirmPassword',
  SEC_QUES: 'secret_question',
  SEC_ANS: 'secret_question_answer',
};

export const LOGIN_KEYS = {
  MOBILENUMBER: 'mobileNumber',
};

export const RESET_PASSWORD_KEYS = {
  NEW_PASSWORD: 'password',
  REPEAT_PASSWORD: 'confirmPassword',
};

export const SEARCH_BOOKINGS_KEYS = { EMAIL_ADDRESS: 'email', ORDER_ID: 'orderId' };

export const CONTACT_DETAIL_KEYS = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  MOBILE_NUMBER: 'mobileNumber',
  EMAIL_ADDRESS: 'email',
  IS_CONSENT: 'isConsent',
};

export const TRAVELER_DETAIL_KEYS = {
  DOB: 'dob',
  FIRST_NAME: 'firstName',
  GENDER: 'gender',
  LAST_NAME: 'lastName',
  NATIONALITY: 'nationality',
  PASSPORT_EXPIRY: 'passportExpiry',
  PASSPORT_NUMBER: 'passportNumber',
  PHONE_NUMBER: 'phoneNumber',
  PREVIOUS_PASSENGER: 'previousPassenger',
  TITLE: 'title',
  TYPE: 'passenger_type',
  VISA_TYPE: 'visaType',
  CNIC: 'CNIC',
};

export const ADMIN_REPORT_SEARCH_KEYS = {
  ORDER_ID: 'id',
  STATUS: 'status',
  PNR: 'pnr',
  FLIGHT_TYPE: 'flight_type',
  PRODUCTS: 'products',
  AGENT: 'agent',
  SEARCH: 'search',
  AFFILIATE: 'affiliate',
};

export const ADMIN_REPORT_RESPONSE_KEYS = {
  ORDER_ID: 'id',
  STATUS: 'status',
  PRODUCTS: 'products',
  AGENT_ID: 'agent_id',
  CONTACT_SUMMARY: 'contact_summary',
  BOOKINGS_SUMMARY: 'bookings_summary',
  PNR: 'pnr',
  CREATED_AT: 'created_at',
};

export const ADDON_KEYS = {
  BAGGAGE: 'baggage',
};

export const PERSONAL_INFO_KEYS = {
  TITLE: 'title',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  DOB: 'dateOfBirth',
  MOBILE_NUMBER: 'mobileNumber',
};

export const ADDRESS_KEYS = {
  COUNTRY: 'country',
  ADDRESS_LINE_ONE: 'street1',
  ADDRESS_LINE_TWO: 'street2',
  CITY: 'city',
  STATE: 'state',
  ZIP_CODE: 'zipCode',
};

export const EMERGENCY_CONTACT_KEYS = {
  FIRST_NAME: 'emFirstName',
  LAST_NAME: 'emLastName',
  MOBILE_NUMBER: 'emTelephone',
};

export const CHANGE_EMAIL_KEYS = {
  CURRENT_EMAIL: 'currentEmail',
  NEW_EMAIL: 'email',
};

export const CHANGE_PASSWORD_KEYS = {
  OLD_PASSWORD: 'oldPassword',
  NEW_PASSWORD: 'password',
  NEW_PASSWORD_CONFIRM: 'confirmPassword',
};

export const CHANGE_MOBILE_NUMBER_KEYS = {
  MOBILE_NUMBER: 'mobileNumber',
};

export const PRE_REGISTRATION_FORM = {
  EMAIL: 'email',
  MOBILE_NUMBER: 'mobileNumber',
};

export const GENDER_TYPES = [
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' },
  { value: 'Other', label: 'Other' },
];

export const ONLINE_FORM_KEYS = {
  NAME: 'name',
  CRD_NUM: 'number',
  SEC_NUM: 'cvv2',
  EXP_DATE: 'expiry',
  METHOD_CODE: 'method',
};

export const CRD_TYPE_CODES = {
  VISA: {
    key: 'VISA',
    code: '001',
  },
  MASTER: {
    key: 'MASTER',
    code: '002',
  },
  AMEX: {
    key: 'AMEX',
    code: '003',
  },
  DISCOVER: {
    key: 'DISCOVER',
    code: '004',
  },
};

export const LCL_BRC_KEYS = {
  PAYMENT_TYPE: 'paymentType',
  LOC_BRANCH: 'localBranch',
  REF: 'reference',
};

export const FORGOT_PASSWORD_KEYS = {
  EMAIL: 'email',
};

export const VISA_TYPES = {
  VISIT: 'Visit',
  BUSINESS: 'Business',
  TOURIST: 'Tourist',
  RESIDNECE: 'Residence / PR',
  EMPLOYMENT: 'Employment',
  CITIZEN: 'Citizen',
  DIPLOMAT: 'Diplomat',
  DEPORTEE: 'Deportee',
  OTHER: 'Other',
};

export const NUM_OF_MONTHS = 12;
export const MAX_ALLOWED_YEARS = 20;

export const PROVIDERS = {
  SABRE: 'SB',
  AIR_BLUE: 'AB',
  SERENE: 'SR',
  SHAHEEN: 'SH',
  PIA: 'PK',
  PIA_CRAWLER: 'piacrawler',
};

export const INPUT_TYPES = {
  DROP_DOWN: 'dropdown',
  TEXT_INPUT: 'textInput',
  DATE_INPUT: 'dateInput',
  PHONE_INPUT: 'phoneInput',
  RADIO_INPUT: 'radioInput',
  MASK_INPUT: 'maskInput',
};

export const HBL_DIRECT_TRANSFER_KEYS = {
  CNIC: 'nic',
  HBL_ACC_NO: 'accountNumber',
};

export const AIRPORT_TRANSFER_LOCATION_KEYS = {
  ADDRESS: 'address',
  TIME: 'time',
  TIME_PERIOD: 'timePeriod',
};

export const DRIVER_DETAIL_KEYS = {
  VENDOR_BOOKING_ID: 'vendor_booking_id',
  VENDOR: 'vendor',
  DRIVER_NAME: 'driver_name',
  DRIVER_NUMBER: 'driver_number',
  CAR_MODEL: 'car_model',
  CAR_NUMBER: 'car_number',
};

export const NIFT_KEYS = {
  BANK: 'bank',
  CNIC: 'nic',
  ACC_NO: 'accountNumber',
  OTP: 'otp',
};

export const REFUND_BANK_DETAILS_KEYS = {
  BANK_CODE: 'bankCode',
  ACCOUNT_NUMBER: 'accountNumber',
  IBAN: 'iban',
  ACCOUNT_TITLE: 'accountTitle',
  METHOD_CODE: 'methodCode',
};
