import React from 'react';
import PropTypes from 'prop-types';

const Loader = ({ options, isLoading }) => {
  const defaultClass = 'pg_load_animation';
  const styleClasses = options && options.styleClasses ? `${defaultClass} ${options.styleClasses}` : defaultClass;

  return (
    isLoading && (
      <div className={styleClasses}>
        <div className="sasta-animation-loader">
          <div />
          <div />
          <div />
        </div>
      </div>
    )
  );
};

Loader.propTypes = {
  isLoading: PropTypes.bool,
  options: PropTypes.object.isRequired,
};

export default Loader;
