import { TravelerCount, Amenity, Image, PaymentType } from 'standardTypes';
import { AnyAction } from 'redux';
import { FilterResponse } from 'modules/Hotels/Search/HotelSearchStore/hotelSearchTypes';
import { HotelSearchQuery } from 'modules/Hotels/Search/HotelSearchStore/hotelSearchQueryTypes';

export interface HotelResultsState {
  hotelSearchResults: HotelSearchResponse | null;
}

export const HotelResultActiontypes = {
  ADD_HOTEL_RESULTS: 'ADD_HOTEL_RESULTS',
  CLEAR_HOTEL_RESULTS: 'CLEAR_HOTEL_RESULTS',
  UPDATE_FILTERS_IN_RESPONSE: 'UPDATE_FILTERS_IN_RESPONSE',
  MERGE_HOTEL_RESULTS: 'MERGE_HOTEL_RESULTS',
};

export interface AddHotelResultsAction extends AnyAction {
  type: typeof HotelResultActiontypes.ADD_HOTEL_RESULTS;
  hotelSearchResults: HotelSearchResponse;
}

export interface ClearHotelResultsAction extends AnyAction {
  type: typeof HotelResultActiontypes.CLEAR_HOTEL_RESULTS;
  hotelSearchResults: null;
}

export interface UpdateResultsFiltersAction extends AnyAction {
  type: typeof HotelResultActiontypes.UPDATE_FILTERS_IN_RESPONSE;
  requestedFilters: object;
}

export interface MergeHotelResultsAction extends AnyAction {
  type: typeof HotelResultActiontypes.MERGE_HOTEL_RESULTS;
  hotelSearchResults: HotelSearchResponse;
}

export type HotelResultsAction =
  | AddHotelResultsAction
  | ClearHotelResultsAction
  | UpdateResultsFiltersAction
  | MergeHotelResultsAction;

export interface HotelMap {
  id: number;
  lat: number;
  lng: number;
}

export interface HotelSearchResponse {
  searchQuery: HotelSearchQuery;
  hotels: Array<HotelBriefResponse>;
  filters: FilterResponse;
  // maps: Array<HotelMap>;
}

export interface HotelBriefResponse {
  id: number;
  propKey: string;
  amenities: Array<Amenity>; //for filters
  checkinTime: string;
  checkoutTime: string;
  facilities: Array<string>; //for filters
  interests: Array<string>; //for filters
  isFeatured: boolean;
  featured: boolean;
  setting: string; // countryside -> for filters
  price: number; //minimum price of room -> From Rs. XXX
  freeCancellation: boolean;
  reserveNowPayLater: boolean; //need to identify it's property name
  rating: number;
  accommodationType: AccommodationType; //for filters
  longitude: number;
  latitude: number;
  description: string;
  thumbnailImage: ThumbnailImage;
  city: string; //location
  countryName: string;
  neighborhood: string; //for filter clifton, dha
  name: string;
  shortName: string;
  address1: string;
  address2: string;
  rooms: Array<Room>;
  distanceFromDownTown: string;
  recommended: boolean;
  propertyClass: number;
  nearbyPlaces: Array<{ location: string; distance: string }>;
  images: Image[];
  telephone: string;
  importantInformation: string;
  provider: string;
}

interface ThumbnailImage {
  title: string;
  url: string;
}

interface StopSell {
  msg: string;
  start: string;
  end: string;
}

export interface Room {
  roomId: number;
  roomName: string; //includes free cancellation
  roomDescription: string; //having extra charges info like mattress
  boardBasis: string;
  rates: Array<Rate>;
  pricePerPerson: number;
  isRefundable: boolean;
  adults: number;
  children: number;
  infants: number;
  images: Image[];
  bookingPolicy: string;
  stopSell: Array<StopSell>;
}
export interface cancellationPolicies {
  id: number;
  startFrom: string;
  endAt: string;
  penalty: string;
  description: string;
}

export type RateMeta = {
  rateId?: number;
  showMarkedUpRate?: boolean;
};
interface Rate {
  availableRooms: number;
  rateClass: string;
  cancellationPolicy: Array<cancellationPolicies>;
  depositRequired: number;
  grossRate: number;
  serviceFee: number;
  fee: number;
  tax: number;
  sellingRate: number;
  total: number;
  adjustment: number;
  paymentType: PaymentType;
  guestsCount: TravelerCount;
  meta: RateMeta;
}

type AccommodationType = 'Hotel' | 'Villa' | 'Apartment';
