export const BTN_ACTIONS = {
  CONTINUE_BOOKING: 'Continue to Booking',
  MOBILE_CONTINUE_BOOKING: 'BOOK',
  CONTINUE_PAYMENT: 'Continue to Payment',
  GO_TO_MY_BOOKINGS: 'Go To My Bookings',
  RESET: 'Reset password',
  SUBMIT: 'Submit',
  BACK: 'Back',
  CONTINUE: 'Continue',
  CONFIRM: 'Confirm',
  UPDATE: 'Update',
  FB_LOGIN: 'Facebook',
  G_LOGIN: 'Google',
  RETURN_SEARCH_RESULT: 'Return to Search Results',
  RETURN_HOME_PAGE: 'Return to Home Page',
  VIEW_MY_BOOKING: 'View My Booking',
  BOOK_NOW: 'Book Now',
  SAVE: 'Save',
  SELECT_FARE_OPTION: 'Select',
  SEARCH_MORE_FLIGHTS: 'Search More Flights',
  ADD_TRANSFER_DETAILS: 'Add Transfer Details',
};

export const DATE_LABELS_MOBILE = { ARRIVAL_DATE: 'Arrival Date', DEPARTURE_DATE: 'Departure Date' };

export const SELECT_PLACEHOLDER = 'Select option';

export const BOOKING_EXP_MSG = 'Booking expired. Please make a fresh booking.';

export const EASYPAY_TYPE_MSG = 'Please select easypaisa payment type from the options below.';
export const JAZZ_TYPE_MSG = 'Please select jazzcash payment type from the options below.';

export const HOTEL_PAYMENT_AGREEMENT_MSG = [
  `By selecting to complete this booking, I acknowledge that I have read and accept the `,
  `Terms of Use`,
  `, and `,
  `Privacy Policy.`,
];
export const HOTEL_DISCLAIMER_MSG = [
  'PLEASE NOTE: ',
  'We will make every effort to ensure that your booking is confirmed with the supplier after receipt of payment. However in the unlikely event that the supplier refuses your bookings we will contact you at your earliest convenience.',
  'A customer service representative will be in touch with you to assist.',
];
export const PAYMENT_AGREEMENT_MSG = [
  `By selecting to complete this booking, I acknowledge that I have read and accept the above Policy section containing Fare Rules & Restrictions, `,
  `Terms of Use`,
  `, and `,
  `Privacy Policy.`,
];
export const BOOKING_DISCLAIMER_MSG =
  'We are currently experiencing technical issues with our telephone lines. Please email us at support@sastaticket.pk for any support you require.';

export const STEPS_INFO = {
  DOMESTIC: 'One way total for all travelers (incl. taxes and fees)',
  INTERNATIONAL: 'Prices are total for all travelers and include all taxes and fees',
  INTERNATIONAL_MOBILE: 'Roundtrip total for all travelers (incl. taxes and fees)',
};

export const SEC_MODAL_MSGS = {
  TITLE: 'CVV Number',
  HEADING: `The card security code is a unique three digit number printed on the back of your debit/credit card.`,
  WHATS_GOOD: `This number is not embossed on the card and hence not printed on receipts, making it difficult for anyone other than the genuine cardholder to know it.
  Some card issuers refer to this number as the card’s security code’s others as the personal’s security code’s and others as card’s verification value’s.`,
  INTRO: `Most cards have a set of numbers printed at the top of the signature strip on the reverse of the card, the final three-digit number is your security code as shown in the example .`,
};

export const PRICE_UPDATED_COPIES = {
  TITLE: 'Price Update',
  MSG: `Price for your selected Ticket(s) have been updated. Please re-search your tickets so that you get
  latest best prices.`,
};

export const PRICE_COPIES = {
  INCLUSIVE_TAXES: 'Inclusive of all taxes and surcharges.',
};

export const HINT_ID_CARD = 'As on ID Card/passport/driving license (without degree or special characters).';

export const FORGOT_PASSWORD_PROMPT = 'Please enter your email and we will send you a temporary password.';

export const SEARCH_LOADING = 'Loading Search Results';
export const PURCHASES_LOADING = 'Loading your Purchases';
export const SALES_LOADING = 'Loading your Sales';
export const BOOKINGS_LOADING = 'Loading your Bookings';
export const BOOKING_DETAIL_LOADING = 'Loading your Booking Details';
export const MORE_DETAIL_LOADING = 'Loading More Details';

export const DOWNLOAD_TICKET = 'Download Ticket';

export const BAGGAGE_INFO = {
  TITLE: 'Baggage Info',
};

export const FARE_RULES = {
  NONREFUND_MSG: `Any cancellation or changes made to this booking may be subject to airline fees, please check fare rules before requesting for a refund.`,
  REFUND_MSG: `Any cancellation or changes made to this booking may be subject to airline fees.
Please check fare rules before requesting for a refund.`,

  CHANGE_FEE_MSG: 'For ticket changes, passengers pay the change along with the difference in fare (if applicable).',

  HEADING: {
    TIME: 'Time',
    CANCELLATION_FEE: 'Cancellation Fee',
    CHANGE_FEE: 'Change Fee',
    BAGGAGE: 'Baggage',
    FEE: 'Fee',
  },
};

export const FINALIZED_BUS_BOOKING_COPIES = [
  'Your E-voucher will be issued upon receipt of payment.',
  'Your itinerary is available online! Print it, email it, share it and check for updates.',
  'Upon issuance, your e-voucher will be available in My Bookings section of your Account and will also be emailed to you.',
];

export const FINALIZED_BOOKING_COPIES = [
  'Your E-voucher will be issued upon receipt of payment. This voucher can be used to check-in at the hotel',
  'Your itinerary is available online! Print it, email it, share it and check for updates.',
  'Upon issuance, your e-voucher will be available in My Bookings section of your Account and will also be emailed to you.',
];

export const RESULTS_EXPIRED_COPIES = {
  TITLE: 'Please refresh your search to see the latest prices.',
  MSG: `Flight prices change frequently due to availability and demand. We want to make sure you always see the most
  up-to-date prices.`,
  BTN_COPY: 'Refresh Search',
};

export const NOT_FOUND = {
  TITLE: '404',
  MSG: `Page you were looking for couldn't be found!`,
  PROMPT_MSG: 'Search Flights',
};

export const FLIGHT_DTL_MODAL = {
  TITLE: 'Flight Details',
};

export const G_LOGIN_MODAL = {
  POPUP_BLOCKED: 'Please allow popups for this domain.',
  SOMETHING_WRONG: 'Something went wrong with Google app. Please try later.',
};

export const metaTitles = {
  '/': 'Online train Ticket Reservation Pakistan | Search train Routes, Fares & Timings',
  '/trains': 'Online train Ticket Reservation Pakistan | Search train Routes, Fares & Timings',
  '/results': 'Online train Tickets Reservation – Search Results',
  '/passengers': 'Traveler Information – Sastaticket.pk',
  '/payment': 'Confirmation – Sastaticket.pk',
  '/payment-detail': 'Payment Details – Sastaticket.pk',
};

export const metaDescriptions = {
  '/': `Search and book cheap train tickets only at Sastaticket.pk. Compare various train fares from Karachi, Lahore,
  Islamabad, Rawalpindi and more.`,
  '/trains': `Search and book cheap train tickets only at Sastaticket.pk. Compare various train fares from Karachi,
  Lahore, Islamabad, Rawalpindi and more.`,
  '/results': 'Search and Book train Tickets with lowest fares on Sastaticket.pk',
  '/passengers': 'Traveler Information – Sastaticket.pk',
  '/payment': 'Confirmation – Sastaticket.pk',
  '/payment-detail': 'Payment Details – Sastaticket.pk',
};

export const HOTEL_ORDER_CONFIRMATION_MESSAGE = 'Your hotel order is confirmed!';

export const BACKEND_ERROR_MESSAGES = {
  DUPLICATE_BOOKING: 'booking already exists',
};

export const SASTA_WALLET = {
  USE_BALANCE: 'Use your balance to pay for your booking.',
  USE_OTP: 'To use the wallet you will need to authenticate using an OTP.',
  OTP_PAYMENT_TITLE: 'Please enter the OTP sent to your mobile number to confirm your purchase.',
  INSUFFICIENT_BALANCE:
    'Sorry, you do not have sufficient balance to pay with Sasta Wallet. Please try using another payment method.',
  SESSION_EXPIRED:
    'Your session has timed out. Please authenticate your session again to continue paying with Sasta Wallet.',
  OIP_ACCESS_WALLET_TITLE: 'Please enter the OTP sent to your registered mobile number to access Sasta Wallet.',
  OIP_WALLET_DEPOSIT: 'Please enter the OTP sent to your registered mobile number for Sasta Wallet deposit.',
  DEPOSIT_TITLE: 'Deposit amount in your wallet to pay with it on checkout.',
  DEPOSIT_INSTRUCTIONS:
    'Please deposit your desired amount to the given account details and upload a proof of payment image.',
  DEPOSIT_REQUEST: 'Request for depsoit amount has been sent.',
};

export const DUPLICATE_BOOKING_MSG =
  "This booking already exists. Please go to 'My Bookings' page to continue with your earlier booking or call us at 021 111-172-782 for further assistance.";
export const EMAIL_VERIFICATION_MESSAGE =
  'Your email address has been updated but it is not verified yet.To verifiy your email, please click on the verification link sent to';
export const VERIFIED_EMAIL_MSG = 'You have successfully verified your email address.';
export const UPDATE_MOBILE_NUMBER_MSG = 'Your mobile number has been successfully updated.';
export const UPDATE_PASSWORD_MSG = 'Your password has been successfully updated.';
export const CANCEL_BOOKING_CONFIRMATION = 'Are you sure you want to cancel this flight booking?';
export const FARE_INCREASED_TEXT =
  'The airline has increased fares on your selected flight due to limited availability on your dates.';
export const FARE_DECREASED_TEXT = "You're in luck! The airline has slashed fares on your selected flight.";
export const OTP_RESEND_SUCCESS = 'OTP has been sent successfully.';
export const REVIEW_TRAVELERS_PRECAUTION =
  'details and ensure that the spelling of your name and other details match with your national identity card (NIC) or passport, as these cannot be changed later.';
export const REVIEW_TRAVELERS_NOTE = '*Errors might lead to cancellation penalties.';
export const DELETE_ORDER_CONFIRMATION = 'Are you sure you want to delete this PNR?';
export const DELETE_BOOKING = 'Are you sure you want to delete this booking?';
export const AIRPORT_TRANSFER_NOTE = 'You will receive a call from us soon to confirm your transfer details';
export const HOTELS_RATING_NOTE =
  'This star rating is provided to Sastaticket.pk by the property, and is usually determined by the hotel rating organisation or another third party.';
export const HOTEL_INCLUSIVE_TAXES_NOTE = 'Inclusive of all taxes';
export const REISSUANCE_NOTE =
  'One of our agents will be in touch with you within 5 hours once you confirm the changes you wish to make. Please note, your new booking is subject to availability and you will have to pay the airline’s change fees as well as any difference of fare.';
export const REISSUANCE_MSG =
  'Thank you, we’ve received your request to modify your booking and our customer service team will reach out to you on your registered mobile number within 5 hours. ';
export const HOTEL_BOOKING_ON_REQUEST_CONTENT =
  'Please note that the selected property is currently running at high occupancy levels. However, we will make every effort to ensure that your booking is confirmed after receipt of payment. In the unlikely event that the booking is refused, your amount shall be fully refunded within 24 hours.';
export const PROPERTY_FULLY_BOOKED = 'Property Fully Booked';
export const COVID_NOTE =
  'Effective 1st December 2021, there is a mandatory requirement for complete COVID Vaccination for Domestic and International Air Travel. In order to travel all passengers of age 15 years and above must be fully vaccinated and provide proof of vaccination. ';
export const DELETE_ORDER_ERROR_MSG =
  'Your Booking can not be deleted, please call our Customer Service at (021) 111 172 782 for assistance.';
