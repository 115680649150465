import React, { Component } from 'react';
import FlightApp from 'modules/App/App';
import withErrorBoundary from '../util/HOC/errorBoundary';
import './AppProvider.scss';
import XdComProvider from './xd-com-provider';

// eslint-disable-next-line react/prefer-stateless-function
class AppProvider extends Component {
  render() {
    return (
      // <XdComProvider>
      <FlightApp />
      // </XdComProvider>
    );
  }
}

export default withErrorBoundary(AppProvider);
