import { SearchState } from './searchTypes';
import { RouteType } from '../../../standardTypes';
import { getDefaultLeg } from './searchActionSelectors';

export const searchInitialState: SearchState = {
  query: {
    cabinClass: {
      code: 'Y',
      label: 'Economy',
    },
    flexibility: null,
    legs: [getDefaultLeg()],
    nonStopFlight: false,
    travelerCount: {
      numAdult: 1,
      numChild: 0,
      numInfant: 0,
    },
    routeType: RouteType.ONEWAY,
  },
};
