export interface FilterSelection {
  propertyClass?: Array<number>;
  sort: SortQueryModel;
  rating?: string;
  regionId: Array<number>;
  bookingPolicy: Array<string>;
  paymentType: Array<string>;
  propertyType: Array<string>;
  amenities: Array<number>;
  hotelName?: string;
  propertyId?: number;
  price: QueryPriceRange;
}

export interface FilterModel {
  name: string;
  label: string;
  options: OptionModel[];
  enabled: boolean;
}

export interface OptionModel {
  name: string;
  label: string;
  value: boolean | number;
}

export interface PriceModal {
  name: string;
  label: string;
  enabled: boolean;
  options: PriceRange[];
}

export interface PriceRange {
  name: string;
  label: string;
  value: number;
}

export interface QueryPriceRange {
  min: number;
  max: number;
}

export interface SortQueryModel {
  name: string;
  order: SORT_ORDER;
}

export interface SortModel {
  name: string;
  label?: string;
  value?: SORT_ORDER | null;
}
export interface FilterResponse {
  sort: SortModel[];
  filters: FilterModel[];
}

export enum SORT_ORDER {
  ASC = 'ASC',
  DESC = 'DESC',
}

export const UPDATE_SEARCH_QUERY = 'UPDATE_SEARCH_QUERY';
