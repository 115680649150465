// Libraries
import React, { useState } from 'react';
import humps from 'humps';
import isEmpty from 'lodash.isempty';

//Context
import { SastaWalletContext, SastaWalletContextProps } from './context';

//Actions
import { initiateOtpSession, getUserWalletBalance } from 'modules/Account/accountActions';

//Utilities
import { showToast } from 'shared/Toast/Toast';

import { isFeatureEnabled } from 'util/Helpers';
import { FEATURES, WALLET_USER_ROLES } from 'util/Constants';

interface SastaWalletContextProviderProps {
  children: React.ReactNode;
}

const SastaWalletContextProvider = (props: SastaWalletContextProviderProps) => {
  const { children } = props;
  const [isValidSession, setValidSession] = useState(false);
  const [isSessionInitiated, setInitiateSession] = useState(false);
  const [isFetchingSession, setFetchingSession] = useState(true);
  const [availableBalance, setAvailableBalance] = useState<number>();

  const initiateSession = (isOMS = false) => {
    initiateOtpSession(isOMS)
      .then((response) => {
        setInitiateSession(true);
        setFetchingSession(false);
        showToast({ msg: response.message, type: 'success' });
      })
      .catch((error) => {
        showToast({ msg: error, type: 'error' });
      });
  };

  const getBalance = (isInitiateSession?: boolean) => {
    setFetchingSession(true);
    getUserWalletBalance()
      .then((response) => {
        setFetchingSession(false);
        setAvailableBalance(response.availableCredit);
        setValidSession(true);
      })
      .catch(() => {
        setFetchingSession(false);
        setAvailableBalance(0);
        setValidSession(false);
        if (isInitiateSession) initiateSession();
      });
  };

  const onCancel = () => {
    setInitiateSession(false);
  };

  const updateSessionStates = () => {
    setFetchingSession(true);
    setInitiateSession(false);
  };

  const onReInitiateSession = () => {
    updateSessionStates();
    setValidSession(false);
  };

  const onValidateOtp = () => {
    updateSessionStates();
    getBalance();
  };

  const setSession = () => {
    setFetchingSession(false);
    setInitiateSession(false);
    setValidSession(true);
  };

  const isSastaWalletEnable = (userRole: string[]): boolean => {
    const activeWalletUserRoles = WALLET_USER_ROLES.filter((role) => userRole && userRole.includes(role));
    return !isEmpty(activeWalletUserRoles) && isFeatureEnabled(FEATURES.SASTA_WALLET);
  };

  const value: SastaWalletContextProps = {
    isFetchingSession,
    isSessionInitiated,
    isValidSession,
    availableBalance,
    initiateSession,
    getBalance,
    onCancel,
    onValidateOtp,
    onReInitiateSession,
    isSastaWalletEnable,
    setSession,
  };

  return <SastaWalletContext.Provider value={value}>{children}</SastaWalletContext.Provider>;
};

export default SastaWalletContextProvider;
