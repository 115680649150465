import caseConverter from 'change-case-object';
import fetch from 'isomorphic-fetch';
import { store } from 'store';
import * as accountActionTypes from './accountActionTypes';
import { setInLS, stringEncrypt, camelizeObject, decamelizeObject, getBackendURL } from 'util/Helpers';
import SastaEndpoints from 'util/Endpoints';
import callApi from 'util/apiCaller';
import analyticsEventProvider from 'util/AnalyticsEventProvider';
import { ANALYTICS_EVENTS, BOOKING_TYPE } from 'util/Constants';
import { getSaveProfileEventData } from './accountHelpers';

// Export Actions
export function saveUser(user) {
  return {
    type: accountActionTypes.SAVE_USER,
    payload: user,
  };
}

export function removeUserBookings() {
  return {
    type: accountActionTypes.REMOVE_USER_BOOKINGS,
  };
}

export function removeUserPurchase() {
  return {
    type: accountActionTypes.REMOVE_USER_PURCHASES,
  };
}

export function removeUser() {
  return {
    type: accountActionTypes.REMOVE_USER,
  };
}

export function saveRandomUserBooking(booking) {
  return {
    type: accountActionTypes.SAVE_ORDER_BOOKING,
    payload: booking,
  };
}

export function removeRandomUserBooking() {
  return {
    type: accountActionTypes.REMOVE_ORDER_BOOKING,
  };
}

export function clearRandomUserBooking() {
  return (dispatch) => {
    dispatch(removeRandomUserBooking());
  };
}

export function searchAndSaveBookingByOrderId({ orderId, email }) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return callApi({
        endpoint: `${SastaEndpoints.searchBookingById}/${orderId}`,
        method: 'GET',
        queryParams: { email: email.toLowerCase() },
        disableToast: true,
      })
        .then(({ json }) => {
          const response = caseConverter.camelCase(json.data);
          if (json.success && response) {
            const order = {
              ...response,
              bookings: response.bookings.filter(
                (booking) =>
                  booking.bookingType &&
                  (booking.bookingType === BOOKING_TYPE.FLIGHT ||
                    booking.bookingType === BOOKING_TYPE.HOTEL ||
                    booking.bookingType === BOOKING_TYPE.BUS)
              ),
            };
            dispatch(saveRandomUserBooking(order || []));
            return resolve(order);
          }
        })
        .catch((error) => {
          return reject(error);
        });
    });
  };
}

export function updateProfile(data) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      // Converting snake case for backend compatibility
      const convertedData = data;
      convertedData.contact_detail.street1 = convertedData.contact_detail.street_1;
      delete convertedData.contact_detail.street_1;
      convertedData.contact_detail.street2 = convertedData.contact_detail.street_2;
      delete convertedData.contact_detail.street_2;
      return callApi({
        endpoint: SastaEndpoints.updateProfile,
        method: 'PATCH',
        body: convertedData,
      })
        .then(({ json }) => {
          const apiResponse = json.data;
          analyticsEventProvider.saveProfileEvent({
            event: ANALYTICS_EVENTS.PROFILE.SAVE_PROFILE,
            context: getSaveProfileEventData(apiResponse.user),
          });

          if (json.success && apiResponse) {
            dispatch(saveUser(apiResponse));
            setInLS('user', stringEncrypt(JSON.stringify(json.data)));
            return resolve(apiResponse);
          }
        })
        .catch((e) => {
          return reject(e);
        });
    });
  };
}

export function saveUserBookings(bookings) {
  return {
    type: accountActionTypes.SAVE_USER_BOOKINGS,
    payload: bookings,
  };
}

export function saveUserPurchases(purchases) {
  return {
    type: accountActionTypes.SAVE_USER_PURCHASES,
    payload: purchases,
  };
}

export function fetchUserBookings(queryParams) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return callApi({
        endpoint: SastaEndpoints.userBookingsv4,
        method: 'GET',
        queryParams,
      })
        .then((result) => {
          const json = (result && result.json) || {};
          const response = caseConverter.camelCase(json.data);
          if (json.success && response) {
            if (response.results.length > 0) {
              const filteredOutBookings = response.results.map((order) => {
                return {
                  ...order,
                  bookings: order.bookings.filter(
                    (booking) =>
                      booking.bookingType &&
                      (booking.bookingType === BOOKING_TYPE.FLIGHT ||
                        booking.bookingType === BOOKING_TYPE.HOTEL ||
                        booking.bookingType === BOOKING_TYPE.BUS ||
                        booking.bookingType === BOOKING_TYPE.TOUR)
                  ),
                };
              });
              dispatch(saveUserBookings(filteredOutBookings || []));
            }
            return resolve(response);
          }
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };
}
export function fetchPassengers() {
  return () => {
    return new Promise((resolve, reject) => {
      callApi({
        endpoint: SastaEndpoints.passengers,
        method: 'GET',
      }).then(({ json }) => {
        const response = json.data;
        if (json.success && response) {
          const passengers = response.map((aPassengers) => {
            return {
              ...caseConverter.camelCase(aPassengers.data),
              dob: aPassengers.dob,
              firstName: aPassengers.first_name,
              id: aPassengers.id,
              lastName: aPassengers.last_name,
              nationality: aPassengers.nationality,
              passengerType: aPassengers.passenger_type,
              passportExpiry: aPassengers.passport_expiry,
              passportNumber: aPassengers.passport_number,
              title: aPassengers.title,
            };
          });

          return resolve(passengers);
        }
        return reject(response);
      });
    });
  };
}

export function fetchUserPurchases(data) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return callApi({
        endpoint: SastaEndpoints.userBookingsv4,
        method: 'GET',
        queryParams: data,
      })
        .then((result) => {
          const json = (result && result.json) || {};
          const response = caseConverter.camelCase(json.data);
          if (json.success && response) {
            if (response.results.length > 0) {
              const filteredOutInsurancePurchases = response.results.map((order) => ({
                ...order,
                bookings: order.bookings.filter((booking) => booking.bookingType !== BOOKING_TYPE.INSURANCE),
              }));
              dispatch(saveUserPurchases(filteredOutInsurancePurchases || []));
            }
            return resolve(response);
          }
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };
}

export function requestForgotPassword(data) {
  return () => {
    return new Promise((resolve, reject) => {
      return callApi({
        endpoint: SastaEndpoints.forgotPassword,
        method: 'GET',
        queryParams: data,
      })
        .then(({ json }) => {
          // const response = json.data;
          if (json.success) {
            return resolve(json.message);
          }
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };
}

// params
// mobileNumber: string mobile number
// resent: if user requests OTP again when it expires this flag is true
// isLogin: if OTP requested on login, this flag is true
export function requestGenerateOtp({ mobileNumber, token, resent = false, isLogin = false }) {
  return () => {
    return new Promise((resolve, reject) => {
      const payload = {
        mobile_number: mobileNumber,
        ...(token && { recaptchaToken: { token, platform: 'web' } }),
      };
      return callApi({
        endpoint: SastaEndpoints.generateOtp,
        method: 'post',
        body: payload,
      })
        .then(({ json }) => {
          if (json.success) {
            // currently not triggered on Login as per requirement
            if (!isLogin) {
              analyticsEventProvider.registerOTP({
                event: ANALYTICS_EVENTS.MEMBER_MODULE.REGISTER.VERIFICATION_REGISTER,
                action: !resent
                  ? ANALYTICS_EVENTS.MEMBER_MODULE.OTP_ACTIONS.OTP_REQUESTED
                  : ANALYTICS_EVENTS.MEMBER_MODULE.OTP_ACTIONS.OTP_RESEND,
              });
            }
            return resolve(json);
          }
        })
        .catch((err) => reject(err));
    });
  };
}

export function validatePhoneNumber(mobileNumber) {
  return () => {
    const payload = {
      mobile_number: mobileNumber,
    };
    return callApi({
      endpoint: SastaEndpoints.validatePhoneNumber,
      method: 'post',
      body: payload,
      disableToast: true,
    }).then(({ json }) => {
      if (json.success) {
        return json;
      }
    });
  };
}

export function validateEmail(email) {
  return () => {
    return callApi({
      endpoint: SastaEndpoints.validateEmailAddress,
      method: 'post',
      body: { email },
      disableToast: true,
    }).then(({ json }) => {
      if (json.success) {
        return json;
      }
    });
  };
}

export function updateContactNumber(payload) {
  return () => {
    return new Promise((resolve, reject) => {
      return callApi({
        endpoint: SastaEndpoints.updateContactNumber,
        method: 'PATCH',
        body: payload,
        disableToast: true,
      })
        .then(({ json }) => {
          if (json.success) {
            return resolve(json);
          }
        })
        .catch((err) => reject(err));
    });
  };
}

export function socialLogin(payload) {
  const { provider, accessToken } = payload;
  return () => {
    return new Promise((resolve, reject) => {
      return callApi({
        endpoint: SastaEndpoints.socialSignIn,
        method: 'post',
        body: { provider, access_token: accessToken },
        disableToast: true,
      })
        .then(({ json }) => {
          if (json.success) {
            return resolve(json.data);
          }
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };
}

export function sendVerificationEmail() {
  return () => {
    return callApi({
      endpoint: SastaEndpoints.sendEmail,
      disableToast: true,
    });
  };
}

export function getUserWalletBalance() {
  return callApi({
    endpoint: SastaEndpoints.getWalletBalance,
    disableToast: true,
  })
    .then(({ json }) => {
      if (json.success) return camelizeObject(json.data);
    })
    .catch((error) => {
      throw new Error(error.message);
    });
}

export function initiateOtpSession(isOMS) {
  return callApi({
    endpoint: SastaEndpoints.initiateOtpSession,
    method: 'post',
    ...(isOMS && { body: { medium: 'OMS' } }),
    disableToast: true,
  })
    .then(({ json }) => {
      if (json.success) return json.data;
    })
    .catch((error) => {
      throw new Error(error.message);
    });
}

export function validateOtp(otp) {
  return () => {
    return callApi({
      endpoint: SastaEndpoints.validateOtp,
      method: 'post',
      body: { otp },
      disableToast: true,
    })
      .then(({ json }) => {
        if (json.success) return json.data;
      })
      .catch((error) => {
        throw new Error(error.message);
      });
  };
}

export function getWalletTransactionList() {
  return () => {
    return callApi({
      endpoint: SastaEndpoints.walletTransationList,
      queryParams: { page: 1, page_size: 10 },
      disableToast: true,
    })
      .then(({ json }) => {
        return camelizeObject(json.results);
      })
      .catch((error) => {
        throw new Error(error.message);
      });
  };
}
export function getDepositList() {
  return () => {
    return callApi({
      endpoint: SastaEndpoints.deposit,
      queryParams: { page: 1, page_size: 50 },
      disableToast: true,
    })
      .then(({ json }) => {
        return camelizeObject(json.results);
      })
      .catch((error) => {
        throw new Error(error.message);
      });
  };
}

export function depositWalletTransaction(payload) {
  const getUserState = () => {
    return store && store.getState().account.user;
  };
  const userState = getUserState();
  const decamelizeData = decamelizeObject(payload);
  const formData = new FormData();

  for (let dataKey in decamelizeData) {
    if (dataKey === 'method_payload') {
      formData.append(dataKey, JSON.stringify(decamelizeData[dataKey]));
    } else if (dataKey === 'file') {
      formData.append(dataKey, payload.file);
    } else {
      formData.append(dataKey, decamelizeData[dataKey]);
    }
  }

  const requestParams = {
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: `JWT ${userState.token}`,
    },
    method: 'post',
    body: formData,
  };

  return () => {
    return fetch(`${getBackendURL()}/${SastaEndpoints.deposit}/`, requestParams)
      .then((response) => response.json().then((json) => ({ json, response })))
      .then(({ json, response }) => {
        if (response.status >= 400 || (json.hasOwnProperty('success') && !json.success)) {
          const error = json.message || 'Something went wrong!';
          return Promise.reject({
            message: error,
            status_code: response.status,
          });
        }
        return json.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
}

export const fetchSalesAndTravelRecords = (payload) => {
  return callApi({
    endpoint: SastaEndpoints.getStatmentList,
    method: 'post',
    body: payload,
  })
    .then(({ json }) => {
      if (json.success) return json.data;
    })
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const fetchAndDownloadCSV = (url) => {
  const getUserState = () => {
    return store && store.getState().account.user;
  };
  const getAllHeaders = (headers, token) => {
    return {
      ...headers,
      Authorization: `JWT ${token}`,
    };
  };
  const userState = getUserState();
  let headers = {
    'Access-Control-Allow-Origin': '*',
    'content-type': 'application/json; charset=utf-8',
  };
  headers = getAllHeaders(headers, userState.token);
  const requestParams = {
    headers,
    method: 'GET',
  };
  return fetch(url, requestParams)
    .then((response) => response.text())
    .then((data) => {
      return data;
    });
};
