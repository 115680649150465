import React from 'react';
import { withTheme } from 'styled-components';
import { Box, StyledSystemProps } from 'design/atoms/Box';
import * as FeatherIcon from 'react-feather';
import isNil from 'lodash/isNil';
interface DpFeatherIconProps {
  icon: string;
  size: number;
  color?: string;
  fillColor?: string;
}

interface ImageIconProps {
  icon: string;
  alt?: string;
  width?: string;
  height?: string;
}

const Icon = ({ icon, color, size, fillColor = 'none', ...props }: DpFeatherIconProps) => {
  const IconTag = FeatherIcon[icon];
  return <IconTag color={color} size={size} cursor="pointer" fill={fillColor} {...props} />;
};

const ImageIcon = ({ icon, alt, ...props }: ImageIconProps) => <img src={icon} alt={alt} {...props} />;

export const STIcon = withTheme(({ icon, size, color, smSize, theme, ...props }) => {
  if (smSize === null) {
    smSize = size;
  }
  return (
    <>
      <Box as="span" display={['none', 'inline-block']} x-type="desktop-icon" {...props}>
        <Icon icon={icon} color={theme.colors[color]} size={theme.icons[size]} />
      </Box>
      <Box as="span" display={['inline-block', 'none']} x-type="mobile-icon" {...props}>
        <Icon icon={icon} color={theme.colors[color]} size={theme.icons[size]} />
      </Box>
    </>
  );
});

export const STFilledIcon = withTheme(({ icon, size, color, fillColor, theme, ...props }) => {
  return (
    <>
      <Box as="span" display={['none', 'inline-block']} x-type="desktop-icon" {...props}>
        <Icon
          icon={icon}
          color={theme.colors[color]}
          size={isNil(theme.icons[size]) ? size : theme.icons[size]}
          fillColor={theme.colors[color]}
        />
      </Box>
      <Box as="span" display={['inline-block', 'none']} x-type="mobile-icon" {...props}>
        <Icon
          icon={icon}
          color={theme.colors[color]}
          size={isNil(theme.icons[size]) ? size : theme.icons[size]}
          fillColor={theme.colors[fillColor]}
        />
      </Box>
    </>
  );
});

export const STImageIcon: React.FC<ImageIconProps & StyledSystemProps> = ({ icon, alt, width, height, ...props }) => {
  return (
    <>
      <Box as="span" display={['none', 'inline-block']} x-type="desktop-icon" {...props}>
        <ImageIcon icon={icon} alt={alt} width={width} height={height} />
      </Box>
      <Box as="span" display={['inline-block', 'none']} x-type="mobile-icon" {...props}>
        <ImageIcon icon={icon} alt={alt} width={width} height={height} />
      </Box>
    </>
  );
};

STIcon.defaultProps = {
  size: 'sm',
};
