import { V3AuthenticatedUserInfo } from './types';
import caseConverter from 'change-case-object';
import { checkIfCookiesAreEnabled, getFromLS, stringDecrypt } from '../../util/Helpers';
import { v2ToV3RouteMap } from '../../app-provider/constants';

export function mapProdAuthInfoToV3AuthInfo(prodAuthInfo: any): V3AuthenticatedUserInfo {
  const v3AuthInfo: V3AuthenticatedUserInfo = {
    token: prodAuthInfo.token,
    refreshToken: prodAuthInfo.refreshToken,
    role: prodAuthInfo.role,
    user: caseConverter.camelCase(prodAuthInfo.user),
    isUserCreated: prodAuthInfo.isUserCreated,
    xAuthStrategy: prodAuthInfo.xAuthStrategy,
    // case converter is also converting array strings - creating issues with .role key
    // so we are only applying it on .user key
  };

  return v3AuthInfo;
}

export function getV3AuthInfo(): V3AuthenticatedUserInfo {
  try {
    // #COPIED from modules/App/App.js
    if (!(checkIfCookiesAreEnabled() && typeof localStorage !== 'undefined')) {
      return;
    }

    let user = stringDecrypt(getFromLS('user') || '');
    if (!user) {
      return;
    }

    user = JSON.parse(user);
    return mapProdAuthInfoToV3AuthInfo(user);
  } catch (e) {
    // not able to extract user info
    return;
  }
}

interface RouterLocation {
  pathname: string;
  search: string;
}

export function isV3Route(location: RouterLocation): boolean {
  const path = `${location.pathname}${location.search}`;
  // if home page
  if (location.pathname === '/') {
    return true;
  }

  const v2RoutesWhichAreAvailableOnV3 = Object.keys(v2ToV3RouteMap);
  const v3Routes = Object.values(v2ToV3RouteMap);

  // NOTE: there is no usage of this function `isV3Route` so, didn't remove hotel from here
  const hotelV3Routes = [
    '/stays/booking',
    '/stays/booking/payment',
    '/stays/booking/confirmation',
    '/stays/bookings/details',
  ];
  const busV3Routes = [
    '/bus/',
    '/bus/search',
    '/bus/booking',
    '/bus/booking/payment',
    '/bus/booking/confirmation',
    '/bus/bookings/details',
  ];

  return [...v2RoutesWhichAreAvailableOnV3, ...v3Routes, ...hotelV3Routes, ...busV3Routes].some(
    (v3Path) =>
      path.includes(v3Path) &&
      (!path.includes('/stays/') || hotelV3Routes.some((hotelPath) => path.includes(hotelPath))) &&
      (!path.includes('/bus/') || busV3Routes.some((busPath) => path.includes(busPath)))
  );
}

export function isFlightSearchPageRoute(location: RouterLocation): boolean {
  const path = `${location.pathname}${location.search}`;
  return path.includes('/air/search');
}

export function isLandingPageRoute(location: RouterLocation): boolean {
  return location.pathname === '/' || location.pathname === '';
}
