import { ROUTE_PREFIX, HOTEL_BOOKING_PREFIX, BUS_PREFIX } from 'util/Constants';

const sastaFlightEndpoints = {
  getAllStops: `v1${ROUTE_PREFIX}/get_all_stops`,
  //getAvailableFlights: `v1${ROUTE_PREFIX}/get_available_flights`,
  getAvailableFlights: `v3/flights`,
  getInsurancePackages: `v1/insurance/packages`,
  //viewBooking: `v2/orders/detail`,
  initiateBooking: `v3${ROUTE_PREFIX}/bookings/initiate`,
  validateWebReference: `v4${ROUTE_PREFIX}/bookings/validate_web_reference`,
  makeBooking: `v4${ROUTE_PREFIX}/bookings/book`,
  searchBookingById: `v3/guest_orders`,
  fareRules: `v1${ROUTE_PREFIX}/airline_fare_rules`,
  viewBooking: `v3/orders`,
  viewBookingV4: `v4/orders`,
  makePayment: 'v3/payments/transactions',
  addPayment: 'v1/sasta_payments/add_payment',
  getDiscount: 'v1/vouchers/validate',
  paymentStatus: 'v1/sasta_payments/status',
  cybersourceInitiate: 'v1/sasta_payments/cybersource/initiate',
  cybersourceValidate: 'v1/sasta_payments/cybersource/validate',
  fees: 'v1/sasta_payments/get_payment_methods',
  paymentProviders: 'v2/payments/providers',
  fareRules_v3: `v3${ROUTE_PREFIX}/airline_fare_rules`,
  hblOtp: 'v3/payments/hblkonnect/generate_otp',
  emiEligibilityCheck: 'v1/payments/emi/validate',
};

const accountEndpoints = {
  socialSignIn: 'v3/users/social_login',
  socialSignUp: 'v3/users/social_sign_up',
  userSignUp: 'v3/users/sign_up',
  userLogin: 'v4/users/login',
  userLogout: 'v3/users/logout',
  forgotPassword: 'v3/users/forgot_password',
  resetPassword: 'v3/users/reset_password',
  changePassword: 'v3/users/change_password',
  profile: 'v1/users/profile',
  updateProfile: 'v1/users/profile',
  userBookings: 'v3/orders',
  userBookingsv4: 'v4/orders',
  passengers: 'v1/air/passengers/passengers_of_user',
  refreshToken: 'v4/users/token/refresh',
  generateOtp: 'v3/users/generate_otp',
  updateContactNumber: 'v3/users/contact_number',
  updateAirportTransfer: 'v1/transfers/update_transfer_leg',
  updateDriverDetail: 'v1/transfers/update_driver_details',
  validatePhoneNumber: 'v3/users/validate_mobile_number',
  validateEmailAddress: 'v3/users/validate_email_address',
  sendEmail: 'v3/users/update_email',
  refundBankDetails: (id) => `v4/transactions/${id}/refunds`,
};

const adminEndpoints = {
  cmsOrderList_v5: 'v5/cmsorders',
  cmsOrderList_v4: 'v4/cmsorders',
  cmsOrderList: 'v3/cmsorders',
  cmsOrderUpdate_v3: 'v3/orders',
  cmsOrderActions: {
    sendETicket: 'send_e_ticket_email',
    sendPaymentReceipt: 'send_receipt_email',
    sendConfirmationEmail: 'send_order_confirmation_email',
    addCustomProduct: 'custom-product',
  },
  cmsOrderUpdate: 'v2/cmsorders',
  cmsExportOrders: 'v4/export_orders_bulk',
  paymentProviders: 'v2/payments/providers',
  paymentProvidersV3: 'v3/payments/providers',
  updateBookingContact: `v4/update_booking_contact`,
  addNote: 'v1/notes',
  cmsAddPayment: 'v2/cmsorders/create_transaction',
  customBooking: 'v1/air/bookings/create_custom_booking',
  updatePaymentStatus: 'v3/cmsorders/update_transaction',
  deletePaymentTransaction: 'v2/cmsorders/delete_transaction',
  custom: {
    addProducts: 'v2/custom/add_product',
  },
  fetchAffiliates: 'v3/cmsorders/get_all_affiliates',
  readBooking: 'v4/cmsorders/read_booking',
};

const orderDetailEndPoints = {
  getAllAgents: 'v1/users/get_all_agents',
};

const sastaHotelEndpoints = {
  getAllRegions: 'v1/hotels/get_all_regions',
  getHotelDetails: 'v1/hotels/detail',
  getRooms: 'v1/hotels/rooms',
  getAvailableHotels: 'v1/hotels/properties',
  hotelInitiateBooking: `v1${HOTEL_BOOKING_PREFIX}/initiate`,
  getRoomCancellationPolicies: `v1/hotels/get_all_policies`,
  hotelMakeBooking: `v1${HOTEL_BOOKING_PREFIX}/book`,
  hotelValidateWebReference: `v1${HOTEL_BOOKING_PREFIX}/validate_web_reference`,
  getPropertiesSuggestions: 'v1/hotels/property_names',
};

const sastaTourEndpoints = {
  getAvailableTours: 'v1/tours',
  initiateUmrahBooking: 'v1/tours/bookings/initiate',
  makeUmrahBooking: 'v1/tours/bookings/book',
};

const sastaBusEndpoints = {
  getAvailableBuses: `v2${BUS_PREFIX}search`,
  getLocations: `v2${BUS_PREFIX}cities`,
  getAvailableBusSeats: `v3${BUS_PREFIX}seats`,
  initiateBusBooking: `v3${BUS_PREFIX}bookings/initiate`,
  busValidateWebReference: `v2${BUS_PREFIX}bookings/validate_web_reference`,
  makeBusBooking: `v2${BUS_PREFIX}bookings/book`,
};

const sastaWalletEndpoints = {
  getWalletBalance: 'v4/payments/wallet/get_balance',
  initiateOtpSession: 'v4/payments/wallet/initiate_session',
  validateOtp: 'v4/payments/wallet/validate_session',
  revokeSession: 'v4/payments/wallet/revoke_session',
  walletTransationList: 'v4/payments/wallet/transactions',
  deposit: 'v4/payments/wallet/member/top_up',
};

const partnerStatementEndpoints = {
  getStatmentList: 'v4/partner_statement',
  downloadCSV: 'v4/partner_statement_csv',
};

const NiftEndpoints = {
  getNiftBanksList: 'v4/payments/nift/banks',
};

const refundEndpoints = {
  getTransactionsRefunds: (uuid) => `v4/cmsorders/${uuid}/refunds`,
  createRefundTransaction: (id) => `v4/transactions/${id}/refunds`,
};

export default {
  ...sastaFlightEndpoints,
  ...accountEndpoints,
  ...orderDetailEndPoints,
  ...adminEndpoints,
  ...sastaHotelEndpoints,
  ...sastaTourEndpoints,
  ...sastaBusEndpoints,
  ...sastaWalletEndpoints,
  ...partnerStatementEndpoints,
  ...NiftEndpoints,
  ...refundEndpoints,
};
