import styled, { css } from 'styled-components';
import { Box } from 'design/atoms/Box';
import { StyledSystemProps } from '../../../atoms/Box';

export const NavItemsGroupStyled = styled(Box)(
  (props: StyledSystemProps) => css`
    display: flex;
    justify-content: ${props.justifyContent ? props.justifyContent : 'none'};
    width: ${props.width ? props.width : '100%'};
    margin-left: ${props.align === 'left' ? 'initial' : 'auto'};
    @media screen and (max-width: 767px) {
      margin-left: 0;
      flex-direction: column;
    }
  `
);
