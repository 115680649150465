import React, { useState } from 'react';
import { STNavItem } from 'design/atoms/NavItem/NavItem';
import { STFlex } from 'design/atoms/Container';
import { Box } from 'design/atoms/Box';
import { STLink, STLabel, STH3, STBodyText } from 'design/atoms/Text';
import { logoutUser } from 'auth';
import { STPopover } from 'design/molecules/Popover';
import { UserDetail } from 'modules/Account/AccountTypes';
import { STHR } from 'design/atoms/HR';
import styled from 'styled-components';
import { useSastaWallet } from 'modules/Account/SastaWallet/Context/useSastaWallet';
import { XDCEventsToSend, xdPWCommunicator } from 'modules/V3App/xd-communicator';

export interface LoggedInPopoverProps {
  user: UserDetail;
  userRole: any[];
}

const LinkContainer = styled(STFlex)`
  padding: ${({ theme }) => `${theme.space[3]}px`};
  &:first-child {
    padding-top: ${({ theme }) => `${theme.space[2]}px`};
  }
  &:last-child {
    padding-bottom: ${({ theme }) => `${theme.space[2]}px`};
  }
  &:hover {
    background: ${({ theme }) => theme.colors.secondaryHover};
  }
  label {
    cursor: pointer;
  }
`;

export const LoggedInPopover: React.FC<LoggedInPopoverProps> = ({ user, userRole }) => {
  let { firstName, lastName, id } = user;
  const [icon, setIcon] = useState('ChevronDown');
  const { isSastaWalletEnable } = useSastaWallet();
  const closeModal = (togglePopover) => {
    togglePopover(false);
    setIcon('ChevronDown');
  };

  const getName = () => {
    if (firstName && lastName) {
      return `${firstName} ${lastName}`;
    }
    return `User ${user.id}`;
  };

  const showOnV3 = (path: string, togglePopover: Function) => {
    closeModal(togglePopover);
    const eventData = {
      name: XDCEventsToSend.V3RouteChanged,
      data: { pathname: path, search: '' },
    };
    xdPWCommunicator.send(eventData);
  };

  return (
    <STPopover
      contentPosition="bottom"
      outsideClickEvent={closeModal}
      trigger={
        <STLink
          variant="blueRegularClean"
          as="a"
          onClick={() => {
            if (icon == 'ChevronDown') setIcon('ChevronUp');
            else setIcon('ChevronDown');
          }}
        >
          <STNavItem active={false} label="My Account" pr={[0, 3]} pl={3} icon={icon} />
        </STLink>
      }
      render={(togglePopover) => (
        <Box bg="white" borderLeft={5} borderRight={5} borderBottom={5}>
          <Box display="block" minWidth="350px">
            <STFlex flexDirection="column" p={3}>
              <Box mb={1}>
                <STH3 m={0}>{getName()}</STH3>
              </Box>
              {user.email && (
                <Box>
                  <STLabel variant="lightGreyRegular" fontSize={0}>
                    {user.email}
                  </STLabel>
                </Box>
              )}
            </STFlex>
            <STHR />
            <STFlex flexDirection="column">
              <LinkContainer>
                <STLabel variant="greyRegular" onClick={() => logoutUser()}>
                  Sign Out
                </STLabel>
              </LinkContainer>
            </STFlex>
          </Box>
        </Box>
      )}
    />
  );
};
