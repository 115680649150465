import moment from 'moment-timezone';

import { LS_KEYS } from '../../../util/Constants';
import { getFromLS, stringDecrypt, formatDateTime } from '../../../util/Helpers';
import { QueryLeg } from './searchTypes';

export const getQueryFromLS = () => {
  const lastSearch = getFromLS(LS_KEYS.LAST_SEARCH);
  const query = lastSearch && JSON.parse(stringDecrypt(lastSearch));
  //todo_: validate query > if validation failed empty LS
  if (query && isQueryValid(query))
    return {
      flexibility: query.flexibility,
      nonStopFlight: query.nonStopFlight,
      cabinClass: query.cabinClass,
      travelerCount: query.travelerCount,
      routeType: query.routeType,
      //todo_: legs need to be re-implemement as leg.map for all legs => when we refactor our frontend to use legs only
      legs: query.legs.map((leg: QueryLeg) => {
        return {
          departureDate: moment(leg.departureDate).isBefore(moment(), 'day')
            ? formatDateTime(moment()).date
            : formatDateTime(moment(leg.departureDate)).date,
          origin: leg.origin,
          destination: leg.destination,
        };
      }),
    };
  else {
    return null;
  }
};

export const getDefaultLeg = (date?: any, previousLegDestination?: any) => ({
  departureDate: formatDateTime(moment(date).add(1, 'd')).date,
  origin: previousLegDestination || null,
  destination: null,
});

export const isQueryValid = (query: any) => {
  const mandatoryProperties = ['cabinClass', 'routeType', 'travelerCount', 'legs'];
  let isValid = mandatoryProperties.every((property) => query.hasOwnProperty(property));
  if (isValid) {
    isValid =
      areLegsValid(query.legs) &&
      query.travelerCount.numAdult >= 1 &&
      query.cabinClass.hasOwnProperty('code') &&
      query.cabinClass.hasOwnProperty('label');
  }
  return isValid;
};

const datePassedBy = (date: string, comparedDate = moment()) => moment(date).isBefore(comparedDate, 'day');
const areLegsValid = (legs: QueryLeg[]): any => {
  if (typeof legs === 'object' && legs.length > 0) {
    return legs.every((leg, index, legs) => {
      let legsValid = false;
      if (leg.origin !== null && leg.destination !== null && !datePassedBy(leg.departureDate)) legsValid = true;
      if (index > 0 && datePassedBy(leg.departureDate, moment(legs[index - 1].departureDate))) legsValid = false;
      return legsValid;
    });
  }
  return null;
};
