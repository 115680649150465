import { HotelSearchQueryState } from './hotelSearchQueryTypes';
import { SearchQueryActionType } from './hotelSearchQueryActionTypes';
import * as searchQueryAction from './hotelSearchTypes';
import initialState from './hotelSearchQueryInitialState';

export default function hotelSearchQuery(state = initialState, action: SearchQueryActionType): HotelSearchQueryState {
  switch (action.type) {
    case searchQueryAction.UPDATE_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: action.searchQuery,
      };
    default:
      return state;
  }
}
