import { BusBookingAction } from './busBookingActionTypes';
import * as bookingActionTypes from '../../../Booking/bookingActionTypes';
import { BookingSteps } from 'modules/Booking/BookingStore/types';
import { BusBookingState } from './BusBookingTypes';

const initialState: BusBookingState = {
  bookings: [],
  currentStep: BookingSteps.BOOKING,
};

export default function umrahBookingReducer(state = initialState, action: BusBookingAction): BusBookingState {
  switch (action.type) {
    case bookingActionTypes.BUS_BOOKING_SAVE_:
      return {
        ...state,
        bookings: action.bookings,
      };
    case bookingActionTypes.BUS_BOOKING_UPDATE:
      return {
        ...state,
        bookings: action.bookings,
      };
    case bookingActionTypes.BOOKING_CLEAR_:
      return {
        ...state,
        bookings: [],
      };
    default:
      return state;
  }
}
