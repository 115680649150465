/**
 * Root Reducer
 */
import { combineReducers } from 'redux';
// Import Reducers
import app from 'modules/App/appReducer';
import account from 'modules/Account/accountReducers';
import adminReport from 'modules/AdminPanel/adminReducers';
import order from 'modules/Order/orderReducers';
import { loadingBarReducer } from 'react-redux-loading-bar';
import { reducer as formReducer } from 'redux-form';
import bookingReducer from './modules/Booking/BookingStore/bookingReducers';
import hotelBookingReducer from './modules/Hotels/Booking/BookingStore/hotelBookingReducer';
import umrahBookingReducer from './modules/Umrah/Booking/BookingStore/umrahBookingReducer';
import TourBookingReducer from './modules/Tours/Booking/BookingStore/TourBookingReducer';
import busBookingReducer from './modules/Bus/Booking/BookingStore/busBookingReducer';
import { queryReducer } from 'modules/Search/SearchStore/searchReducer';
import resultsReducer from './modules/SearchResults/ResultsStore/resultReducer';
import hotelSearchQuery from './modules/Hotels/Search/HotelSearchStore/hotelSearchQueryReducer';
import offerDetailsReducer from './modules/Hotels/OfferDetails/OfferDetailsStore/offerDetailsReducer';
import { hotelResultsReducer } from 'modules/Hotels/HotelResults/HotelResultsStore/hotelResultsReducer';

// Combine all reducers into one root reducer
export default combineReducers({
  account,
  adminReport,
  app,
  order,
  form: formReducer,
  loadingBar: loadingBarReducer,
  search: queryReducer,
  bookingDetails: bookingReducer,
  busBookingDetails: busBookingReducer,
  hotelBookingDetails: hotelBookingReducer,
  umrahBookingDetails: umrahBookingReducer,
  tourBookingDetails: TourBookingReducer,
  results: resultsReducer,
  hotelSearchQuery: hotelSearchQuery,
  offerDetails: offerDetailsReducer,
  hotels: hotelResultsReducer,
});
