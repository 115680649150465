/* eslint-disable */
// Eslint is disabled since this file is no longer being used.
import ReactFbPixel from 'react-facebook-pixel';
// import { FB_PIXEL_ANALYTICS_EVENTS } from 'util/Constants';

class FacebookPixelService {
  init() {
    ReactFbPixel.init('1779404062280723');
  }

  trackPage() {
    // Disabled Facebook Pixel Events
    // ReactFbPixel.pageView();
  }

  trackEvent({ category, context = {} }) {
    // Disabled Facebook Pixel events
    // ReactFbPixel.track(FB_PIXEL_ANALYTICS_EVENTS.EVENT_NAMES[category], context);
  }
}

const fbp = new FacebookPixelService();

export default fbp;
