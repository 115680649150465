import React from 'react';

import ErrorBoundary from 'shared/ErrorBoundary/ErrorBoundary';
import errorMsgs from 'util/ErrorMsgs';

const withErrorBoundary = (ComponentToRender) => {
  return (props) => (
    <ErrorBoundary options={{ text: errorMsgs.PAGE_DISPLAY_ERROR, styleClasses: 'page-failed' }}>
      <ComponentToRender {...props} />
    </ErrorBoundary>
  );
};

export default withErrorBoundary;
