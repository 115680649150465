import { createIntl, createIntlCache, RawIntlProvider } from 'react-intl';
import messages from './en_us.json';

// This is optional but highly recommended
// since it prevents memory leak
const cache = createIntlCache();

const language = navigator.language;

export const i18n = createIntl(
  {
    locale: language,
    messages,
  },
  cache
);

export const _ = (id) => i18n.formatMessage({ id });

export const IntlProvider = RawIntlProvider;
