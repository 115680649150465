import { HotelSearchQueryState } from './hotelSearchQueryTypes';
import { formatDateForSelector, formatDateForURL } from 'util/Helpers';

import moment from 'moment-timezone';
import { SORT_ORDER } from './hotelSearchTypes';

const initialState: HotelSearchQueryState = {
  searchQuery: {
    destination: undefined,
    checkin: formatDateForURL(formatDateForSelector(moment()).date),
    checkout: formatDateForURL(formatDateForSelector(moment().add(1, 'days')).date),
    roomsOccupancy: [
      {
        adults: 1,
        /**
         * Due to empty array the hotel search api is breaking on city wise hotel search.
         * Commenting it for now,
         */
        // children: [],
      },
    ],
    filters: {
      sort: {
        name: 'featured',
        order: SORT_ORDER.DESC,
      },
      regionId: [],
      bookingPolicy: [],
      paymentType: [],
      propertyClass: [],
      propertyType: [],
      amenities: [],
      price: {
        min: 0,
        max: 1000,
      },
    },
  },
};

export default initialState;
