import React from 'react';
import MediaQuery from 'react-media';
import { VIEW_PORTS } from 'util/Constants';
import { STFlex } from 'design/atoms/Container';
import { STLabel } from 'design/atoms/Text';
import { Box } from 'design/atoms/Box';
import facebook from '../../../../static/images/social/facebook.svg';
import twitter from '../../../../static/images/social/twitter.svg';
import instagram from '../../../../static/images/social/instagram.svg';
import playstore from '../../../../static/images/social/playstore.svg';

const BottomFooter = () => {
  const year = new Date().getFullYear();
  const socialIcons = [
    {
      img: facebook,
      alt: 'facebook',
      link: 'https://www.facebook.com/sastaticket.pk',
    },
    {
      img: twitter,
      alt: 'twitter',
      link: 'https://twitter.com/sastaticketpk',
    },
    {
      img: instagram,
      alt: 'instagram',
      link: 'https://www.instagram.com/sastaticket.pk/',
    },
    {
      img: playstore,
      alt: 'playstore',
      link: 'https://play.google.com/store/apps/details?id=com.pk.sastaticket',
    },
  ];
  return (
    <STFlex maxWidth="1270px" margin="auto" justifyContent="center" width={1}>
      <STFlex justifyContent="center" px={[5, 0]} py={[2, 3]}>
        <STFlex flexDirection="column" textAlign="center">
          <STLabel variant="blackRegular" fontSize={0}>
            &copy; Copyright {year} Sastaticket Private Limited. All rights reserved. &nbsp;
          </STLabel>
          <MediaQuery query={VIEW_PORTS.MOBILE}>
            {(matches) =>
              matches ? (
                <STFlex pt={2} justifyContent="center">
                  <STLabel variant="blackBold" fontSize={0} pr={2}>
                    Follow us
                  </STLabel>
                  {socialIcons.map((icon) => (
                    <Box flexWrap="wrap" pr={2}>
                      <a href={icon.link}>
                        <img src={icon.img} alt={icon.alt} />
                      </a>
                    </Box>
                  ))}
                </STFlex>
              ) : null
            }
          </MediaQuery>
        </STFlex>
      </STFlex>
    </STFlex>
  );
};

export default BottomFooter;
