import { XDCEventsToSend, xdPWCommunicator } from 'modules/V3App/xd-communicator';
import TagManager from 'react-gtm-module';
import { ENHANCED_ECOMMERCE_TYPES } from 'util/Constants';

class GoogleTagManagerService {
  constructor() {
    this.firedPath = null;
  }
  init() {
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GTM_TRACKING_ID,
      auth: process.env.REACT_APP_GTM_AUTH,
      preview: process.env.REACT_APP_GTM_ENV,
    };

    TagManager.initialize(tagManagerArgs);
  }

  getClientID() {
    const cookies = document.cookie && document.cookie.split(';');
    const gaClientId = cookies.filter((cookie) => cookie.includes('_gid'));
    if (gaClientId.length) {
      return gaClientId[0].trim();
    }
    return null;
  }

  removeEmpty(data) {
    const clonedData = { ...data };
    for (let key in clonedData) {
      if (data[key] === null || data[key] === undefined) {
        delete clonedData[key];
      }
    }
    return clonedData;
  }

  triggerStandardEvent(eventObject) {
    const eventData = this.removeEmpty(eventObject);
    const gtmEventForParentWindow = {
      name: XDCEventsToSend.GTMEvent,
      data: eventData,
    };
    xdPWCommunicator.send(gtmEventForParentWindow);
  }

  trackPageView() {
    const location = window.location.pathname;
    if (this.firedPath !== location) {
      this.firedPath = location;
      const eventData = {
        event: 'pageview',
        page: {
          path: location,
          title: document.title,
        },
      };
      const gtmEventForParentWindow = {
        name: XDCEventsToSend.GTMEvent,
        data: eventData,
      };
      xdPWCommunicator.send(gtmEventForParentWindow);
    }
  }

  addCustomDimensions(dimensionsObject) {
    Object.keys(dimensionsObject).map((key) => {
      const dimensionValue = dimensionsObject[key];
      const gtmEventForParentWindow = {
        name: XDCEventsToSend.GTMEvent,
        data: { [key]: dimensionValue },
      };
      xdPWCommunicator.send(gtmEventForParentWindow);
    });
  }

  eeProductImpression(impressions) {
    this.addContentGroup({ cat1: 'web', cat2: 'flights', cat3: 'impressions' });
    const eventData = {
      event: ENHANCED_ECOMMERCE_TYPES.PRODUCT_IMPRESSION,
      ecommerce: {
        currencyCode: 'PKR',
        impressions,
      },
    };
    const gtmEventForParentWindow = {
      name: XDCEventsToSend.GTMEvent,
      data: eventData,
    };
    xdPWCommunicator.send(gtmEventForParentWindow);
  }

  eeClickProduct(products, actionField) {
    this.addContentGroup({ cat1: 'web', cat2: 'flights', cat3: 'offerClick' });
    const eventData = {
      event: ENHANCED_ECOMMERCE_TYPES.PRODUCT_CLICK,
      ecommerce: {
        [ENHANCED_ECOMMERCE_TYPES.CLICK]: {
          actionField,
          products,
        },
      },
    };
    const gtmEventForParentWindow = {
      name: XDCEventsToSend.GTMEvent,
      data: eventData,
    };
    xdPWCommunicator.send(gtmEventForParentWindow);
  }

  eeViewProduct(product, context) {
    this.addContentGroup({ cat1: 'web', cat2: 'flights', cat3: 'offerdetail' });
    const eventData = {
      flight_destid: product.id,
      event: ENHANCED_ECOMMERCE_TYPES.DETAIL,
      ecommerce: {
        detail: {
          actionField: { list: 'Flights' },
          products: [product],
        },
      },
      context,
    };
    const gtmEventForParentWindow = {
      name: XDCEventsToSend.GTMEvent,
      data: eventData,
    };
    xdPWCommunicator.send(gtmEventForParentWindow);
  }

  eeAddToCart(product) {
    this.addContentGroup({ cat1: 'web', cat2: 'flights', cat3: 'cart' });
    const eventData = {
      flight_destid: product.id,
      event: ENHANCED_ECOMMERCE_TYPES.ADD_TO_CART,
      ecommerce: {
        currencyCode: 'PKR',
        [ENHANCED_ECOMMERCE_TYPES.ADD]: {
          products: [product],
        },
      },
    };
    const gtmEventForParentWindow = {
      name: XDCEventsToSend.GTMEvent,
      data: eventData,
    };
    xdPWCommunicator.send(gtmEventForParentWindow);
  }

  eeTransactionEvent(actionField, product, extraDimensions, context) {
    this.addContentGroup({ cat1: 'web', cat2: 'flights', cat3: 'purchase' });
    const eventData = {
      flight_destid: product.id,
      ...extraDimensions,
      event: 'purchase',
      ecommerce: {
        purchase: {
          actionField,
          products: [product],
        },
      },
      context,
    };
    const gtmEventForParentWindow = {
      name: XDCEventsToSend.GTMEvent,
      data: eventData,
    };
    xdPWCommunicator.send(gtmEventForParentWindow);
  }

  eeCheckout(checkout, product, extraDimensions, context) {
    const eventData = {
      event: 'checkout',
      flight_destid: product.id,
      ...extraDimensions,
      ecommerce: {
        checkout: {
          actionField: checkout,
          products: [product],
          currencyCode: 'PKR',
        },
      },
      context,
    };
    const gtmEventForParentWindow = {
      name: XDCEventsToSend.GTMEvent,
      data: eventData,
    };
    xdPWCommunicator.send(gtmEventForParentWindow);
  }

  eeCheckoutOption(checkout) {
    const eventData = {
      event: 'checkoutOption',
      ecommerce: {
        checkout_option: {
          actionField: checkout,
        },
      },
    };
    const gtmEventForParentWindow = {
      name: XDCEventsToSend.GTMEvent,
      data: eventData,
    };
    xdPWCommunicator.send(gtmEventForParentWindow);
  }

  addContentGroup({ cat1, cat2, cat3, cat4 = '', cat5 = '' }) {
    const eventData = {
      contentCategory1: cat1,
      contentCategory2: cat2,
      contentCategory3: cat3,
      ...(cat4.length && { contentCategory4: cat4 }),
      ...(cat5.length && { contentCategory5: cat5 }),
    };
    const gtmEventForParentWindow = {
      name: XDCEventsToSend.GTMEvent,
      data: eventData,
    };
    xdPWCommunicator.send(gtmEventForParentWindow);
  }

  clickFareCalendar(departureDate) {
    const eventData = {
      category: 'searchResults',
      event: 'clickfarecalendar',
      label: departureDate,
    };
    const gtmEventForParentWindow = {
      name: XDCEventsToSend.GTMEvent,
      data: eventData,
    };
    xdPWCommunicator.send(gtmEventForParentWindow);
  }

  trackAirportTransfer(context) {
    const eventData = {
      category: 'bookingReview',
      event: 'airporttransfer',
      context,
    };
    const gtmEventForParentWindow = {
      name: XDCEventsToSend.GTMEvent,
      data: eventData,
    };
    xdPWCommunicator.send(gtmEventForParentWindow);
  }

  removeAirportTransfer(context) {
    const eventData = {
      category: 'bookingReview',
      event: 'airporttransferremoval',
      context,
    };
    const gtmEventForParentWindow = {
      name: XDCEventsToSend.GTMEvent,
      data: eventData,
    };
    xdPWCommunicator.send(gtmEventForParentWindow);
  }

  triggerTourEvent(event, category, context) {
    const eventData = {
      category,
      event,
      context,
    };
    const gtmEventForParentWindow = {
      name: XDCEventsToSend.GTMEvent,
      data: eventData,
    };
    xdPWCommunicator.send(gtmEventForParentWindow);
  }

  triggerWebLoginEvent(userDetails) {
    const eventData = {
      event: 'webUserLogin',
      ...userDetails,
    };
    const gtmEventForParentWindow = {
      name: XDCEventsToSend.GTMEvent,
      data: eventData,
    };
    xdPWCommunicator.send(gtmEventForParentWindow);
  }
}

const gtm = new GoogleTagManagerService();

export default gtm;
