import React from 'react';

import './VerificationBanner.css';

interface VerificationBannerProps {
  displayText: string;
}

const VerificationBanner = ({ displayText }: VerificationBannerProps) => {
  return <div className="verification-banner">{displayText}</div>;
};

export default VerificationBanner;
