// @ts-nocheck
import React from 'react';
import { CollapseStyled, Overlay, CloseButtonContainer } from './NavCollapse.style';
import { STIcon } from 'design/atoms/Icon';

interface NavCollapseProps {
  children: React.ReactNode | React.ReactNode[];
  isOpen: boolean;
  onClose: Function;
}

export const STNavCollapse = ({ children, isOpen, onClose }: NavCollapseProps) => {
  return (
    <>
      {isOpen && <Overlay />}
      <CollapseStyled data-cy="nav-collapse" isOpen={isOpen}>
        {isOpen && (
          <CloseButtonContainer pb={2} pt={3} pl={2}>
            <STIcon color={'primary'} icon={'X'} size={'xs'} onClick={onClose} />
          </CloseButtonContainer>
        )}
        {children}
      </CollapseStyled>
    </>
  );
};
