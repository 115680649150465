import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';
import Loadable from 'react-loadable';
import SastaLoader from 'shared/SastaLoader/SastaLoader';
import _ from 'lodash';
import {
  ACCOUNT_ROUTE_PREFIX,
  ROUTE_PREFIX,
  HOTELS_PREFIX,
  ACCOUNT_HOTEL_PREFIX,
  BUS_PREFIX,
  TOUR_PREFIX,
  ACCOUNT_BUS_PREFIX,
  ACCOUNT_TOUR_PREFIX,
  HOTEL_BOOKING_ROUTE_PREFIX,
} from 'util/Constants';
import { V2Routes } from 'v2/routes';

require('@babel/polyfill');

/**
 * Apply LazyLoading to Component. Passing renderLoader false will skip the loader
 * @param {object} options {loader: () => { }}
 * @param {boolean} renderLoader default true
 */

export const lazyLoadModule = (options, renderLoader = true) => {
  return Loadable(
    Object.assign(
      {
        loading() {
          return renderLoader ? <SastaLoader isLoading /> : null;
        },
      },
      options
    )
  );
};

export default (
  <Switch>
    <Route
      path="/"
      exact
      component={lazyLoadModule({
        loader: () => import('modules/Account/LoginView/LoginView'),
      })}
    />
    <Route
      path={`${ROUTE_PREFIX}/reset-password`}
      component={lazyLoadModule({
        loader: () => import('modules/Account/ResetPassword/ResetPassword.tsx'),
      })}
    />

    <Route
      path={`${ROUTE_PREFIX}/admin-panel`}
      exact
      component={lazyLoadModule({
        loader: () => import('modules/AdminPanel/AdminPanel'),
      })}
    />
    <Route
      path={`${ROUTE_PREFIX}/admin-panel/booking-summary/reissue`}
      component={lazyLoadModule({
        loader: () => import('modules/AdminPanel/TicketReissue/TicketReissue'),
      })}
    />
    <Route
      path={`${ROUTE_PREFIX}/admin-panel/booking-summary/:orderRef`}
      component={lazyLoadModule({
        loader: () => import('modules/AdminPanel/AdminBooking/AdminBooking'),
      })}
    />
    <Route
      path="/*"
      component={lazyLoadModule({
        loader: () => import('shared/NotFound/NotFound'),
      })}
    />
    <Route
      path={ROUTE_PREFIX}
      component={lazyLoadModule({
        loader: () => import('shared/NotFound/NotFound'),
      })}
    />
    <Route
      path={HOTELS_PREFIX}
      component={lazyLoadModule({
        loader: () => import('shared/NotFound/NotFound'),
      })}
    />
  </Switch>
);
