/**
 * This file holds types of our Result state, actions, payloads as well as any other types related to this module.
 */
import { FlightSearchResult, RouteType, CalendarFare } from '../../../standardTypes';
import { AnyAction } from 'redux';
import { Query } from '../../Search/SearchStore/searchTypes';

export interface Policy {
  rules: any[];
  notes: string[];
}
export interface FareRules {
  policies: {
    cancellationPolicy: Policy;

    modificationPolicy: Policy;
    baggagePolicy: Policy;
    notes: string[];
  };

  rawData: string;
}
export interface ResultsState {
  searchResults: SearchResults | null;
  multiCityStepNumber: number;
  isInternationalCombo: boolean;
  calendarFares: CalendarFare[];
  selectedResults: FlightSearchResult[];
}

export const ResultActiontypes = {
  ADD_RESULTS: 'ADD_RESULTS',
  CLEAR_RESULTS: 'CLEAR_RESULTS',
  ADD_COMBO_TYPE: 'ADD_COMBO_TYPE',
  CHANGE_STEP: 'CHANGE_STEP',
  SELECT_SEARCH_RESULT: 'SELECT_SEARCH_RESULT',
  CLEAR_SELECTED_RESULT: 'CLEAR_SELECTED_RESULT',
  ADD_CALENDAR_FARES: 'ADD_CALENDAR_FARES',
  CLEAR_CALENDAR_FARES: 'CLEAR_CALENDAR_FARES',
};

/**
 * Sub types of Result Store Types below
 */

export interface SearchResults {
  providerSearchIds: Object;
  flights: FlightSearchResult[];
  routeType: RouteType;
  isInternational: boolean;
  searchQuery: Query;
  cacheGafResponse: boolean;
  gafKey: string;
  calendarFares: CalendarFare[];
  calendarPoll: boolean;
}

export interface AddResultsAction extends AnyAction {
  type: typeof ResultActiontypes.ADD_RESULTS;
  searchResults: SearchResults;
}

export interface ClearResultsAction extends AnyAction {
  type: typeof ResultActiontypes.CLEAR_RESULTS;
  searchResults: null;
}

export interface ClearFaresAction extends AnyAction {
  type: typeof ResultActiontypes.CLEAR_CALENDAR_FARES;
}

export interface AddComboTypeAction extends AnyAction {
  type: typeof ResultActiontypes.ADD_COMBO_TYPE;
  isInternationalCombo: boolean;
}

export interface ChangeStepAction extends AnyAction {
  type: typeof ResultActiontypes.CHANGE_STEP;
  multiCityStepNumber: number;
}

export interface SelectSearchResultAction extends AnyAction {
  type: typeof ResultActiontypes.SELECT_SEARCH_RESULT;
  selectedResults: FlightSearchResult[];
  // legIndex: number;
}

export interface ClearSelectedResultAction extends AnyAction {
  type: typeof ResultActiontypes.CLEAR_SELECTED_RESULT;
}

export interface AddCalendarFareAction extends AnyAction {
  type: typeof ResultActiontypes.ADD_CALENDAR_FARES;
  calendarFares: CalendarFare[];
}

export type ResultAction =
  | AddResultsAction
  | ClearResultsAction
  | AddComboTypeAction
  | ChangeStepAction
  | SelectSearchResultAction
  | ClearSelectedResultAction
  | AddCalendarFareAction
  | ClearFaresAction;
